import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { PageHeader, ConfirmationDialog } from 'components'
import { Typography, Button, Grid, Box, Icon } from '@ntpkunity/controls'
import { Status, Reasons, DialogMessages } from '@helpers/enums'
import { APP_ROUTES } from '@helpers/links'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { useGetOrderInformation } from '@apis/order-management.service'
import {
  AssetDetails,
  CustomerDetails,
  InsuranceDetails,
  OrderDetails,
  TradeInDetails,
  PaymentBreakdown,
  PickupAppointment,
  Contracts,
  PaymentDetails
} from '@app/order-management/order-summary'

const OrderSummary: FC = () => {
  const theme = useTheme()
  const { mutate: getOrderInformation, data: orderDetail } = useGetOrderInformation()
  const [orderinformation, setOrderInformation] = useState<any>(orderDetail)
  const { reference_id } = useParams()
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const [availableState, setAvailableState] = useState<boolean>(false)
  const { mutate: updateOrderStatus } = useUpdateOrderStatus()
  const { actions } = useStoreContext()
  let navigate = useNavigate()

  useEffect(() => {
    if (reference_id || availableState) {
      getOrderInformation(
        { reference_number: reference_id },
        {
          onSuccess(response: any) {
            if (
              response?.status === Status.Submitted ||
              response?.status === Status.Completed ||
              response?.status === Status.Conditioned ||
              response?.status === Status.Approved ||
              response?.status === Status.Cancelled ||
              response?.status === Status.ReSubmitted ||
              response?.status === Status.Reviewed ||
              response?.status === Status.InReview ||
              response?.status === Status.PendingConfirmation
            )
              setOrderInformation(response)
          }
        }
      )
    }
  }, [reference_id, availableState])

  const onDeleteConfirm = () => {
    const data = {
      order_id: orderinformation?.reference_number,
      reqBody: {
        status: Status.Cancelled,
        user_id: 0,
        reason:
          orderinformation?.status === Status.PendingConfirmation
            ? Reasons.ASSET_NOT_AVAILABLE
            : Reasons.CANCELED_BY_DEALER
      }
    }
    updateOrderStatus(data, {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Order Cancelled',
          toastState: true
        })
        navigate(APP_ROUTES?.WORKQUEUE)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }

  return (
    <LayoutWithSideNav theme={theme}>
      <Box theme={theme} sx={{ mb: 2.5 }}>
        <PageHeader
          className="main-page-header border-bottom"
          theme={theme}
          container
          item
          spacing={2}
        >
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
              <Typography theme={theme} variant="h2" component="h2">
                Order {orderinformation?.status}
              </Typography>
              <Typography theme={theme} variant="body2" component="p">
                {(() => {
                  switch (orderinformation?.status) {
                    case Status.Submitted:
                      return (
                        <>
                          This should not take too long. We will notify you once the credit
                          application has been reviewed.
                        </>
                      )
                    case Status.Completed:
                      return (
                        <>This order has been completed. You can review the order details below.</>
                      )
                    case Status.Conditioned:
                      return (
                        <>
                          Please see the information below regarding the status of your credit
                          application review.
                        </>
                      )
                    case Status.Approved:
                      return (
                        <>
                          The credit application has been approved. Once you receive the payment,
                          please update the payment status below to proceed.
                        </>
                      )
                    case Status.Cancelled:
                      return (
                        <>
                          Sorry, the order has been cancelled and moved to your cancelled orders
                          queue.
                        </>
                      )
                    case Status.ReSubmitted:
                      return (
                        <>
                          This should not take too long. We will notify you once the credit
                          application has been reviewed.
                        </>
                      )
                    case Status.Reviewed:
                      return (
                        <>
                          This should not take too long. We will notify you once the credit
                          application has been reviewed.
                        </>
                      )
                    case Status.InReview:
                      return (
                        <>
                          This should not take too long. We will notify you once the credit
                          application has been reviewed.
                        </>
                      )
                    case Status.Draft:
                      return (
                        <>Your order has been saved. You can view the saved order details below.</>
                      )
                    case Status.PendingConfirmation:
                      return (
                        <>
                          Customer has submitted the order. Finalize this order by confirming
                          availability
                        </>
                      )
                    default:
                      return (
                        <>
                          This should not take too long. We will notify you once the credit
                          application has been reviewed.
                        </>
                      )
                  }
                })()}
              </Typography>
            </Grid>

            <Grid theme={theme} item lg={5} md={6} sm={12} xs={12} textAlign="right">
              {orderinformation &&
              orderinformation?.status != Status.Completed &&
              orderinformation?.status != Status.PendingConfirmation &&
              orderinformation?.status != Status.Cancelled ? (
                <Button
                  theme={theme}
                  secondary
                  id="CancelOrder"
                  text={'Cancel Order'}
                  onClick={(_e) => {
                    setConfirmationPopupState(true)
                  }}
                />
              ) : (
                <></>
              )}
              {orderinformation && orderinformation?.status == Status.PendingConfirmation && (
                <Button
                  theme={theme}
                  secondary
                  id="EditOrder"
                  text={'Edit Order'}
                  onClick={(_e) => {
                    navigate(
                      `/dms/create-order-ui/${orderinformation?.Vehicle_details?.vin}/${orderinformation?.customer_info?.reference_id}/${orderinformation.reference_number}`
                    )
                  }}
                />
              )}
            </Grid>
          </Grid>
        </PageHeader>
      </Box>
      <AssetDetails
        order_info={orderinformation}
        setConfirmationPopupState={setConfirmationPopupState}
        setAvailableState={setAvailableState}
      />
      <Grid theme={theme} className="container" container spacing={2}>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
          {(orderDetail?.status == Status.Appointment || orderDetail?.status == Status.Completed) &&
          orderDetail?.appointment_details ? (
            <PickupAppointment order_info={orderDetail}></PickupAppointment>
          ) : (
            ''
          )}

          {orderDetail?.documents && orderDetail?.documents.length > 0 ? (
            <Contracts
              documents={orderDetail?.documents as any}
              orderStatus={orderDetail?.status}
              customerReferenceId={orderDetail?.customer_info?.reference_id}
            />
          ) : (
            ''
          )}

          {orderDetail?.status == Status.SchedulePickup ||
          orderDetail?.status == Status.Appointment ||
          orderDetail?.status == Status.Completed ? (
            <PaymentDetails
              paymentIndicator={orderDetail?.payment_recieved}
              orderStatus={orderDetail?.status}
            />
          ) : (
            ''
          )}

          <CustomerDetails customer_info={orderinformation?.customer_info} />

          <OrderDetails order_detail={orderinformation} />

          {orderinformation?.Insurance_Inforation ? (
            <InsuranceDetails insurance={orderinformation?.Insurance_Inforation} />
          ) : (
            ''
          )}

          {orderinformation?.order_tradein ? (
            <TradeInDetails order_tradein={orderinformation?.order_tradein} />
          ) : (
            ''
          )}
        </Grid>
        <PaymentBreakdown order_info={orderinformation} />
      </Grid>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.cancelBtnText}
          isButtonTypeDanger
          icon={<Icon name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          hideCancelButton={false}
        />
      )}
    </LayoutWithSideNav>
  )
}

export default OrderSummary
