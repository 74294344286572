import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { PageHeader, ConfirmationDialog } from '@components'
import { Typography, Button, Box, Icon } from '@ntpkunity/controls'
import {
  useReadyCheckListEvaluateRequest,
  useCreateEventRequest,
  useSaveChecklistProgress,
  useUpdateChecklistProgress
} from '@apis/dealer-workqueue.service'
import { v4 as uuidv4 } from 'uuid'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { useStoreContext } from '@store/storeContext'
import {
  compareAnswers,
  containsKeyValue,
  getANToken,
  getDecodedANToken,
  initialDealerWorkQueueState
} from '@helpers/methods'
import { APP_ROUTES } from '@helpers/links'

const TYPES = {
  AVAILABILITY_CHECK: 'AVAILABILITY_CHECK',
  GET_READY_CHECKLIST: 'GET_READY_CHECKLIST',
  PICKUP: 'PICKUP'
}
const STATUSES = {
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE'
}

//@ts-ignore
const InProgressPageHeader: FC<{
  showButton?: boolean
  reqData?: any
  checkListName?: string
  checklistIdentifier?: string
  questionCount?: number
  savedOptionAvailable?: boolean
  description?: string
  keys_description?: string
  possession_description?: string
  checklistData?: any
  setChecklistErrors?: any
  form?: any
}> = ({
  showButton = true,
  reqData,
  checkListName,
  checklistIdentifier,
  questionCount,
  savedOptionAvailable,
  description,
  keys_description,
  possession_description,
  checklistData,
  setChecklistErrors,
  form
}: any) => {
  const theme = useTheme()
  const { trigger } = form
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const { state } = location
  const [popUpState, setPopupState] = useState<boolean>(false)
  const [sendReq, setSendRequest] = useState<boolean>(false)
  const [showCompletitionText, setShowCompletionText] = useState<boolean>(false)
  const [reqadyCheckListRequest, setReadyCheckListRequest] = useState({})
  const [sendSaveChecklistRequest, setSendSaveRequestForReadyChecklistPage] = useState({})
  const [popUpStateForSecondQuestionNo, setPopupStateForSecondQuestionNo] = useState<boolean>(false)
  const { actions, states } = useStoreContext()
  const { mutate: evaluateEventAssociatedReadyCheckList, isLoading: evaluateListLoader } =
    useReadyCheckListEvaluateRequest()
  //@ts-ignore
  const { mutate: saveChecklistProgress, isLoading: saveCheckListLoading } =
    useSaveChecklistProgress()
  //@ts-ignore
  const { mutate: updateChecklistProgress, isLoading: updateCheckListLoading } =
    useUpdateChecklistProgress()
  const {
    mutate: createEvent,
    // error,
    isLoading
  } = useCreateEventRequest()
  const decodedToken = getDecodedANToken(getANToken())

  const handleClick = () => {
    checkAndSendCall(reqData)
  }

  const vehicle_check_page = location?.pathname?.includes('work-queue/vehicle-check')
  const ready_checklist_page = location?.pathname?.includes('work-queue/checklist')
  const schedule_pickup_page = location?.pathname?.includes('work-queue/schedule-pickup')

  const makeStoreEmpty = () => {
    actions.setDealerGetReadyCheckList(initialDealerWorkQueueState)
    actions.setDealerSchedulePickups(initialDealerWorkQueueState)
    actions.setDealerVehicleAvailableConfirmationOrders(initialDealerWorkQueueState)
  }

  const checkAndSendCall = async (reqData: any) => {
    await trigger()
    let checklistErrors = compareAnswers(reqData, checklistData)
    if (checklistErrors?.length > 0) {
      setChecklistErrors(checklistErrors)
    }
    if (Object.keys(reqData).length > 0) {
      if (location?.pathname?.includes('vehicle-check')) {
        const { value: odometer, available } = reqData
        const metaData = states?.dealerSelectedOrderData?.orderItems?.find(
          (obj: any) => obj?.type === 'vehicle'
        )?.metadata
        const orderOdometerValue = metaData?.odometerReportedByDealer
          ? metaData?.odometerReportedByDealer
          : metaData?.odometerAtOrderCreation
          ? metaData?.odometerAtOrderCreation
          : 0
        if (
          available == 'available' &&
          (state || states?.dealerSelectedOrderData) &&
          Math.abs(odometer - orderOdometerValue) >= Number(states?.odometerData?.odometer_variance)
        ) {
          setPopupState(true)
        } else {
          if (
            (((state || states?.dealerSelectedOrderData) &&
              Math.abs(odometer - orderOdometerValue) <=
                Number(states?.odometerData?.odometer_variance)) ||
              available !== 'not available') &&
            containsKeyValue(reqData?.checklistOptions, 'Has the vehicle been put on hold?', 'No')
          ) {
            setPopupStateForSecondQuestionNo(true)
          } else if (
            available === 'not available' &&
            containsKeyValue(reqData?.checklistOptions, 'Has the vehicle been put on hold?', 'No')
          ) {
            setPopupStateForSecondQuestionNo(true)
          } else {
            saveOrUpdateChecklistOptions()
          }
        }
      } else if (ready_checklist_page || schedule_pickup_page) {
        if (ready_checklist_page) {
          setPopupState(false)
          evaluateListAndCreateEventCall()
        } else {
          setPopupState(true)
        }
      }
    } else {
    }
  }

  const handleCreateEvent = (requestBody: any) => {
    setPopupState(false)
    createEvent(
      { reqBody: requestBody, order_id: id },
      {
        onSuccess() {
          if (ready_checklist_page) {
            saveOrUpdateChecklistOptions()
          } else {
            setSendRequest(false)
            actions.setToast({
              toastMessage:
                'Record updated successfully, you will be redirected back to the workqueue screen.',
              toastState: true,
              toastLength: 3000
            })
            makeStoreEmpty()
            navigateToWorkqueuePage()
          }
        },
        onError({ error }: any) {
          setSendRequest(false)
          actions.setToast({
            toastMessage: error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Some error occured',
            toastState: true,
            variant: 'error'
          })
          makeStoreEmpty()
          navigateToWorkqueuePage()
        }
      }
    )
  }

  const evaluateListAndCreateEventCall = () => {
    const questionAnswersArrayForRequest: any = []
    let { value: odometer, available } = reqData
    const data = vehicle_check_page ? reqData.checklistOptions : reqData

    for (const key in data) {
      if (key !== 'image') {
        const obj: any = { question: key, answer: data[key] }
        questionAnswersArrayForRequest.push(obj)
      }
    }
    const requestData = {
      payload: [
        {
          checklist_name: checkListName,
          response: vehicle_check_page
            ? questionAnswersArrayForRequest.filter(
                (obj: any) => obj.question !== 'Is this vehicle available?'
              )
            : questionAnswersArrayForRequest.filter(
                (obj: any) => obj.question !== 'GeoTab Serial Number'
              )
        }
      ]
    }
    evaluateEventAssociatedReadyCheckList(
      { ...requestData },
      {
        onSuccess(res: any) {
          let createEventRequestBody = null
          if (vehicle_check_page) {
            createEventRequestBody = {
              type: TYPES?.AVAILABILITY_CHECK,
              status:
                available === 'available'
                  ? STATUSES?.AVAILABLE
                  : available === 'not available'
                  ? STATUSES?.NOT_AVAILABLE
                  : '',
              metadata: {
                ...(odometer && available === 'available' && { odometer }),
                ...(reqData?.damage_details &&
                  containsKeyValue(
                    reqData?.checklistOptions,
                    'Does the vehicle have any damage?',
                    'Yes'
                  ) && {
                    damageDetailsReportedByDealer: reqData?.damage_details
                  }),
                ...(reqData?.open_repair_orders &&
                  containsKeyValue(
                    reqData?.checklistOptions,
                    'Are there any open Repair Orders against this vehicle?',
                    'Yes'
                  ) && {
                    estimatedCostOfOpenRO: reqData?.open_repair_orders
                  })
              },
              completedBy: decodedToken?.email,
              idempotentKey: uuidv4()
            }
          } else {
            createEventRequestBody = {
              type: ready_checklist_page ? TYPES?.GET_READY_CHECKLIST : TYPES?.PICKUP,
              status:
                (ready_checklist_page && res[0]?.status == 'Favourable') ||
                (schedule_pickup_page &&
                  res[0]?.status == 'Favourable' &&
                  questionCount == Object.keys(reqData).length)
                  ? STATUSES?.COMPLETED
                  : STATUSES?.INCOMPLETE,
              metadata: {
                ...(schedule_pickup_page
                  ? {
                      numberOfVehicleKeys:
                        reqData['How many keys/key cards/FOBs did you provide to the customer ?']
                    }
                  : {}),
                ...(schedule_pickup_page &&
                  containsKeyValue(
                    reqData,
                    'Did you walk through the vehicle and features with the customer?',
                    'No'
                  ) && { reasonDealerDoesNotWalkthroughVehicle: description }),
                ...(ready_checklist_page &&
                  containsKeyValue(reqData, 'Does the vehicle have any damage?', 'Yes') && {
                    damageDetailsReportedByDealer: description
                  })
              },
              completedBy: decodedToken?.email,
              idempotentKey: uuidv4()
            }
            setReadyCheckListRequest(createEventRequestBody)
          }
          if (ready_checklist_page) {
            if (res[0]?.status == 'Favourable') {
              setShowCompletionText(true)
              setPopupState(true)
            } else {
              setPopupState(true)
              setShowCompletionText(false)
            }
          } else if (vehicle_check_page && odometer) {
            handleCreateEvent(createEventRequestBody)
          } else if (schedule_pickup_page) {
            handleCreateEvent(createEventRequestBody)
          } else {
          }
        },
        onError() {
          actions.setToast({
            toastMessage: 'Some error occured in checklist evaluation',
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }

  useEffect(() => {
    if (sendReq) {
      if (vehicle_check_page) {
        setSendRequest(!sendReq)
        saveOrUpdateChecklistOptions()
      }
    }
    if (schedule_pickup_page) {
      if (sendReq) {
        saveOrUpdateChecklistOptions()
      }
    }
    if (
      ready_checklist_page &&
      Object.keys(reqadyCheckListRequest).length > 0 &&
      sendSaveChecklistRequest
    ) {
      handleCreateEvent(reqadyCheckListRequest)
    }
  }, [sendReq, popUpStateForSecondQuestionNo, , sendSaveChecklistRequest])

  const saveOrUpdateChecklistOptions = () => {
    var selectedQuestionAnswersArrayForRequest = []
    setSendRequest(false)
    const { value, available, other_reason } = reqData
    const data = vehicle_check_page ? reqData.checklistOptions : reqData
    for (const key in data) {
      const obj: any = {
        ...(!savedOptionAvailable
          ? {
              created_by: decodedToken?.email
            }
          : {
              updated_by: decodedToken?.email
            }),
        customer_reference_id: states?.dealerSelectedOrderData?.customerId,
        ...(!savedOptionAvailable && {
          order_reference_id: states?.dealerSelectedOrderData?.id
        }),
        checklist_name: checkListName,
        event: ready_checklist_page
          ? 'get_ready_checklist_event'
          : vehicle_check_page
          ? 'availability-check-checklist-event'
          : 'Vehicle Pick up Scheduling Confirmation',
        question_text: key,
        question_type:
          schedule_pickup_page &&
          key === 'How many keys/key cards/FOBs did you provide to the customer ?'
            ? 'MULTIPLE_OPTIONS'
            : 'YES_NO',
        primary_answer: data[key],
        external_order_ref_number: states?.dealerSelectedOrderData?.id,
        checklist_identifier: checklistIdentifier,
        ...(vehicle_check_page && key === 'Does the vehicle have any damage?' && data[key] === 'Yes'
          ? { description: reqData?.damage_details }
          : { description: null }),
        ...(ready_checklist_page &&
          key === 'Does the vehicle have any damage?' &&
          data[key] === 'Yes' && { description: description }),
        ...(key === 'Did you walk through the vehicle and features with the customer?' &&
          reqData[key] === 'No' && { description: description }),
        ...(key === 'How many keys/key cards/FOBs did you provide to the customer ?' && {
          description: keys_description
        }),
        ...(schedule_pickup_page &&
          key === 'Did the customer take possession of the vehicle?' && {
            description: possession_description
          })
      }
      if (vehicle_check_page) {
        if (obj?.question_text === 'Are there any open Repair Orders against this vehicle?') {
          if (obj?.primary_answer === 'Yes') {
            obj.description = reqData?.open_repair_orders
          } else if (obj?.primary_answer === 'No') {
            obj.description = null
          }
        }
      }
      selectedQuestionAnswersArrayForRequest.push(obj)
    }
    if (vehicle_check_page) {
      const obj_vehicle_available: any = {
        ...(!savedOptionAvailable
          ? {
              created_by: decodedToken?.email
            }
          : {
              updated_by: decodedToken?.email
            }),
        customer_reference_id: states?.dealerSelectedOrderData?.customerId,
        ...(!savedOptionAvailable && {
          order_reference_id: states?.dealerSelectedOrderData?.id
        }),
        checklist_name: checkListName,
        event: ready_checklist_page
          ? 'get_ready_checklist_event'
          : vehicle_check_page
          ? 'availability-check-checklist-event'
          : 'Vehicle Pick up Scheduling Confirmation',
        question_text: 'Is this vehicle available?',
        question_type: 'YES_NO',
        primary_answer: available === 'available' ? 'Yes' : 'No',
        external_order_ref_number: states?.dealerSelectedOrderData?.id,
        checklist_identifier: checklistIdentifier
      }
      if (vehicle_check_page && available == 'available') {
        obj_vehicle_available.description = value
      }
      if (vehicle_check_page && available == 'not available') {
        if (value == '0') {
          obj_vehicle_available.description = other_reason
        } else {
          obj_vehicle_available.description = value
        }
      }
      const existingObjIndex = selectedQuestionAnswersArrayForRequest.findIndex(
        (item) => item.question_text === obj_vehicle_available.question_text
      )
      if (existingObjIndex !== -1) {
        selectedQuestionAnswersArrayForRequest[existingObjIndex] = obj_vehicle_available
      } else {
        selectedQuestionAnswersArrayForRequest.push(obj_vehicle_available)
      }
    }
    if (!savedOptionAvailable) {
      saveChecklistProgress(
        { data: selectedQuestionAnswersArrayForRequest },
        {
          onSuccess() {
            if (vehicle_check_page) {
              if (available === 'available') {
                if (
                  containsKeyValue(
                    reqData?.checklistOptions,
                    'Has the vehicle been put on hold?',
                    'Yes'
                  )
                ) {
                  evaluateListAndCreateEventCall()
                } else {
                  actions.setToast({
                    toastMessage:
                      'Checklist progress saved. You will be redirected back to workqueue screen.',
                    toastState: true,
                    toastLength: 2000
                  })
                  makeStoreEmpty()
                  navigateToWorkqueuePage()
                }
              } else if (available == 'not available') {
                handleCreateEvent({
                  completedBy: decodedToken?.email,
                  idempotentKey: uuidv4(),
                  status: STATUSES?.NOT_AVAILABLE,
                  type: TYPES?.AVAILABILITY_CHECK,
                  metadata: {}
                })
              } else {
                actions.setToast({
                  toastMessage:
                    'Checklist progress saved. You will be redirected back to workqueue screen.',
                  toastState: true,
                  toastLength: 2000
                })
                makeStoreEmpty()
                navigateToWorkqueuePage()
              }
            } else if (schedule_pickup_page) {
              evaluateListAndCreateEventCall()
            } else if (ready_checklist_page) {
              setSendRequest(false)
              actions.setToast({
                toastMessage:
                  'Checklist progress saved. You will be redirected back to workqueue screen.',
                toastState: true,
                toastLength: 2000
              })
              makeStoreEmpty()
              navigateToWorkqueuePage()
            } else {
              actions.setToast({
                toastMessage:
                  'Checklist progress saved. You will be redirected back to workqueue screen.',
                toastState: true,
                toastLength: 2000
              })
              makeStoreEmpty()
              navigateToWorkqueuePage()
            }
          },
          onError({ error }: any) {
            actions.setToast({
              toastMessage: error ? error?.response?.data?.detail?.message : 'Something went wrong',
              toastState: true,
              variant: 'error'
            })
          }
        }
      ) // post call
    } else {
      updateChecklistProgress(
        { data: selectedQuestionAnswersArrayForRequest },
        {
          onSuccess() {
            if (vehicle_check_page) {
              if (available === 'available') {
                if (
                  containsKeyValue(
                    reqData?.checklistOptions,
                    'Has the vehicle been put on hold?',
                    'Yes'
                  )
                ) {
                  evaluateListAndCreateEventCall()
                } else {
                  actions.setToast({
                    toastMessage:
                      'Checklist progress saved. You will be redirected back to workqueue screen.',
                    toastState: true,
                    toastLength: 2000
                  })
                  makeStoreEmpty()
                  navigateToWorkqueuePage()
                }
              } else if (available == 'not available') {
                handleCreateEvent({
                  completedBy: decodedToken?.email,
                  idempotentKey: uuidv4(),
                  status: STATUSES?.NOT_AVAILABLE,
                  type: TYPES?.AVAILABILITY_CHECK,
                  metadata: {}
                })
              } else {
                actions.setToast({
                  toastMessage:
                    'Checklist progress saved. You will be redirected back to workqueue screen.',
                  toastState: true,
                  toastLength: 2000
                })
                makeStoreEmpty()
                navigateToWorkqueuePage()
              }
            } else if (schedule_pickup_page) {
              evaluateListAndCreateEventCall()
            } else if (ready_checklist_page) {
              setSendRequest(false)
              actions.setToast({
                toastMessage:
                  'Checklist progress saved. You will be redirected back to workqueue screen.',
                toastState: true,
                toastLength: 2000
              })
              makeStoreEmpty()
              navigateToWorkqueuePage()
            } else {
              actions.setToast({
                toastMessage:
                  'Checklist progress saved. You will be redirected back to workqueue screen.',
                toastState: true,
                toastLength: 2000
              })
              makeStoreEmpty()
              navigateToWorkqueuePage()
            }
          },
          onError({ error }: any) {
            actions.setToast({
              toastMessage: error ? error?.response?.data?.detail?.message : 'Something went wrong',
              toastState: true,
              variant: 'error'
            })
          }
        }
      ) // Patch call
    }
  }

  const navigateToWorkqueuePage = () => {
    setTimeout(() => {
      navigate(APP_ROUTES?.DEALER_WORK_QUEUE)
    }, 3000)
  }

  return (
    <>
      <ConfirmationDialog
        openPopUp={popUpState}
        onConfirm={() => {
          if (
            vehicle_check_page &&
            containsKeyValue(reqData?.checklistOptions, 'Has the vehicle been put on hold?', 'No')
          ) {
            setPopupStateForSecondQuestionNo(true)
          } else if (ready_checklist_page) {
            setSendSaveRequestForReadyChecklistPage(true)
          } else {
            setSendRequest(true)
          }
        }}
        onCancel={() => setPopupState(false)}
        setPopUpState={() => setPopupState(false)}
        hideCancelButton={false}
        confirmationTitle={'Do you want to save the changes?'}
        confirmationText={
          !ready_checklist_page
            ? `${
                location?.pathname?.includes('vehicle-check')
                  ? 'The odometer reading is different from our records.'
                  : ''
              } Are you sure you want to save it?`
            : ready_checklist_page && showCompletitionText
            ? 'Upon completion, an AN Mobility Representative will be scheduling pickup for this vehicle'
            : 'The deal cannot proceed until all items on the checklist have been completed. Please Contact Mobility Store Support for any questions.'
        }
        primaryBtnText={!showCompletitionText && ready_checklist_page ? 'Save Anyway' : 'Save'}
        secondaryBtnText={!showCompletitionText && ready_checklist_page ? 'Keep Editing' : 'Cancel'}
        icon={<Icon name="DialogSaveChangesIcon" />}
      />
      <ConfirmationDialog
        openPopUp={popUpStateForSecondQuestionNo}
        onConfirm={() => {
          setSendRequest(true)
        }}
        hideCancelButton
        onCancel={() => {}}
        setPopUpState={() => setPopupStateForSecondQuestionNo(false)}
        confirmationTitle={'Vehicle must be put on hold in order to proceed.'}
        primaryBtnText={'Done'}
        icon={<Icon name="DialogAlertIcon" />}
      />
      {/* <form onSubmit={handleClick}> */}
      <PageHeader className="main-page-header border-bottom">
        <Box theme={theme} flexShrink={0} display={'flex'} sx={{ mr: 1 }}>
          <Button
            theme={theme}
            iconText={
              <Icon name="IconLeftArrow" onClick={() => navigate(APP_ROUTES?.DEALER_WORK_QUEUE)} />
            }
            className="btn-back"
          />
        </Box>
        <Box theme={theme} flexGrow={'1'}>
          <Typography
            theme={theme}
            variant="h2"
            component="h2"
            className="title"
            children="In-Progress"
          />
        </Box>
        {showButton && (
          <Box
            theme={theme}
            className="action-area"
            flexShrink={0}
            display={'flex'}
            alignItems={'center'}
          >
            <Button
              type="submit"
              theme={theme}
              primary
              text="Save Changes"
              disabled={
                isLoading || saveCheckListLoading || updateCheckListLoading || evaluateListLoader
              }
              endIcon={
                (isLoading ||
                  saveCheckListLoading ||
                  updateCheckListLoading ||
                  evaluateListLoader) && <img src={DisableLoader} alt="Loader" />
              }
              onClick={handleClick}
            />
          </Box>
        )}
      </PageHeader>
      {/* </form> */}
    </>
  )
}

export default InProgressPageHeader
