export enum QueryKeys {
  GET_ALL_FINANCIAL_INSURANCE_PRODUCT = 'GET ALL FINANCIAL PRODUCT',
  GET_ALL_WORK_ORDERS = 'GET ALL WORK ORDERS',
  GET_ALL_ORDERS = 'GET ALL ORDERS',
  GET_ALL_SCHEDULE_PICKUP_ORDERS = 'GET ALL SCHEDULE PICKUP ORDERS',
  GET_ALL_PENDING_CONFIRMATION_ORDERS = 'GET ALL PENDING CONFIRMATION ORDERS',
  GET_ALL_CANCELLED_ORDERS = 'GET ALL CANCELLED ORDERS',
  GET_ALL_COMPLETED_ORDERS = 'GET ALL COMPLETED ORDERS',
  GET_ALL_APPOINTMENT_ORDERS = 'GET ALL APPOINTMENT ORDERS',
  GET_ALL_PREFIX = 'GET_ALL_PREFIX',
  GET_ALL_COUTRIES = 'GET_ALL_COUTRIES',
  GET_ALL_STATES = 'GET_ALL_STATES',
  GET_CUSTOMER = 'GET_CUSTOMER',
  GET_ALL_CURRENCIES = 'GET_ALL_CURRENCIES',
  GET_CUSTOMER_CONTACT_DETAILS = 'GET_CUSTOMER_CONTACT_DETAILS',
  GET_CUSTOMER_EMPLOYMENT_DETAILS = 'GET_CUSTOMER_EMPLOYMENT_DETAILS',
  GET_PERSONAL_FINANCE = 'GET_PERSONAL_FINANCE_DETAILS',
  GET_LICENSE_DETAILS = 'GET_LICENSE_DETAILS',
  GET_ALL_ACTIVE_ASSET_USAGE = 'GET_ALL_ACTIVE_ASSET_USAGE',
  GET_LENDER_PROFILE = 'GET LENDER PROFILE',
  GET_THEME_BUILDER = 'GET THEME BUILDER'
}
