import { FC, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Typography, Button, Tooltip, Icon, Menu } from '@ntpkunity/controls'
import { DetailCard } from 'components'
import { getBase64 } from '@helpers/methods'
import { useUploadFile, useVerifyDocument } from '@apis/order-management.service'
import { Status } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import ContractSignModal from './contract-sign-modal'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import {
  useGetCustomerSignatures,
  useSaveCustomerDocumentSignature
} from '@apis/customer-detail.service'
interface IContractsProps {
  documents?: any
  orderStatus: string
  setAllDocuments?: any
  customerReferenceId: string
}
const Contracts: FC<IContractsProps> = ({
  documents,
  orderStatus,
  setAllDocuments,
  customerReferenceId
}) => {
  const { mutate: getCustomerSignatures } = useGetCustomerSignatures()
  const { mutate: saveCustomerDosumentSignature, isLoading: saveCustomerDosumentSignatureLoading } =
    useSaveCustomerDocumentSignature()
  const { actions } = useStoreContext()
  const theme = useTheme()
  const { mutate: uploadFile } = useUploadFile()
  const { mutate: verifyDocument } = useVerifyDocument()
  const [selectedDocument, setSelectedDocument] = useState<any>()
  const hiddenFileInput = useRef<any>(null)
  const [openContractSignDialog, setOpenContractSignDialog] = useState<boolean>(false)
  const [getLatestSignature, setGetLatestSignature] = useState<boolean>(true)
  const [isDocSign, setIsDocSign] = useState<boolean>(false)
  const [customerSignatures, setCustomerSignatures] = useState<any>({})

  useEffect(() => {
    if (customerReferenceId && getLatestSignature) {
      getCustomerSignatures(
        {
          reference_id: customerReferenceId
        },
        {
          onSuccess: (response: any) => {
            setCustomerSignatures(response)
            setGetLatestSignature(false)
          },
          onError: () => {
            setGetLatestSignature(false)
          }
        }
      )
    }
  }, [customerReferenceId, getLatestSignature])

  useEffect(() => {
    if (
      (customerSignatures?.initial_image && customerSignatures?.signature_image,
      selectedDocument,
      isDocSign)
    ) {
      signDocument(selectedDocument)
    }
  }, [customerSignatures, selectedDocument, isDocSign])

  const handleChange = async (event: any) => {
    const base64 = await getBase64(
      event.type === 'drop' ? event.dataTransfer.files[0] : event.target.files?.[0]
    )
    const body = {
      file_name: event?.target.files?.[0]?.name,
      content_type: event?.target?.files?.[0]?.type,
      file: base64,
      identifier: selectedDocument?.identifier
    }

    uploadFile(body, {
      onSuccess: (_res: any) => {
        verifyDocument(selectedDocument?.identifier, {
          onSuccess(res: any) {
            if (res == 'Document Successfully Verified') {
              const updatedDocument = documents?.map((item: any) => {
                if (item.identifier === selectedDocument?.identifier) {
                  return { ...item, is_verified: true }
                }
                return item
              })
              setAllDocuments(updatedDocument)
            }
          }
        }),
          actions.setToast({
            toastMessage: 'Document Successfully Uploaded',
            toastState: true
          })
      }
    })
  }
  const handleDownloadAll = () => {
    documents?.forEach((document: any) => {
      if (document.is_verified) {
        window.open(document.document_signed_url, '_blank')
      } else {
        window.open(document.document_raw_url, '_blank')
      }
    })
  }

  const handleSignDocumentClick = (doc: any) => {
    setSelectedDocument(doc)
    if (customerSignatures?.initial_image && customerSignatures?.signature_image) {
      setIsDocSign(true)
    } else {
      setOpenContractSignDialog(true)
    }
  }

  const signDocument = (doc: any) => {
    saveCustomerDosumentSignature(
      {
        identifier: doc?.identifier,
        document_url: doc?.document_raw_url,
        document_name: doc?.document_name,
        customer_id: customerReferenceId
      },
      {
        onSuccess: (response: any) => {
          const copyDocuments = documents?.slice()
          let sDoc = copyDocuments?.find((f: any) => f.document_name === doc?.document_name)
          if (sDoc) {
            sDoc.document_signed_url = response?.generated_document_url
            sDoc.is_verified = true
          }
          if (sDoc.document_name.includes('BMW_Financial_Service')) {
            copyDocuments
              ?.filter((x: any) => x.is_fni_doc == true)
              ?.forEach((item: any) => (item.is_verified = true))
          }
          setAllDocuments(copyDocuments)
          setIsDocSign(false)
        }
      }
    )
  }

  return (
    <>
      <DetailCard mb={2}>
        <Box className="card-body" theme={theme}>
          <Box className="row" mb={3} theme={theme}>
            <Typography component="h4" variant="h4" theme={theme}>
              Contracting Documents
            </Typography>
            {orderStatus == Status.Approved ? (
              <Button defaultBtn iconText={<Icon name="SaveIcon" />} onClick={handleDownloadAll} />
            ) : (
              ''
            )}
          </Box>
        </Box>

        <Box className="card-body" theme={theme}>
          {documents?.map((document: any) => (
            <div key={document.identifier}>
              <Box className="row" theme={theme}>
                <Box className="center" theme={theme}>
                  <Icon name="DocumentIcon" />
                  <Typography
                    variant="body2"
                    sx={{ ml: 2 }}
                    component="h3"
                    className="card-text"
                    theme={theme}
                  >
                    {document.document_name}
                  </Typography>
                </Box>
                <input
                  type="file"
                  // accept=".xls,.xlsx"
                  id="AssetCatalogueUploadId"
                  ref={hiddenFileInput}
                  style={{ display: 'none' }}
                  onChange={handleChange}
                />
                <Box className="center" theme={theme}>
                  <Icon
                    className="icon"
                    name={`${document.is_verified == true ? 'GreenCheckIcon' : 'CircleExc'}`}
                  />
                  {orderStatus === Status.Approved ? (
                    <Menu
                      sx={{ ml: 2 }}
                      theme={theme}
                      options={[
                        {
                          optionText: (
                            <>
                              <Icon className="menu-icon" name="IcView" /> View
                            </>
                          ),
                          optionkey: 'View',
                          optionValue: document
                        },
                        {
                          optionText: (
                            <>
                              <Icon className="menu-icon" name="SignatureIcon" /> Sign
                            </>
                          ),
                          optionkey: 'Sign',
                          optionValue: document,
                          disabled: document?.document_signed_url ? true : false
                        },
                        {
                          optionText: (
                            <>
                              <Icon className="menu-icon" name="SaveIcon" /> Download
                            </>
                          ),
                          optionkey: 'Download',
                          optionValue: document
                        },
                        {
                          optionText: (
                            <>
                              <Icon className="menu-icon" name="UploadIcon" /> Upload
                            </>
                          ),
                          optionkey: 'Upload',
                          optionValue: document
                        }
                      ]}
                      handleOptionClick={(_event: any, key: any, value: any) => {
                        switch (key) {
                          case 'Download':
                            if (value?.is_verified) {
                              window.open(value?.document_signed_url, '_blank')
                            } else {
                              window.open(value?.document_raw_url, '_blank')
                            }
                            break
                          case 'Upload':
                            setSelectedDocument(value)
                            hiddenFileInput?.current && hiddenFileInput.current.click()
                            break
                          case 'View':
                            if (value?.is_verified) {
                              window.open(value?.document_signed_url, '_blank')
                            } else {
                              window.open(value?.document_raw_url, '_blank')
                            }
                            break
                          case 'Sign':
                            handleSignDocumentClick(value)
                            break
                          default:
                            break
                        }
                      }}
                      render={(handleOptionClick: any) => (
                        <Box theme={theme}>
                          <Button
                            disabled={
                              saveCustomerDosumentSignatureLoading &&
                              selectedDocument.identifier === document.identifier
                            }
                            theme={theme}
                            defaultBtn
                            iconText={<Icon name="MoreIcon" />}
                            onClick={handleOptionClick}
                            startIcon={
                              saveCustomerDosumentSignatureLoading &&
                              selectedDocument.identifier === document.identifier && (
                                <img src={DisableLoader} alt="Loader" />
                              )
                            }
                          />
                        </Box>
                      )}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    />
                  ) : (
                    <Tooltip theme={theme} title="View">
                      <Button
                        defaultBtn
                        iconText={<Icon name="IcView" />}
                        onClick={() => {
                          if (document?.is_verified) {
                            window.open(document?.document_signed_url, '_blank')
                          } else {
                            window.open(document?.document_raw_url, '_blank')
                          }
                        }}
                      ></Button>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Box className="line" theme={theme}>
                <hr />
              </Box>
            </div>
          ))}
        </Box>
      </DetailCard>
      {openContractSignDialog && (
        <ContractSignModal
          openContractSignDialog={openContractSignDialog}
          setOpenContractSignDialog={setOpenContractSignDialog}
          setGetLatestSignature={setGetLatestSignature}
          setIsDocSign={setIsDocSign}
          customerReferenceId={customerReferenceId}
        ></ContractSignModal>
      )}
    </>
  )
}

export default Contracts
