import { useState, FC } from 'react'
import { useTheme } from '@mui/material'
import { Dialog, Button, Grid } from '@ntpkunity/controls'
import { useUpdateVehicleDetailByVid } from '@apis/inventory-management.service'
import { useStoreContext } from '@store/storeContext'
import { IVehicleDetail } from '@models'
// import { getUser } from "../../public/helper/methods";
import InventoryOptionPopup from '../inventory-options/add-more-options-dialog'
import { useGetAddonByTrim } from '@apis/dealer-addon.service'
import InventoryOptionsTable from './options-listing-table'

const AttachedOptions: FC<{
  openPopup: boolean
  vehicleData: IVehicleDetail
  setopenOptionDialog: any
}> = ({ openPopup, vehicleData, setopenOptionDialog }) => {
  const { actions, states } = useStoreContext()
  const { mutate: updateInventoryOption } = useUpdateVehicleDetailByVid()
  const [openAddMoreOptionDialog, setOpenAddMoreOptionDialog] = useState(false)
  const [vehicleObject, setVehicleObject] = useState<any>(null)
  const { mutate: getDealerAddon, data: dealerOptions } = useGetAddonByTrim()
  const [optionObject, setOptionObject] = useState<any>(dealerOptions)
  // const dealerData = getUser();
  const theme = useTheme()
  const [deleteData, setDeleteData] = useState<any[]>([])
  const [listChanged, setlistChanged] = useState(false)

  const handleCloseOptionDialog = () => {
    setopenOptionDialog(false)
  }
  const handleOptionData = () => {
    let dealerids: any = deleteData?.map((x) => x.id)
    vehicleData.dealer_options = dealerids
  }
  const handleData = () => {
    getDealerAddon(
      {
        // dealerData.dealer_code
        data: `dealer_code=${states?.dealerAndLenderInfo?.id}&trim_desc=${vehicleData.vehicle.model_code}`
      },
      {
        onSuccess(response: any) {
          setOptionObject(response)
          setOpenAddMoreOptionDialog(true)
        }
      }
    )
  }
  return (
    <>
      <Dialog
        disablePortal
        theme={theme}
        variant={undefined}
        size="xl"
        title="Dealer Options"
        open={openPopup}
        onCloseDialog={handleCloseOptionDialog}
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={'right'}>
                <Button
                  theme={theme}
                  secondary
                  text="Add More options"
                  onClick={(_e: any) => {
                    setVehicleObject(vehicleData)
                    handleData()
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  text="Save Changes"
                  onClick={(_e: any) => {
                    handleOptionData()
                    handleCloseOptionDialog()
                    updateInventoryOption(vehicleData, {
                      onSuccess() {
                        actions.setToast({
                          toastMessage: 'Record Updated Successfully',

                          toastState: true
                        })
                        window.location.reload()
                      },
                      onError(error: any) {
                        actions.setToast({
                          toastMessage: error.error.toString(),

                          toastState: true,

                          variant: 'error'
                        })
                      }
                    })
                    setopenOptionDialog(false)
                  }}
                  disabled={!listChanged}
                />
              </Grid>
            </Grid>
          </>
        }
      >
        <InventoryOptionsTable
          setDeleteData={setDeleteData}
          vehicleData={vehicleData}
          setlistChanged={setlistChanged}
          addMoreOptionPopupState={openAddMoreOptionDialog}
        />
      </Dialog>
      <InventoryOptionPopup
        openPopup={openAddMoreOptionDialog}
        vehicleData={vehicleObject}
        setopenOptionDialog={setOpenAddMoreOptionDialog}
        optionData={optionObject}
      ></InventoryOptionPopup>
    </>
  )
}

export default AttachedOptions
