import { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { Button, Icon, Box, Input, Tooltip } from '@ntpkunity/controls'
import { useQueryClient } from 'react-query'
import { InstallationMode, QueryKeys, Status, StipulationType } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import { IQuotationParameters } from 'types/quote-parameters'
import { FinancialStipType } from '@helpers/enums/stipulation-type.enum'

const PackageLease: FC<{
  orderStatus?: string
  leaseObj: any
  calculationData: any
  form: any
  selectedAccordion: any
  sortedOptionsArray: any
  tradeInEquityValue: any
  optionsSum: any
  allowedMileageData: any
  quotationParameterData: IQuotationParameters
  setQuotationParameterData: any
  setQuotationChanges: any
}> = ({
  leaseObj,
  calculationData,
  form,
  selectedAccordion,
  sortedOptionsArray,
  optionsSum,
  tradeInEquityValue,
  allowedMileageData,
  quotationParameterData,
  setQuotationParameterData,
  setQuotationChanges,
  orderStatus
}) => {
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const { states, actions } = useStoreContext()
  const isOrderConditioned = orderStatus === Status.Conditioned
  const financialStips = (states.stipulationsData as any).stipulations?.filter(
    (stip: any) => stip.stipulation_type?.toLowerCase() === StipulationType.FINANCIAL
  )

  const isDisplayFinanceError = financialStips?.find(
    (stip: any) => stip.parameter === FinancialStipType.FINANCED_AMOUNT
  )?.fulfilled
  const isDisplayPeriodicError = financialStips?.find(
    (stip: any) => stip.parameter === FinancialStipType.PERIODIC_PAYMENT
  )?.fulfilled
  const isDisplayTermsError = financialStips?.find(
    (stip: any) => stip.parameter === FinancialStipType.TERMS
  )?.fulfilled

  const isOnlyFinanceAmount = financialStips?.some(
    (stip: any) => stip.parameter === FinancialStipType.FINANCED_AMOUNT
  )
  const isStipTerms = financialStips?.some(
    (stip: any) => stip.parameter === FinancialStipType.TERMS
  )
  const isOnlyMonthlyPayment = financialStips?.some(
    (stip: any) => stip.parameter === FinancialStipType.PERIODIC_PAYMENT
  )

  const isOnlyTerms =
    isOrderConditioned && isStipTerms && !isOnlyFinanceAmount && !isOnlyMonthlyPayment
  const dealerFee =
    leaseObj.finance_type == 'Finance'
      ? quotationParameterData?.finance_fees
      : quotationParameterData?.lease_fees
  const fni =
    leaseObj.finance_type == 'Finance'
      ? quotationParameterData?.finance_fni ?? []
      : quotationParameterData?.lease_fni ?? []
  const queryClient = useQueryClient()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const financedAmount =
    (calculationData?.selling_price ? calculationData?.selling_price : 0) -
    (calculationData?.down_payment ? calculationData?.down_payment : 0) +
    (dealerFee?.reduce((total: number, currentItem: any) => total + currentItem.applied_price, 0) ||
      0) +
    (fni?.reduce((total: number, currentItem: any) => total + currentItem.applied_price, 0) || 0) +
    (sortedOptionsArray?.reduce(
      (total: number, currentItem: any) =>
        total +
        ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
          !currentItem.is_price_inclusive) ||
        currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
          ? currentItem.applied_price || currentItem.price
          : 0),
      0
    ) || 0) -
    (tradeInEquityValue || 0)
  const totalRV =
    (((quotationParameterData?.selling_price || 0) *
      (leaseObj?.finance_type == 'Finance'
        ? quotationParameterData?.finance_rv_rate || 0
        : quotationParameterData?.lease_rv_rate || 0)) /
      100 || 0) +
    (sortedOptionsArray?.reduce(
      (total: number, currentItem: any) =>
        total +
        ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
          !currentItem.is_price_inclusive) ||
        currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
          ? currentItem.rv_balloon_value
            ? currentItem.rv_balloon_value
            : currentItem.rv_balloon_percentage
          : 0),
      0
    ) || 0)
  useEffect(() => {
    actions.setStipulationsInitialData({
      ...states.stipulationsData,
      monthly_payment: calculationData?.estimated_monthly_payment,
      finance_amount: financedAmount
    })
  }, [calculationData?.estimated_monthly_payment, financedAmount])
  const { control } = form
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  const theme = useTheme()

  const [open, setOpen] = useState({
    selling_price: false,
    finance_down_payment: false,
    lease_down_payment: false,
    lease_rv_rate: false,
    finance_rv_rate: false,
    fees: dealerFee?.map(() => false) ?? [],
    fni: fni && fni?.length > 0 ? fni?.map(() => false) : [],
    options: sortedOptionsArray?.map(() => false) ?? [],
    trade_in_equity: false
  })
  const tooltipMsg = () => {
    return (
      <>
        Please update the following financial parameters to fulfill this financial stipulation:
        <br />
        -Selling Price <br /> -Down Payment <br /> -RV/Balloon <br /> -Options <br /> -Fees and F&I
        Products <br /> -Trade-In
      </>
    )
  }

  return (
    <Box theme={theme} className="package-body">
      <Box theme={theme} className="package-row editable">
        <Box
          theme={theme}
          className={
            isOrderConditioned &&
            isDisplayPeriodicError === false &&
            isDisplayPeriodicError !== undefined
              ? 'alert alert-error'
              : ''
          }
        >
          {isOrderConditioned &&
          isDisplayPeriodicError === false &&
          isDisplayPeriodicError !== undefined ? (
            <Tooltip title={tooltipMsg()} theme={theme} placement="left">
              <Icon name="InfoIcon" />
            </Tooltip>
          ) : (
            <></>
          )}
          <b>
            <span className="symbol">{defaultCurrency}</span>
            {calculationData?.estimated_monthly_payment
              ? calculationData?.estimated_monthly_payment.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })
              : '-'}
          </b>
        </Box>
      </Box>
      {selectedAccordion.periodicPayment && (
        <>
          <Box theme={theme} className="package-row editable">
            <span className="symbol">{defaultCurrency}</span>
            {calculationData?.estimated_monthly_payment
              ? calculationData?.estimated_monthly_payment.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })
              : '-'}
          </Box>
          <Box theme={theme} className="package-row editable">
            {'-'}
          </Box>
        </>
      )}

      <Box theme={theme} className="package-row editable">
        <b>
          <span className="symbol">{defaultCurrency}</span>
          {calculationData?.due_at_signing
            ? leaseObj?.rental_mode == 'Advance'
              ? (
                  ((quotationParameterData?.selling_price || 0) *
                    ((leaseObj?.finance_type == 'Finance'
                      ? quotationParameterData?.finance_down_payment
                      : quotationParameterData?.lease_down_payment) || 0)) /
                    100 +
                  (calculationData?.estimated_monthly_payment || 0)
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })
              : (
                  ((quotationParameterData?.selling_price || 0) *
                    ((leaseObj?.finance_type == 'Finance'
                      ? quotationParameterData?.finance_down_payment
                      : quotationParameterData?.lease_down_payment) || 0)) /
                  100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })
            : '-'}
        </b>
      </Box>
      <Box theme={theme} className="package-row editable right-align">
        <Box
          theme={theme}
          className={
            isOrderConditioned && isDisplayTermsError === false && isDisplayTermsError !== undefined
              ? 'alert alert-error'
              : ''
          }
        >
          {quotationParameterData?.contract_term_id
            ? states?.contractTermsData?.filter(
                (term: any) => term.id == quotationParameterData?.contract_term_id
              )[0].term
            : '-'}
        </Box>
      </Box>
      <Box theme={theme} className="package-row editable right-align">
        {(
          leaseObj?.finance_type == 'Finance'
            ? quotationParameterData?.finance_rate
            : quotationParameterData?.lease_rate
        )
          ? (
              (leaseObj?.finance_type == 'Finance'
                ? quotationParameterData?.finance_rate
                : quotationParameterData?.lease_rate) || 0
            ).toLocaleString(undefined, {
              maximumFractionDigits: 4,
              minimumFractionDigits: 4
            })
          : '-'}
        <span className="symbol">%</span>
      </Box>

      <Box theme={theme} className="package-row editable">
        {leaseObj?.finance_type === 'Finance' && '-'}
        {leaseObj?.finance_type === 'Lease' &&
          (quotationParameterData?.annual_mileage_id ? (
            <>
              {allowedMileageData
                ?.filter(
                  (mileage: any) => mileage.id == quotationParameterData?.annual_mileage_id
                )[0]
                .value.toLocaleString()}{' '}
              <span className="symbol">{states?.lenderInfo?.mileage_unit}</span>
            </>
          ) : (
            '-'
          ))}
      </Box>

      <Box theme={theme} className="package-row blank">
        <hr />
      </Box>
      <Box theme={theme} className="package-row editable">
        {!open.selling_price && quotationParameterData?.selling_price && (
          <>
            <span className="symbol">{defaultCurrency}</span>

            {quotationParameterData?.selling_price
              ? parseFloat(quotationParameterData?.selling_price.toString()).toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }
                )
              : '-'}

            {isOnlyTerms ? (
              <></>
            ) : (
              <Button
                theme={theme}
                defaultBtn
                className="btn-edit"
                iconText={
                  <Icon
                    name="EditIcon"
                    onClick={() => {
                      setOpen({ ...open, selling_price: !open.selling_price })
                    }}
                  />
                }
              />
            )}
          </>
        )}
        {open.selling_price && (
          <Box theme={theme} className="editable-content">
            <Controller
              name="selling_price"
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  placeholder={'0.00'}
                  type="decimal"
                  startAdornment={<span className="start-adornment">{defaultCurrency}</span>}
                  onChange={field.onChange}
                  value={field.value}
                  masking
                  maskDecimalScale={2}
                  maskNumeric
                />
              )}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="TickIcon" />}
              onClick={() => {
                setOpen({ ...open, selling_price: !open.selling_price })
                setQuotationParameterData({
                  ...quotationParameterData,
                  selling_price: parseFloat(form.getValues()?.selling_price)
                })
                setQuotationChanges(true)
              }}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="CloseBlack" />}
              onClick={() => {
                setOpen({ ...open, selling_price: !open.selling_price })
                form.setValue('selling_price', quotationParameterData?.selling_price)
              }}
            />
          </Box>
        )}
      </Box>
      <Box theme={theme} className="package-row editable right-align">
        {!(leaseObj?.finance_type == 'Finance'
          ? open.finance_down_payment
          : open.lease_down_payment) &&
          (leaseObj?.finance_type == 'Finance'
            ? quotationParameterData?.finance_down_payment
            : quotationParameterData?.lease_down_payment) && (
            <>
              {leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_down_payment
                ? '(' +
                  (quotationParameterData?.finance_down_payment
                    ? quotationParameterData?.finance_down_payment
                    : 0
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }) +
                  '%) '
                : leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_down_payment
                ? '(' +
                  (quotationParameterData?.lease_down_payment
                    ? quotationParameterData?.lease_down_payment
                    : 0
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }) +
                  '%) '
                : ''}
              <span className="symbol">{defaultCurrency}</span>
              {leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_down_payment
                ? (
                    ((quotationParameterData?.selling_price || 0) *
                      quotationParameterData?.finance_down_payment) /
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_down_payment
                ? (
                    ((quotationParameterData?.selling_price || 0) *
                      quotationParameterData?.lease_down_payment) /
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
              {isOnlyTerms ? (
                <></>
              ) : (
                <Button
                  theme={theme}
                  defaultBtn
                  className="btn-edit"
                  iconText={
                    <Icon
                      name="EditIcon"
                      onClick={() => {
                        setOpen({
                          ...open,
                          [leaseObj?.finance_type == 'Finance'
                            ? 'finance_down_payment'
                            : 'lease_down_payment']: !(leaseObj?.finance_type == 'Finance'
                            ? open.finance_down_payment
                            : open.lease_down_payment)
                        })
                      }}
                    />
                  }
                />
              )}
            </>
          )}
        {(leaseObj?.finance_type == 'Finance'
          ? open.finance_down_payment
          : open.lease_down_payment) && (
          <Box theme={theme} className="editable-content">
            {leaseObj?.downpayment_chart && (
              <small className="caption">
                Value should be in between{' '}
                {leaseObj?.downpayment_chart.length > 0 &&
                  leaseObj?.downpayment_chart[0].minimum_down_payment}
                % to{' '}
                {leaseObj?.downpayment_chart.length > 0 &&
                  leaseObj?.downpayment_chart[0].maximum_down_payment}
                %
              </small>
            )}
            <Controller
              name={
                leaseObj?.finance_type == 'Finance' ? 'finance_down_payment' : 'lease_down_payment'
              }
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  placeholder={'0.00'}
                  type="decimal"
                  endAdornment={<span className="symbol">%</span>}
                  onChange={field.onChange}
                  value={field.value}
                  masking
                  maskDecimalScale={2}
                  maskNumeric
                />
              )}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="TickIcon" />}
              onClick={() => {
                const is_down_payment_valid =
                  (leaseObj?.downpayment_chart &&
                    leaseObj?.downpayment_chart[0].maximum_down_payment) >=
                    (leaseObj?.finance_type == 'Finance'
                      ? form.getValues().finance_down_payment
                      : form.getValues().lease_down_payment) &&
                  (leaseObj?.downpayment_chart &&
                    leaseObj?.downpayment_chart[0].minimum_down_payment) <=
                    (leaseObj?.finance_type == 'Finance'
                      ? form.getValues().finance_down_payment
                      : form.getValues().lease_down_payment)
                if (is_down_payment_valid || !leaseObj?.downpayment_chart) {
                  setOpen({
                    ...open,
                    [leaseObj?.finance_type == 'Finance'
                      ? 'finance_down_payment'
                      : 'lease_down_payment']: !(leaseObj?.finance_type == 'Finance'
                      ? open.finance_down_payment
                      : open.lease_down_payment)
                  })
                  setQuotationParameterData({
                    ...quotationParameterData,
                    [leaseObj?.finance_type == 'Finance'
                      ? 'finance_down_payment'
                      : 'lease_down_payment']:
                      leaseObj?.finance_type == 'Finance'
                        ? form.getValues().finance_down_payment
                        : form.getValues().lease_down_payment
                  })

                  setQuotationChanges(true)
                }
              }}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="CloseBlack" />}
              onClick={() => {
                setOpen({
                  ...open,
                  [leaseObj?.finance_type == 'Finance'
                    ? 'finance_down_payment'
                    : 'lease_down_payment']: !(leaseObj?.finance_type == 'Finance'
                    ? open.finance_down_payment
                    : open.lease_down_payment)
                })
                leaseObj?.finance_type == 'Lease'
                  ? form.setValue('lease_down_payment', quotationParameterData?.lease_down_payment)
                  : form.setValue(
                      'finance_down_payment',
                      quotationParameterData?.finance_down_payment
                    )
              }}
            />
          </Box>
        )}
      </Box>
      <Box theme={theme} className="package-row editable">
        {totalRV > 0 ? (
          <>
            <span className="symbol">{defaultCurrency}</span>
            {totalRV.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })}
          </>
        ) : (
          '-'
        )}
      </Box>
      {selectedAccordion.rvBalloon && (
        <>
          <Box theme={theme} className="package-row editable right-align">
            {!(leaseObj?.finance_type == 'Finance' ? open.finance_rv_rate : open.lease_rv_rate) && (
              <>
                {(leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_rv_rate) ||
                (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rv_rate)
                  ? leaseObj?.finance_type == 'Finance'
                    ? '(' + quotationParameterData?.finance_rv_rate + '%) '
                    : '(' + quotationParameterData?.lease_rv_rate + '%) '
                  : ''}
                {(leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_rv_rate) ||
                (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rv_rate) ? (
                  <>
                    <span className="symbol">{defaultCurrency}</span>
                    {(
                      ((quotationParameterData?.selling_price || 0) *
                        ((leaseObj?.finance_type == 'Finance'
                          ? quotationParameterData?.finance_rv_rate
                          : quotationParameterData?.lease_rv_rate) || 0)) /
                      100
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </>
                ) : (
                  '-'
                )}
                {isOnlyTerms ? (
                  <></>
                ) : (
                  <Button
                    theme={theme}
                    defaultBtn
                    className="btn-edit"
                    iconText={
                      <Icon
                        name="EditIcon"
                        onClick={() => {
                          setOpen({
                            ...open,
                            [leaseObj?.finance_type == 'Finance'
                              ? 'finance_rv_rate'
                              : 'lease_rv_rate']: !(leaseObj?.finance_type == 'Finance'
                              ? open.finance_rv_rate
                              : open.lease_rv_rate)
                          })
                        }}
                      />
                    }
                  />
                )}
              </>
            )}
            {(leaseObj?.finance_type == 'Finance' ? open.finance_rv_rate : open.lease_rv_rate) && (
              <Box theme={theme} className="editable-content">
                {leaseObj?.finance_type && (
                  <small className="caption">
                    Value should be in between{' '}
                    {leaseObj?.rv_chart.length > 0 && leaseObj?.rv_chart[0].minimum_rv}% to{' '}
                    {leaseObj?.rv_chart.length > 0 && leaseObj?.rv_chart[0].maximum_rv}%
                  </small>
                )}
                <Controller
                  name={leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate'}
                  control={control}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      placeholder={'0.00'}
                      type="decimal"
                      endAdornment={<span className="symbol">%</span>}
                      onChange={field.onChange}
                      value={field.value}
                      masking
                      maskDecimalScale={2}
                      maskNumeric
                    />
                  )}
                />
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="TickIcon" />}
                  onClick={() => {
                    const is_rv_rate_valid =
                      (leaseObj?.rv_chart && leaseObj?.rv_chart[0].maximum_rv) >=
                        (leaseObj?.finance_type == 'Finance'
                          ? Number(form.getValues().finance_rv_rate)
                          : Number(form.getValues().lease_rv_rate)) &&
                      (leaseObj?.rv_chart && leaseObj?.rv_chart[0].minimum_rv) <=
                        (leaseObj?.finance_type == 'Finance'
                          ? Number(form.getValues().finance_rv_rate)
                          : Number(form.getValues().lease_rv_rate))
                    if (is_rv_rate_valid || !leaseObj?.rv_chart) {
                      setOpen({
                        ...open,
                        [leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate']:
                          !(leaseObj?.finance_type == 'Finance'
                            ? open.finance_rv_rate
                            : open.lease_rv_rate)
                      })
                      setQuotationParameterData({
                        ...quotationParameterData,
                        [leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate']:
                          leaseObj?.finance_type == 'Finance'
                            ? form.getValues().finance_rv_rate
                            : form.getValues().lease_rv_rate
                      })
                      setQuotationChanges(true)
                    }
                  }}
                />
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="CloseBlack" />}
                  onClick={() => {
                    setOpen({
                      ...open,
                      [leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate']:
                        !(leaseObj?.finance_type == 'Finance'
                          ? open.finance_rv_rate
                          : open.lease_rv_rate)
                    })
                  }}
                />
              </Box>
            )}
          </Box>
          <Box theme={theme} className="package-row editable">
            <span className="symbol">{defaultCurrency}</span>
            {sortedOptionsArray
              ?.reduce(
                (total: number, currentItem: any) =>
                  total +
                  ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                    !currentItem.is_price_inclusive) ||
                  currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                    ? currentItem.rv_balloon_value
                      ? currentItem.rv_balloon_value
                      : currentItem.rv_balloon_percentage
                    : 0),
                0
              )
              .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
          </Box>
        </>
      )}
      <Box theme={theme} className="package-row editable">
        {sortedOptionsArray && sortedOptionsArray?.length > 0 && optionsSum !== 0 ? (
          <>
            <span className="symbol">{defaultCurrency}</span>
            {(
              sortedOptionsArray?.reduce(
                (total: number, currentItem: any) =>
                  total +
                  ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                    !currentItem.is_price_inclusive) ||
                  currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                    ? currentItem.applied_price ?? currentItem.price
                    : 0),
                0
              ) || 0
            ).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })}
          </>
        ) : (
          '-'
        )}
      </Box>
      {selectedAccordion.options &&
        sortedOptionsArray?.length > 0 &&
        sortedOptionsArray?.map((obj: any, index: any) => (
          <div key={index}>
            {sortedOptionsArray[index - 1]?.installation_mode !== obj?.installation_mode && (
              <Box theme={theme} className="package-row blank"></Box>
            )}
            <Box theme={theme} className="package-row editable">
              {!open.options[index] && (
                <>
                  {(obj.installation_mode === InstallationMode.PRE_INSTALLED &&
                    !obj.is_price_inclusive) ||
                  obj.installation_mode !== InstallationMode.PRE_INSTALLED ? (
                    <>
                      {(obj?.price || obj?.applied_price) === 0 ? (
                        '-'
                      ) : (
                        <>
                          <span className="symbol">{defaultCurrency}</span>
                          {(obj?.price || obj?.applied_price).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}
                        </>
                      )}
                      <Button
                        theme={theme}
                        defaultBtn
                        className="btn-edit"
                        iconText={
                          <Icon
                            name="EditIcon"
                            onClick={() => {
                              const openOptions = open.options
                              openOptions[index] = !openOptions[index]
                              setOpen({ ...open, options: openOptions })
                            }}
                          />
                        }
                      />
                    </>
                  ) : (
                    '-'
                  )}
                </>
              )}
              {open.options[index] && (
                <Box theme={theme} className="editable-content">
                  <Controller
                    name={`options[${index}].applied_price`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        placeholder={'0.00'}
                        type="decimal"
                        startAdornment={<span className="start-adornment">{defaultCurrency}</span>}
                        onChange={field.onChange}
                        value={field.value}
                        masking
                        maskDecimalScale={2}
                        maskNumeric
                      />
                    )}
                  />
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="TickIcon" />}
                    onClick={() => {
                      const openOptions = open.options
                      openOptions[index] = !openOptions[index]
                      setOpen({ ...open, options: openOptions })
                      setQuotationParameterData({
                        ...quotationParameterData,
                        options: quotationParameterData?.options?.map((option: any) =>
                          option.option_id == obj.option_id
                            ? {
                                ...option,
                                applied_price: parseFloat(
                                  form.getValues().options[index].applied_price
                                )
                              }
                            : option
                        )
                      })
                      setQuotationChanges(true)
                    }}
                  />
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="CloseBlack" />}
                    onClick={() => {
                      const openOptions = open.options
                      openOptions[index] = !openOptions[index]
                      setOpen({ ...open, options: openOptions })
                      form.setValue(
                        `options[${index}].applied_price`,
                        quotationParameterData?.options &&
                          quotationParameterData?.options[index]?.applied_price
                      )
                    }}
                  />
                </Box>
              )}
            </Box>
          </div>
        ))}
      <Box theme={theme} className="package-row editable">
        {dealerFee &&
        dealerFee?.reduce(
          (total: number, currentItem: any) => total + currentItem.applied_price,
          0
        ) > 0 ? (
          <>
            <span className="symbol">{defaultCurrency}</span>
            {dealerFee
              ?.reduce((total: number, currentItem: any) => total + currentItem.applied_price, 0)
              .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
          </>
        ) : (
          '-'
        )}
      </Box>
      {selectedAccordion.fees &&
        (quotationParameterData?.lease_fees || [])
          .concat(
            (quotationParameterData?.finance_fees || [])?.filter(
              (item) =>
                (quotationParameterData?.lease_fees || [])?.findIndex(
                  (fee: any) => fee.dealer_fee_id == item.dealer_fee_id
                ) < 0
            )
          )
          .map((obj: any, index: any) => (
            <div key={index}>
              <Box theme={theme} className="package-row editable">
                {!open.fees[index] && (
                  <>
                    {dealerFee?.some((fee: any) => fee.dealer_fee_id === obj.dealer_fee_id) ? (
                      <>
                        {dealerFee?.filter((fee: any) => fee.dealer_fee_id == obj.dealer_fee_id)[0]
                          ?.applied_price > 0 ? (
                          <>
                            <span className="symbol">{defaultCurrency}</span>
                            {dealerFee
                              ?.filter((fee: any) => fee.dealer_fee_id == obj.dealer_fee_id)[0]
                              ?.applied_price?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                          </>
                        ) : (
                          '-'
                        )}

                        <Button
                          theme={theme}
                          defaultBtn
                          className="btn-edit"
                          iconText={
                            <Icon
                              name="EditIcon"
                              onClick={() => {
                                const openFees = open.fees
                                openFees[index] = !openFees[index]
                                setOpen({ ...open, fees: openFees })
                              }}
                            />
                          }
                        />
                      </>
                    ) : (
                      '-'
                    )}
                  </>
                )}
                {open.fees[index] && (
                  <Box theme={theme} className="editable-content">
                    <Controller
                      name={
                        leaseObj.finance_type == 'Finance'
                          ? `finance_fees[${quotationParameterData.finance_fees?.findIndex(
                              (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                            )}].applied_price`
                          : `lease_fees[${quotationParameterData.lease_fees?.findIndex(
                              (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                            )}].applied_price`
                      }
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          placeholder={'0.00'}
                          type="decimal"
                          startAdornment={
                            <span className="start-adornment">{defaultCurrency}</span>
                          }
                          onChange={field.onChange}
                          value={field.value}
                          masking
                          maskDecimalScale={2}
                          maskNumeric
                        />
                      )}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="TickIcon" />}
                      onClick={() => {
                        const openFees = open.fees
                        openFees[index] = !openFees[index]
                        setOpen({ ...open, fees: openFees })
                        setQuotationParameterData({
                          ...quotationParameterData,
                          [leaseObj?.finance_type == 'Finance' ? 'finance_fees' : 'lease_fees']:
                            leaseObj?.finance_type == 'Finance'
                              ? quotationParameterData?.finance_fees?.map((fee: any) =>
                                  fee.dealer_fee_id == obj.dealer_fee_id
                                    ? {
                                        ...fee,
                                        applied_price: parseFloat(
                                          form.getValues().finance_fees[
                                            quotationParameterData.finance_fees?.findIndex(
                                              (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                            ) || 0
                                          ].applied_price
                                        )
                                      }
                                    : fee
                                )
                              : quotationParameterData?.lease_fees?.map((fee: any) =>
                                  fee.dealer_fee_id == obj.dealer_fee_id
                                    ? {
                                        ...fee,
                                        applied_price: parseFloat(
                                          form.getValues().lease_fees[
                                            quotationParameterData.lease_fees?.findIndex(
                                              (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                            ) || 0
                                          ].applied_price
                                        )
                                      }
                                    : fee
                                )
                        })
                        setQuotationChanges(true)
                      }}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="CloseBlack" />}
                      onClick={() => {
                        const openFees = open.fees
                        openFees[index] = !openFees[index]
                        setOpen({ ...open, fees: openFees })
                        leaseObj?.finance_type == 'Lease'
                          ? form.setValue(
                              `lease_fees[${quotationParameterData.lease_fees?.findIndex(
                                (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                              )}].applied_price`,
                              quotationParameterData?.lease_fees &&
                                quotationParameterData?.lease_fees.filter(
                                  (fee: any) => fee.dealer_fee_id == obj.dealer_fee_id
                                )[0]?.applied_price
                            )
                          : form.setValue(
                              `finance_fees[${quotationParameterData.finance_fees?.findIndex(
                                (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                              )}].applied_price`,
                              quotationParameterData?.finance_fees &&
                                quotationParameterData?.finance_fees.filter(
                                  (fee: any) => fee.dealer_fee_id == obj.dealer_fee_id
                                )[0]?.applied_price
                            )
                      }}
                    />
                  </Box>
                )}
              </Box>
            </div>
          ))}

      <Box theme={theme} className="package-row editable">
        {fni && fni?.length > 0 ? (
          <>
            {fni
              ?.reduce((total: number, currentItem: any) => total + currentItem.applied_price, 0)
              .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) !==
            '0.00' ? (
              <>
                <span className="symbol">{defaultCurrency}</span>
                <span>
                  {fni
                    ?.reduce(
                      (total: number, currentItem: any) => total + currentItem.applied_price,
                      0
                    )
                    .toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                </span>
              </>
            ) : (
              '-'
            )}
          </>
        ) : (
          '-'
        )}
      </Box>
      {selectedAccordion.fIProducts &&
        (quotationParameterData?.lease_fni || [])
          .concat(
            (quotationParameterData?.finance_fni || [])?.filter(
              (item) =>
                (quotationParameterData?.lease_fni || [])?.findIndex(
                  (fni: any) => fni.financial_insurance_id == item.financial_insurance_id
                ) < 0
            )
          )
          .map((obj: any, index: any) => (
            <div key={index}>
              <Box theme={theme} className="package-row editable">
                {!open.fni[index] && (
                  <>
                    {fni?.some(
                      (fni: any) => fni.financial_insurance_id === obj.financial_insurance_id
                    ) ? (
                      <>
                        {fni?.filter(
                          (fni: any) => fni.financial_insurance_id == obj.financial_insurance_id
                        )[0]?.applied_price === 0 ? (
                          '-'
                        ) : (
                          <>
                            <span className="symbol">{defaultCurrency}</span>
                            <span>
                              {fni
                                ?.filter(
                                  (fni: any) =>
                                    fni.financial_insurance_id == obj.financial_insurance_id
                                )[0]
                                ?.applied_price?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}
                            </span>
                          </>
                        )}

                        <Button
                          theme={theme}
                          defaultBtn
                          className="btn-edit"
                          iconText={
                            <Icon
                              name="EditIcon"
                              onClick={() => {
                                const openFNI = open.fni
                                openFNI[index] = !openFNI[index]
                                setOpen({ ...open, fni: openFNI })
                              }}
                            />
                          }
                        />
                      </>
                    ) : (
                      '-'
                    )}
                  </>
                )}
                {open.fni[index] && (
                  <Box theme={theme} className="editable-content">
                    <Controller
                      name={
                        leaseObj.finance_type == 'Finance'
                          ? `finance_fni[${quotationParameterData.finance_fni?.findIndex(
                              (fni: any) => fni.financial_insurance_id == obj.financial_insurance_id
                            )}].applied_price`
                          : `lease_fni[${quotationParameterData.lease_fni?.findIndex(
                              (fni: any) => fni.financial_insurance_id == obj.financial_insurance_id
                            )}].applied_price`
                      }
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          placeholder={'0.00'}
                          type="decimal"
                          startAdornment={
                            <span className="start-adornment">{defaultCurrency}</span>
                          }
                          onChange={field.onChange}
                          value={field.value}
                          masking
                          maskDecimalScale={2}
                          maskNumeric
                        />
                      )}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="TickIcon" />}
                      onClick={() => {
                        const openFNI = open.fni
                        openFNI[index] = !openFNI[index]
                        setOpen({ ...open, fni: openFNI })
                        setQuotationParameterData({
                          ...quotationParameterData,
                          [leaseObj?.finance_type == 'Finance' ? 'finance_fni' : 'lease_fni']:
                            leaseObj?.finance_type == 'Finance'
                              ? quotationParameterData?.finance_fni?.map((fni: any) =>
                                  fni.financial_insurance_id == obj.financial_insurance_id
                                    ? {
                                        ...fni,
                                        applied_price: parseFloat(
                                          form.getValues().finance_fni[
                                            quotationParameterData.finance_fni?.findIndex(
                                              (fni: any) =>
                                                fni.financial_insurance_id ==
                                                obj.financial_insurance_id
                                            ) || 0
                                          ].applied_price
                                        )
                                      }
                                    : fni
                                )
                              : quotationParameterData?.lease_fni?.map((fni: any) =>
                                  fni.financial_insurance_id == obj.financial_insurance_id
                                    ? {
                                        ...fni,
                                        applied_price: parseFloat(
                                          form.getValues().lease_fni[
                                            quotationParameterData.lease_fni?.findIndex(
                                              (fni: any) =>
                                                fni.financial_insurance_id ==
                                                obj.financial_insurance_id
                                            ) || 0
                                          ].applied_price
                                        )
                                      }
                                    : fni
                                )
                        })
                        setQuotationChanges(true)
                      }}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="CloseBlack" />}
                      onClick={() => {
                        const openFNI = open.fni
                        openFNI[index] = !openFNI[index]
                        setOpen({ ...open, fni: openFNI })
                        leaseObj?.finance_type == 'Lease'
                          ? form.setValue(
                              `lease_fni[${index}].applied_price`,
                              quotationParameterData?.lease_fni &&
                                quotationParameterData?.lease_fni[
                                  quotationParameterData.lease_fni?.findIndex(
                                    (fni: any) =>
                                      fni.financial_insurance_id == obj.financial_insurance_id
                                  ) || 0
                                ]?.applied_price
                            )
                          : form.setValue(
                              `finance_fni[${index}].applied_price`,
                              quotationParameterData?.finance_fni &&
                                quotationParameterData?.finance_fni[
                                  quotationParameterData.finance_fni?.findIndex(
                                    (fni: any) =>
                                      fni.financial_insurance_id == obj.financial_insurance_id
                                  ) || 0
                                ]?.applied_price
                            )
                      }}
                    />
                  </Box>
                )}
              </Box>
            </div>
          ))}
      {/* <Box theme={theme} className="package-row editable">
      <span className="symbol">{defaultCurrency}</span> {"-"}
      </Box> */}
      <Box theme={theme} className="package-row editable">
        {tradeInEquityValue && tradeInEquityValue > 0 ? (
          <>
            <span className="symbol">{defaultCurrency}</span>
            {Math.abs(tradeInEquityValue).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })}
          </>
        ) : tradeInEquityValue && tradeInEquityValue < 0 ? (
          <>
            {'-'}
            <span className="symbol">{defaultCurrency}</span>
            {Math.abs(tradeInEquityValue).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })}
          </>
        ) : (
          '-'
        )}
      </Box>
      <Box theme={theme} className="package-row editable">
        {'-'}
      </Box>
      <Box theme={theme} className="package-row editable">
        <Box
          theme={theme}
          className={
            isOrderConditioned &&
            isDisplayFinanceError === false &&
            isDisplayFinanceError !== undefined
              ? 'alert alert-error'
              : ''
          }
        >
          {isOrderConditioned &&
          isDisplayFinanceError === false &&
          isDisplayFinanceError !== undefined ? (
            <Tooltip title={tooltipMsg()} theme={theme} placement="left">
              <Icon name="InfoIcon" />
            </Tooltip>
          ) : (
            <></>
          )}
          {financedAmount ? (
            <>
              <span className="symbol">{defaultCurrency}</span>
              {financedAmount.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          ) : (
            '-'
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default PackageLease
