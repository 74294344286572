import Http from '@helpers/http-api'
import { useMutation } from 'react-query'
import {
  EP_GET_DEALER_FEE,
  EP_DEALER_PREFERENCE,
  EP_GET_FEES,
  EP_DEALER_PREFERENCE_BY_DEALER_CODE,
  EP_SAVE_DEALER_PROFILE,
  EP_DEALER_TRADEIN,
  EP_DEALER_PAYMENT,
  EP_DEALER_PAYMENT_BY_DEALER_ID,
  EP_DEALER_TRADEIN_BY_DEALER_ID,
  EP_SAVE_PEN_DEALER_ID,
  EP_DEALER_CONFIGURATIONS,
  EP_GET_DEALER_PROFILE_BY_ID,
  EP_GET_DEALER_AND_LENDER,
  EP_GET_LENDAR_BY_TENANT_ID
} from '@helpers/endpoints'
import { useStoreContext } from '@store/storeContext'
import {
  IDealerPreference,
  IDealerProfile,
  IDealerTradeIn,
  IDealerPayment,
  IDealerConfigurations
} from '@models'
import { getMarketPlaceToken } from '@helpers/methods'

const token = getMarketPlaceToken()
const headers = {
  Authorization: `Bearer ${token}`
}

export const useGetDealerAndLender = (): any => {
  // const { actions } = useStoreContext();
  const { data, isLoading, error, mutate } = useMutation<{ body: any }, any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    const { email, tenant_id }: any = body
    return apiService.get<any>(
      EP_GET_DEALER_AND_LENDER + `?tenant_id=${tenant_id}&email=${email}`,
      {},
      { headers }
    )
  })
  return { data, isLoading, error, mutate }
}

export const useGetLenderByTenantId = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{ tenant_id: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(`${EP_GET_LENDAR_BY_TENANT_ID}/${body.tenant_id}`, {})
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetDealerFee = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_GET_DEALER_FEE)
    },
    {
      onSuccess(_response: any) {
        actions.setDealerFeeInitialData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useDeleteDealerFeeById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.delete<any>(`${EP_GET_DEALER_FEE}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useUpdateDealerFeeById = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_GET_DEALER_FEE}/${body.id}`, body)
    },
    {
      onSuccess(response: any) {
        actions.updateDealerFeeRow(response)
        actions.setToast({
          toastMessage: 'Data Updated Sucessfully',
          toastState: true
        })
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetDealerFeeById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_DEALER_FEE}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveDealerPreference = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerPreference, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerPreference>(`${EP_DEALER_PREFERENCE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useGetPreferenceByDealerCode = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ dealer_code: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(
        `${EP_DEALER_PREFERENCE_BY_DEALER_CODE}/${body.dealer_code}`,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setDealerPreferenceData(_response)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateDealerPreference = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerPreference, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<IDealerPreference>(`${EP_DEALER_PREFERENCE}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetPreference = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_DEALER_PREFERENCE)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useSaveDealerFee = (): any => {
  const { data, isLoading, mutate } = useMutation<
    {
      id?: number
      fee_name: string
      state_id: number[]
      default_amount: number
      taxable: boolean
      is_active: boolean
      dealer_id: number
    },
    any
  >(
    (body: any) => {
      body.dealer_id = 1
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_GET_DEALER_FEE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetFees = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get<any>(EP_GET_FEES)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetTradeInByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_DEALER_TRADEIN_BY_DEALER_ID}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveDealerTradeIn = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerTradeIn, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerTradeIn>(`${EP_DEALER_TRADEIN}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateDealerTradeIn = (): any => {
  //update method
  const { data, isLoading, mutate } = useMutation<IDealerTradeIn, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<IDealerTradeIn>(`${EP_DEALER_TRADEIN}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveDealerProfile = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerProfile>(`${EP_SAVE_DEALER_PROFILE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useGetProfilebyDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_SAVE_DEALER_PROFILE}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useUpdateDealerProfilebyId = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<IDealerProfile>(`${EP_SAVE_DEALER_PROFILE}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdatePenDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<IDealerProfile>(
        `${EP_SAVE_PEN_DEALER_ID}/${body.id}/${body.pen_dealer_id}`,
        body
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useSaveDealerPayment = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerPayment, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerPayment>(`${EP_DEALER_PAYMENT}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetPaymentByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_DEALER_PAYMENT_BY_DEALER_ID}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetDealerProfileById = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(
        `${EP_GET_DEALER_PROFILE_BY_ID}/${body.dealer_id}`,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setDealerProfileData(_response)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateDealerPaymentById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_DEALER_PAYMENT}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveDealerConfigurations = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerConfigurations, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerConfigurations>(`${EP_DEALER_CONFIGURATIONS}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetAllDealerFeebyState = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ dealer_id: number; state_code: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      body.dealer_id ? `${body.dealer_id}` : ''
      return apiService.get<any>(
        `${`dealer/fee/dealer-code`}/${body.dealer_id}?state_code=${body.state_code}`,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setOfferInitialData({ order_fees: _response })
      }
    }
  )
  return { data, isLoading, mutate }
}
