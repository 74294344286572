import jwt_decode from 'jwt-decode'
// import { LocalStorage } from "@helpers/browserStorage";
import {
  EXTERNAL_SUBSCRIPTION_KEY,
  // ACCESS_TOKEN_KEY,
  SETTINGS,
  urlPattern
} from '@helpers/constants'
import { RegisterOptions } from 'react-hook-form'
import dayjs from 'dayjs'
import { IntegrationProvider, LenderIntegrationType } from './enums'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
export const validation = (
  name: string,
  required: boolean,
  matchPattern: boolean = false,
  passwordRegex: RegExp = new RegExp(''),
  regexMessage: string = ''
): RegisterOptions => ({
  required: {
    value: required,
    message: `${name} is required`
  },
  validate: {
    matchesPattern: (value: string | undefined) => {
      if (matchPattern && value && !passwordRegex.exec(value)) {
        return `${regexMessage}`
      }
      return true
    }
  }
})

export const getUser = (): any | undefined => {
  // const localStorage: LocalStorage = new LocalStorage();
  const token = getMarketPlaceToken()
  return jwt_decode(token as string) || undefined
}

export const getMarketPlaceToken = (): any | undefined => {
  const settings: any = localStorage.getItem(SETTINGS)
  return JSON.parse(settings)?.access_token
}

export const getAllSettingsFromLocalStorage = (): any | undefined => {
  const settings: any = localStorage.getItem(SETTINGS)
  return JSON.parse(settings)
}

export const getANToken = (): any | undefined => {
  const token1: any = localStorage.getItem('settings')
  const token = JSON.parse(token1)?.access_token
  return token
}

export const getDecodedANToken = (access_token: string): any | undefined => {
  return jwt_decode(access_token as string) || undefined
}

export const dateTime12HourFormat = () => {
  const currentDate = new Date()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const year = currentDate.getFullYear()

  return (
    month +
    '/' +
    day +
    '/' +
    year +
    ' ' +
    currentDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    })
  )
}

export const convertToDecimal = (amount: number): string => amount.toFixed()

export const formatedDate = (date: string | Date, format: string): string =>
  dayjs(date).format(format)

export const dateFormat = 'MM/DD/YYYY'

export const containsKeyValue = (obj: any, key: any, value: any) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop) && prop === key && obj[prop] === value) {
      return true
    }
  }
  return false
}

export const openInNextWindow = (url: string): Window | null => window.open(url)

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const result: string = reader.result as string
      const base64Data = result.split(',')[1] // Remove the data URL scheme
      resolve(base64Data)
    }
    reader.onerror = (error) => reject(error)
  })
}

export const isEmpty = (value: any) =>
  value === undefined ||
  value === null ||
  (typeof value === 'string' && !value.trim().length) ||
  (typeof value === 'object' && !Object.keys(value).length)

export const formatPayloadDate = (date: any) => dayjs(date).format('YYYY-MM-DD')
export const getGoogleKey = (integrationsData: any) => {
  let googleKey = null
  const integarationObj = integrationsData?.find(
    (x: any) =>
      x?.integration_type === LenderIntegrationType.ADDRESS_VALIDATION &&
      x?.provider_name === IntegrationProvider.GOOGLE &&
      x?.is_active
  )
  if (integarationObj && integarationObj?.lender_integration_user_inputs?.length > 0) {
    googleKey = integarationObj?.lender_integration_user_inputs[0]?.user_input_value
  }
  return googleKey
}

export const isValidUrl = (url: any) => {
  return urlPattern.test(url)
}

export const formatDate = (inputDate: any) => {
  const date = new Date(inputDate)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${month}/${day}/${year}` // returns format MM/DD/YYYY
}

export const convertDateTimeToISOZFormat = (inputDateTime: any) => {
  const parts = inputDateTime.split(' ')
  const dateParts = parts[0].split('/')
  const timeParts = parts[1].split(':')

  let hours = parseInt(timeParts[0])
  const minutes = parseInt(timeParts[1])
  const isPM = parts[2] === 'PM'

  if (isPM && hours !== 12) {
    hours += 12
  } else if (!isPM && hours === 12) {
    hours = 0
  }

  const isoFormattedDateTime = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${hours
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00.000Z`

  return isoFormattedDateTime
}

export const removeUndefinedKeys = (obj: any) => {
  const newObj: any = {}

  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined) {
      newObj[key] = value
    }
  }

  return newObj
}

export const getInitials = (firstName: string, lastName: string): string => {
  let firstInitial = firstName[0]?.toUpperCase() || ''
  let lastInitial = lastName[0]?.toUpperCase() || ''

  return firstInitial + lastInitial
}

export const dateAndTimeIn12HourFormat = () => {
  const date = new Date().toLocaleDateString().split('/')
  let actualReturnDate =
    date[1] +
    '/' +
    date[0] +
    '/' +
    date[2] +
    ' ' +
    new Date().toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
  return actualReturnDate
}

export const formatDateAsDay_Month_Year = (inputDate: any) => {
  const date = new Date(inputDate)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${day}/${month}/${year}` // returns format MM/DD/YYYY
}

export const removeSpecialChars = (input: unknown): string => {
  // Convert input to string and remove all non-numeric characters
  const cleanedString = String(input).replace(/\D+/g, '')
  // Format the cleaned string as "(###) ###-####"
  const formattedString = cleanedString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  return formattedString
}

export const setExternalSubscriptionKey = (key: string) => {
  localStorage.setItem(EXTERNAL_SUBSCRIPTION_KEY, JSON.stringify(key))
}

export const getExternalSubscriptionKey = (): string => {
  const externalSubscriptionKey: any = localStorage.getItem(EXTERNAL_SUBSCRIPTION_KEY)
  return JSON.parse(externalSubscriptionKey) as string
}
export const compareAnswers = (formValues: any, checklistData: any) => {
  const checklistErrors = []

  // Check if checklistData is defined and not empty
  if (checklistData && checklistData.length > 0 && checklistData[0]?.length > 0) {
    for (const [question, answer] of Object.entries(formValues)) {
      const matchingQuestion = checklistData[0][0]?.questions?.find(
        (q: { question: string }) => q?.question === question
      )

      if (matchingQuestion && answer !== undefined && answer !== null && answer !== '') {
        const matchingAnswer = matchingQuestion?.answers?.find(
          (a: { answer: any; is_correct?: boolean }) =>
            a?.answer === answer && (a?.is_correct ?? false)
        )

        if (!matchingAnswer?.is_correct) {
          checklistErrors.push({ question, message: 'Amend the option to proceed.' })
        }
      }
    }
  }
  return checklistErrors
}
export const removeApostrophes = (question: string) => {
  // Check if question is null or undefined
  if (question == null) {
    return '' // or handle accordingly
  }

  // Replace apostrophes with an empty string
  return question.replace(/'/g, '')
}

export const question_check = (formData: { [x: string]: any }, questionArray: any[]) => {
  // Check if formData is defined and not null
  if (formData) {
    const formDataWithOriginalQuestions = Object.keys(formData).reduce(
      (acc: { [x: string]: any }, key) => {
        // Find the corresponding entry in the original and modified questions array
        const questionEntry = questionArray.find(
          (entry: { modifiedQuestion: string }) => entry.modifiedQuestion === key
        )

        // If found, replace the key with the original question
        if (questionEntry) {
          acc[questionEntry.originalQuestion as string] = formData[key]
        } else {
          // If not found, keep the key as it is
          acc[key] = formData[key]
        }

        return acc
      },
      {}
    )

    return formDataWithOriginalQuestions
  } else {
    // If formData is undefined or null, return an empty object
    return {}
  }
}

export const utcToLocal = (date: string, format?: string) => {
  if (date) {
    const utcDate = dayjs.utc(date)
    const localDate = utcDate.tz(dayjs.tz.guess())
    return format ? localDate.format(format) : localDate.format()
  } else return ''
}

export const formatPhoneNumber = (phoneNumber: any) => {
  // Remove any non-numeric characters from the phone number
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')

  // Apply the formatting
  const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

  return formatted
}

export const addConditionToRequestBody = (
  field: string,
  value: any,
  operator: string,
  requestBody: any[]
) => {
  // Push the condition object to the requestBody array
  requestBody.push({
    field,
    operator: operator,
    value,
    and_or: 'And'
  })
}

export const initialDealerWorkQueueState = {
  result: [],
  page_number: 0,
  page_size: 0,
  total_results: 0,
  total_pages: 0
}
