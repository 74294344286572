import { FC } from 'react'
import { useTheme } from '@mui/material'

import { Button, Grid, Icon, Skeleton, Typography } from '@ntpkunity/controls'

import { Box, DetailCard } from '@components'

import { useStoreContext } from '@store/storeContext'
import { formatPhoneNumber, utcToLocal } from '@helpers/methods'
import { useDonwnloadDocument } from '@apis/dealer-workqueue.service'
import { Documents } from '@app/in-progress/ready-check-list/documentStyle'

const DealerOrderInsuranceInformation: FC<{ insuranceLoading: any; dataLoading: any }> = ({
  insuranceLoading,
  dataLoading
}) => {
  const {
    states: { insuranceInformation }
  } = useStoreContext()
  const { mutate: getDocURL } = useDonwnloadDocument()

  const theme = useTheme()
  const customerAddress = insuranceInformation
    ? insuranceInformation?.insurer_address?.address_line_1
    : ''
  const customerAddress2 = insuranceInformation
    ? insuranceInformation?.insurer_address?.address_line_2
    : ''
  const stateName = insuranceInformation ? insuranceInformation?.insurer_address?.state_name : ''
  const city = insuranceInformation ? insuranceInformation?.insurer_address?.city : ''
  const zipCode = insuranceInformation ? insuranceInformation?.insurer_address?.zip_code : ''

  const completeAddress =
    (customerAddress ? customerAddress + ', ' : '') +
    (customerAddress2 ? customerAddress2 + ', ' : '') +
    (city ? city + ', ' : '') +
    (stateName ? stateName + ' ' : '') +
    (zipCode ? zipCode : '')

  return (
    <>
      <DetailCard mb={2}>
        <Typography theme={theme} mb={2} component="h4" variant="h4">
          Insurance Details
        </Typography>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Insurance Provider"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {insuranceLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                children={insuranceInformation?.insurer_name ?? '-'}
              />
            )}
          </Grid>
        </Grid>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Insurance Contact"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {insuranceLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                children={
                  insuranceInformation?.agent_phone
                    ? formatPhoneNumber(insuranceInformation?.agent_phone)
                    : '-'
                }
              />
            )}
          </Grid>
        </Grid>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Policy Number"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {insuranceLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                children={insuranceInformation?.policy_number ?? '-'}
              />
            )}
          </Grid>
        </Grid>
        <Grid className="container" theme={theme} container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Expiration"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {insuranceLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="h3"
                className="label-detail"
                children={
                  insuranceInformation?.expiration_date
                    ? utcToLocal(insuranceInformation?.expiration_date, 'MM-DD-YYYY')
                    : '-'
                }
              />
            )}
          </Grid>
        </Grid>
        <Grid className="container" theme={theme} container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Address"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {insuranceLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="h3"
                className="label-detail"
                children={completeAddress ? completeAddress : '-'}
              />
            )}
          </Grid>
        </Grid>
        {insuranceInformation && insuranceInformation?.insurance_documents?.length > 0 && (
          <Documents>
            <>
              <Grid
                theme={theme}
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ mt: 0.5 }}
              >
                {insuranceInformation?.insurance_documents?.map((item: any, index: any) => {
                  return (
                    <Grid item xs={12} sm={6} md={6} lg={6} theme={theme} key={item?.identifier}>
                      <Box theme={theme} className="card">
                        <Box theme={theme} className="row">
                          {item?.content_type === ('image/jpeg' || 'image/png') ? (
                            <Icon name="ImageIcon" />
                          ) : (
                            <Icon name="PdfFileIcon" />
                          )}
                          <Typography theme={theme} variant="body2" component="p">
                            {item?.name}
                          </Typography>
                        </Box>

                        <Box theme={theme} className="row" key={index}>
                          <Button
                            theme={theme}
                            defaultBtn
                            onClick={() => {
                              getDocURL(
                                {
                                  artifact_id: item?.document_uri
                                },
                                {
                                  onSuccess(res: any) {
                                    window.open(res?.document_url)
                                  }
                                }
                              )
                            }}
                            iconText={<Icon name="IcView" />}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </>
          </Documents>
        )}
      </DetailCard>
    </>
  )
}
export default DealerOrderInsuranceInformation
