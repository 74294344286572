import Http from '@helpers/http-api'
import { useMutation } from 'react-query'
import {
  EP_GET_REFERENCE_DATA_BY_DEALER_CODE,
  EP_ASSET_CONDITIONS,
  EP_VEHICLE,
  EP_GET_VEHICLES_BY_DEALER_CODE,
  EP_GET_VEHICLES_BY_FILTER,
  EP_GET_VEHICLES_INVENTORY_BY_DEALER_CODE,
  EP_REFERRAL_INVITE,
  EP_VEHICLE_DETAIL,
  EP_GET_INVENTORY_OPTIONS_DETAIL,
  EP_UPDATE_VEHICLE_DETAIL,
  EP_VEHICLE_BY_VIN,
  EP_VEHICLE_BY_MODEL_CODE,
  EP_CALCULATE_ORDER_QUOTATION
  // EP_GET_LENDAR_BY_TENANT_ID
} from '@helpers/endpoints'
import { useStoreContext } from '@store/storeContext'
import { IQuotationComparison, IVehicle, IVehicleDetail } from '@models'
import { getMarketPlaceToken } from '@helpers/methods'

const token = getMarketPlaceToken()
const headers: any = {
  Authorization: `Bearer ${token}`
}
export const useGetInventory = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>(() => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    return apiService.get<any>(EP_VEHICLE, {}, { headers })
  })
  return { data, isLoading, error, mutate }
}

export const useGetInventoryByDealerCode = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{ dealer_code: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_VEHICLES_BY_DEALER_CODE}/${body.dealer_code}`)
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetFilterReferenceData = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{ dealer_code: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(
        `${EP_GET_REFERENCE_DATA_BY_DEALER_CODE}/${body.dealer_code}`,
        {},
        { headers }
      )
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetAssetConditionsData = (): any => {
  const { states } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{ dealer_code: string }, any>(() => {
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })
    headers.lender_id = states?.dealerAndLenderInfo?.lender_id
    return apiService.get<any>(`${EP_ASSET_CONDITIONS}`, {}, { headers })
  })
  return { data, isLoading, error, mutate }
}

export const useGetInventoryByFilters = (): any => {
  const {
    actions,
    states: {
      dealerAndLenderInfo: { dealer_code }
    }
  } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{ data: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      let params = body.data ? `${body.data}` : ''
      return apiService.get<any>(
        params
          ? `${EP_GET_VEHICLES_BY_FILTER}?${params}`
          : `${EP_GET_VEHICLES_BY_FILTER}?dealer_code=${dealer_code}&page_number=${0}&page_size=${100}`,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setAssetInventoryInitialData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetInventoryData = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{ dealer_code: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      let params = body.data ? `${body.data}` : ''
      return apiService.get<any>(
        params
          ? `${EP_GET_VEHICLES_INVENTORY_BY_DEALER_CODE}?${params}`
          : EP_GET_VEHICLES_BY_DEALER_CODE
      )
    }
  )
  return { data, isLoading, error, mutate }
}

export const useUpdateVehicleDetailByVin = (): any => {
  const { data, isLoading, mutate } = useMutation<{ vehicleDetail: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.patch<any>(`${EP_VEHICLE_DETAIL}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useUpdateVehicleDetailByVid = (): any => {
  const { data, isLoading, mutate } = useMutation<IVehicleDetail, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.patch<IVehicleDetail>(`${EP_UPDATE_VEHICLE_DETAIL}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateVehicleByVin = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number; vehicle: IVehicle }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.patch<any>(`${EP_VEHICLE}/${body.id}`, body.vehicle)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSendReferralInvite = (): any => {
  const { data, isLoading, mutate } = useMutation<
    {
      first_name: string
      last_name: string
      email: string
      vin: string
      dealer_code: string
    },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_REFERRAL_INVITE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetInventoryOptions = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>((body: any) => {
    let params = body.data ? `${body.data}` : ''
    body.dealer_options = 1
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })
    return apiService.get<any>(
      params ? `${EP_GET_INVENTORY_OPTIONS_DETAIL}?${params}` : EP_GET_INVENTORY_OPTIONS_DETAIL
    )
  })
  return { data, isLoading, error, mutate }
}

export const useGetInventoryByVin = (): any => {
  const { actions, states } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    (body: any) => {
      let vin = body.vin ? `${body.vin}` : ''
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(EP_VEHICLE_BY_VIN + `/${vin}`, {}, { headers })
    },
    {
      onSuccess(_response: any) {
        actions.setOfferInitialData({
          ...states?.orderData?.selected_vehicle,
          selected_vehicle: _response
        })
        // actions.setCalculationData({payload:reqBody,_response});
      }
    }
  )

  return { data, isLoading, error, mutate }
}

export const useGetVehicleIdUsingModelCode = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{ model_code: any }, any>((body: any) => {
    const apiService = Http.createConnection()
    return apiService.get<any>(EP_VEHICLE_BY_MODEL_CODE + `/${body.model_code}`, {}, { headers })
  })
  return { data, isLoading, error, mutate }
}

export const useOrderCalculationRequest = (): any => {
  const { actions } = useStoreContext()
  let reqBody: any = null
  const { data, isLoading, error, mutate, mutateAsync } = useMutation<IQuotationComparison, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      reqBody = body
      return apiService.post<IQuotationComparison>(`${EP_CALCULATE_ORDER_QUOTATION}`, body, {
        headers
      })
    },
    {
      onSuccess(_response: any) {
        actions.setCalculationData({ payload: reqBody, _response })
      }
    }
  )
  return { data, isLoading, error, mutate, mutateAsync }
}
