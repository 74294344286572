import { useTheme } from '@mui/material'
import { Box, Button, Dialog } from '@ntpkunity/controls'
import SignatureCanvas from 'react-signature-canvas'
import { FC, useEffect, useRef, useState } from 'react'
import { useStoreContext } from '@store/storeContext'
import { useSaveCustomerSignatures } from '@apis/customer-detail.service'
import DisableLoader from '@public/assets/images/loader-disabled.gif'

enum Title {
  SIGN_CONTRACT = 'Sign Contract',
  SIGN_YOUR_INITIALS = 'Sign Your Initials'
}

const ContractSignModal: FC<{
  openContractSignDialog: boolean
  setOpenContractSignDialog: any
  setGetLatestSignature: any
  setIsDocSign: any
  customerReferenceId: string
}> = ({
  openContractSignDialog,
  setOpenContractSignDialog,
  setGetLatestSignature,
  setIsDocSign,
  customerReferenceId
}) => {
  const { mutate: saveCustomerSignatures, isLoading: saveCustomerSignaturesLoading } =
    useSaveCustomerSignatures()
  const theme = useTheme()
  const { actions } = useStoreContext()
  let signatureCanvas = useRef<any>()
  const [signature, setSignature] = useState<any>()
  const [initial, setInitial] = useState<any>()
  const [title, setTitle] = useState<string>(Title.SIGN_CONTRACT)

  useEffect(() => {
    if (signature && initial) {
      saveCustomerSignatures(
        {
          signature_image: signature,
          initial_image: initial,
          reference_id: customerReferenceId
        },
        {
          onSuccess: () => {
            setGetLatestSignature(true)
            setOpenContractSignDialog(false)
            setIsDocSign(true)
          }
        }
      )
    }
  }, [signature, initial])

  const handleCloseContractSignModal = () => {
    setOpenContractSignDialog(false)
  }

  const handleClearSignature = () => {
    if (signatureCanvas && signatureCanvas.current) {
      signatureCanvas.current.clear()
    }
  }

  const handleConfirmSignature = () => {
    if (signatureCanvas && signatureCanvas.current) {
      if (!signatureCanvas.current.isEmpty()) {
        if (title === Title.SIGN_YOUR_INITIALS) {
          setInitial(signatureCanvas.current.toDataURL())
        } else {
          setSignature(signatureCanvas.current.toDataURL())
          setTitle(Title.SIGN_YOUR_INITIALS)
          signatureCanvas.current.clear()
        }
      } else {
        actions.setToast({
          toastMessage: `Please provide ${signature ? 'initials' : 'signatures'}`,
          toastState: true
        })
      }
    }
  }

  const handleBackButtonClick = () => {
    if (signatureCanvas && signatureCanvas.current) {
      setTitle(Title.SIGN_CONTRACT)
      signatureCanvas.current.clear()
      signatureCanvas.current.fromDataURL(signature)
    }
  }
  //''
  return (
    <>
      <Dialog
        theme={theme}
        size="md"
        variant={undefined}
        title={title}
        disablePortal
        open={openContractSignDialog}
        onCloseDialog={handleCloseContractSignModal}
        onBackBtn={title === 'Sign Contract' ? undefined : handleBackButtonClick}
        children={
          <>
            <SignatureCanvas
              canvasProps={{ width: 520, height: 150, className: 'sigCanvas' }}
              ref={signatureCanvas}
            />
            <Box theme={theme} sx={{ opacity: '0.4' }}>
              <hr />
            </Box>
            {/* <Typography variant="body2" component="p" sx={{ textAlign: 'center' }} theme={theme}>
              Alexander Jones
            </Typography> */}
          </>
        }
        customFooter={
          <>
            <Button theme={theme} secondary text="Clear" onClick={handleClearSignature} />
            <Button
              disabled={saveCustomerSignaturesLoading}
              theme={theme}
              primary
              text="Confirm"
              onClick={handleConfirmSignature}
              fullWidth
              endIcon={saveCustomerSignaturesLoading && <img src={DisableLoader} alt="Loader" />}
            />
          </>
        }
      />
    </>
  )
}

export default ContractSignModal
