export enum Status {
  Inquiry = 'Inquiry',
  AvailableInInventory = 'Available In Inventory',
  InContarct = 'In Contarct',
  Available = 'Available',
  Draft = 'Draft', //Orders
  Submitted = 'Submitted', //Orders
  Approved = 'Approved', //Orders
  Conditioned = 'Conditioned', //Orders
  InReview = 'In Review', //Orders
  Reviewed = 'Reviewed', //Orders
  ReSubmitted = 'Re Submitted', //Orders
  SchedulePickup = 'Awaiting Scheduling', //Schedule Pickup/delivery
  Appointment = 'Appointment Scheduled', //Appointment
  Cancelled = 'Cancelled', //Cancelled
  Converted = 'Converted', //Completed
  Completed = 'Completed', //Completed
  PendingConfirmation = 'Pending Confirmation'
}
