import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { TextBox } from '@components'
import { Employment_Type } from '@helpers/enums'
import { Select, Grid, Box, Typography, Button, Input, Icon } from '@ntpkunity/controls'

import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useSaveEmploymentDetails, useUpdateEmployInfo } from 'apis/customer-detail.service'
import { IAddress, IEmploymentDetails } from '@models'
import { getGoogleKey, isEmpty, validation } from '@helpers/methods'
import * as yup from 'yup'
import AddressDetailsComponent from './address-detail'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHasFocus } from '@hooks/useHasHook'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import { QueryKeys } from '@helpers/enums'

export const defaultValuesCustomer = {
  employment_type: '',
  name: '',
  phone: '',
  occupation: '',
  employed_since: '',
  annual_employment_income: 0,
  annual_other_income: 0,
  source_of_other_income: '',
  reference_id: ''
}

interface ILicenseDetailsComponentProps {
  setIsEmployment?: (value: boolean) => void
  setIsEmploymentErrors?: (value: boolean) => void
  setRoute: (value: number) => void
  employInfoData?: any
  lenderIntegrationsData: any
  customerReferenceId?: string
  setIsFocus?: (value: boolean) => void
}

function createValidationSchema() {
  const schema = yup.object().shape({
    employment_type: yup.string().nullable(),
    name: yup
      .string()
      .nullable()
      .required('Employer Name is required')
      .max(60, 'Employer Name maximum length is up to 60 characters'),
    phone: yup
      .string()
      .nullable()
      .required('Employer Phone Number is required')
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        'Valid Employer Phone Number is Required'
      ),
    occupation: yup
      .string()
      .nullable()
      .required('Occupation is required')
      .max(60, 'Occupation maximum length is up to 60 characters'),
    employed_since: yup
      .string()
      .nullable()
      .required('Employed Since Date is required')
      .matches(/^(0[1-9]|1[0-2])\d{4}$/, 'Employed Since Date should be in MM/YYYY format'),
    annual_employment_income: yup
      .number()
      .transform((originalValue) => {
        return isNaN(originalValue) ? undefined : Number(originalValue)
      })
      .nullable()
      .required('Annual Employment Income is required'),
    annual_other_income: yup
      .number()
      .transform((originalValue) => {
        return isNaN(originalValue) ? undefined : Number(originalValue)
      })
      .nullable()
      .required('Annual Other Income is required'),
    source_of_other_income: yup.string().when('annual_other_income', {
      is: (annualOtherIncome: number) => annualOtherIncome > 0,
      then: yup
        .string()
        .nullable()
        .required('Source of Other Income is required')
        .max(100, 'Source of Other Income maximum length is up to 100 characters'),
      otherwise: yup
        .string()
        .nullable()
        .max(100, 'Source of Other Income maximum length is up to 100 characters')
    }),
    address: yup.object().shape({
      address_line_1: yup.string().nullable().required('Address Line 1 is required'),
      city: yup.string().nullable().required('City is required'),
      state_name: yup.string().nullable().required('State Name is required'),
      state_id: yup.string().nullable().required('State Name is required'),
      zip_code: yup.string().nullable().required('Zip Code is required')
    })
  })

  return schema
}

interface IFormInputs {
  employment_type: string
  name?: string
  phone?: string
  occupation?: string
  employed_since?: string
  annual_employment_income?: number
  annual_other_income?: number | 0
  source_of_other_income?: string | 0
  address: IAddress | null
}

const EmploymentDetailsComponent: FC<ILicenseDetailsComponentProps> = ({
  setIsEmployment,
  setIsEmploymentErrors,
  setRoute,
  employInfoData,
  lenderIntegrationsData,
  customerReferenceId,
  setIsFocus
}) => {
  const theme = useTheme()
  const [errorDialog, setErrorDialog] = useState<any>({ visible: false, error: '' })
  const { mutate: addEmploymentData } = useSaveEmploymentDetails()
  const [months, setMonths] = useState(0)
  const [years, setYears] = useState(0)
  const [isAddressDirty, setIsAddressDirty] = useState(false)
  const { mutate: updateEmployInfoReferenceId } = useUpdateEmployInfo()
  const [googleApiKey, setGoogleApiKey] = useState(null)
  const [isVerified, setIsVerified] = useState(false)
  const [getMailValue] = useState('Employment')
  const validationSchema = createValidationSchema()
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  const form = useForm<IFormInputs>({
    defaultValues: employInfoData,
    resolver: yupResolver(validationSchema),
    mode: 'all'
  })

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors, isDirty }
  } = form

  useEffect(() => {
    const address = employInfoData?.address
    reset(employInfoData)
    setValue('employed_since', setMoveInDate(employInfoData?.employed_since))
    setValue('address', address ?? null)
    setValue('address.state_id', address?.state_name)
    setValue('address.address_type', getMailValue)
  }, [employInfoData])

  const focus = useHasFocus()

  useEffect(() => {
    setIsFocus?.(focus)
  }, [focus])

  useEffect(() => {
    return () => {
      if (employInfoData) {
        setIsEmploymentErrors?.(true)
        setIsEmployment?.(true)
      }
    }
  }, [employInfoData])

  useEffect(() => {
    if (getValues('address')?.verified) {
      setIsVerified(true)
    }
  }, [getValues('address')])

  useEffect(() => {
    setGoogleApiKey(getGoogleKey(lenderIntegrationsData))
  }, [lenderIntegrationsData])

  function handleEmployedSince(e: any) {
    const [inputMonth, inputYear] = e?.target?.value
      ?.toString()
      ?.split('/')
      ?.map((num: string) => parseInt(num))
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1

    if (inputMonth > 12 || inputMonth === 0 || inputYear === 0) {
      setErrorDialog({ visible: true, error: 'Please enter a valid Employed Since Date' })
      return
    }

    if (inputYear > currentYear || (inputYear >= currentYear && inputMonth > currentMonth)) {
      setErrorDialog({ visible: true, error: 'Employed Since Date can not be a future date' })
      return
    }
    setErrorDialog({ visible: false, error: '' })
    if (inputMonth > 0 && inputYear > 0) {
      calculateDateDifference(inputMonth, inputYear)
    }
  }

  function calculateDateDifference(inputMonth: number, inputYear: number): void {
    const currentDate = new Date()
    const months = currentDate.getMonth() + 1
    const yearDiff = currentDate.getFullYear() - inputYear
    const monthDiff = months - inputMonth

    if (monthDiff < 0) {
      setYears(yearDiff - 1)
      setMonths(12 + monthDiff)
    } else {
      setYears(yearDiff)
      setMonths(monthDiff)
    }
  }

  const getEmployedSinceDate = () => {
    let date = null
    const employedSince = getValues('employed_since')?.toString()
    if (employedSince && employedSince?.toString()?.length > 5) {
      const month = employedSince?.toString().slice(0, 2)
      const year = employedSince?.toString().slice(2, 6)
      date = `${year}-${month}-${'01'}`
      return date
    }
    return date
  }

  const setMoveInDate = (moveInDate: string) => {
    let monthYear = ''
    if (moveInDate?.length > 0) {
      monthYear = moveInDate?.toString()?.slice(5, 7) + moveInDate?.toString()?.slice(0, 4)
      if (moveInDate?.length > 6) {
        calculateDateDifference(
          parseInt(moveInDate?.toString()?.slice(5, 7)),
          parseInt(moveInDate?.toString()?.slice(0, 4))
        )
      }
    }
    return monthYear
  }

  const hasErrors = Object.keys(errors).length > 0
  if (hasErrors) {
    setIsEmployment?.(false)
    setIsEmploymentErrors?.(hasErrors)
  }

  const onSubmit: SubmitHandler<any> = (formData) => {
    let { employment_type, annual_other_income, source_of_other_income } = formData
    if (employment_type === Employment_Type.Employed) {
      annual_other_income = 0
      source_of_other_income = ''
    }
    const payload = {
      ...formData,
      reference_id: customerReferenceId,
      annual_other_income: parseInt(annual_other_income),
      source_of_other_income,
      annual_employment_income: parseInt(formData.annual_employment_income),
      employed_since: getEmployedSinceDate()
    }
    if (isEmpty(employInfoData)) {
      addEmploymentData(payload, {
        onSuccess: (__response: IEmploymentDetails) => {
          setIsEmployment?.(true)
          setIsEmploymentErrors?.(true)
          setRoute(3)
        },
        onError(__error: any) {}
      })
    } else if (isDirty || isAddressDirty) {
      updateEmployInfoReferenceId(payload, {
        onSuccess: (__response: IEmploymentDetails) => {
          setIsEmployment?.(true)
          setIsEmploymentErrors?.(true)
          setRoute(3)
        }
      })
    } else {
      setIsEmployment?.(true)
      setIsEmploymentErrors?.(true)
      setRoute(3)
    }
  }

  return (
    <>
      <Typography theme={theme} component="h3" variant="h3" sx={{ mb: 3 }}>
        Employment Details
      </Typography>
      <Grid theme={theme} container spacing={3} sx={{ mb: 4 }}>
        <Grid theme={theme} item xs={12} sm={6}>
          <Controller
            name={'employment_type'}
            defaultValue={''}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                label={'Employment Type'}
                items={Object.entries(Employment_Type).map((item) => ({
                  text: item[1],
                  value: item[1]
                }))}
                sxProps={''}
                value={value}
                selectError={errors?.employment_type?.message?.toString()}
                onChange={(e: any) => {
                  onChange(e)
                }}
              />
            )}
          />
        </Grid>
        {watch('employment_type') === Employment_Type.Employed && (
          <>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'name'}
                defaultValue={''}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder={'Type here..'}
                    label={'Employer Name'}
                    type="text"
                    {...field}
                    error={errors?.name?.message}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'phone'}
                defaultValue={''}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    maskNumeric
                    masking
                    format="### - ### - ####"
                    placeholder={'000 - 000 - 0000'}
                    label={'Employer Phone Number'}
                    type="text"
                    {...field}
                    error={(errors as any)?.phone?.message}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'occupation'}
                defaultValue={defaultValuesCustomer.occupation}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder={'Type here..'}
                    label={'Occupation'}
                    type="text"
                    {...field}
                    error={errors?.occupation?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'employed_since'}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Input
                    maskNumeric
                    masking
                    format="##/####"
                    theme={theme}
                    fullWidth
                    placeholder="MM/YYYY"
                    label="Employed Since"
                    {...field}
                    onBlur={(e) => {
                      field.onBlur()
                      handleEmployedSince(e)
                    }}
                    type={'text'}
                    helperText={
                      (errors?.employed_since?.message?.toString() ?? '').length <= 0 &&
                      errorDialog?.visible == false
                        ? `Duration: ${years.toString().padStart(2, '0')} year(s), ${months
                            .toString()
                            .padStart(2, '0')} month(s)`
                        : ''
                    }
                    error={
                      errorDialog?.visible == false
                        ? errors?.employed_since?.message?.toString()
                        : errorDialog?.error
                    }
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'annual_employment_income'}
                defaultValue={0}
                rules={validation('Annual Employment Income', true)}
                control={control}
                render={({ field }) => (
                  <TextBox
                    label={'Annual Employment Income'}
                    type="text"
                    numeric={true}
                    startAdornment={defaultCurrency}
                    masking={true}
                    scale={2}
                    {...field}
                    error={errors?.annual_employment_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'annual_other_income'}
                defaultValue={0}
                control={control}
                render={({ field }) => (
                  <TextBox
                    label={'Annual Other Income'}
                    type="text"
                    numeric={true}
                    masking={true}
                    startAdornment={defaultCurrency}
                    scale={2}
                    {...field}
                    error={errors?.annual_other_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'source_of_other_income'}
                defaultValue={''}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder={'Type here..'}
                    label={'Source of Other Income'}
                    type="text"
                    {...field}
                    error={errors?.source_of_other_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
          </>
        )}
        {watch('employment_type') !== Employment_Type.Employed && (
          <>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'annual_other_income'}
                defaultValue={0}
                control={control}
                render={({ field }) => (
                  <TextBox
                    label={'Annual Other Income'}
                    type="text"
                    numeric={true}
                    masking={true}
                    startAdornment={defaultCurrency}
                    scale={2}
                    {...field}
                    error={errors?.annual_other_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'source_of_other_income'}
                defaultValue={''}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder={'Type here..'}
                    label={'Source of Other Income'}
                    type="text"
                    {...field}
                    error={errors?.source_of_other_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>
      <Typography theme={theme} component="h4" variant="h4" className="title-icon">
        <span>Employment Address</span>{' '}
        {isVerified ? (
          <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
            <Icon name="GreenCheckIcon" />
          </Box>
        ) : null}
      </Typography>
      <Box theme={theme} sx={{ mb: 4 }}>
        <AddressDetailsComponent
          form={form}
          googleApiKey={googleApiKey}
          addressValue={'address'}
          setIsAddressDirty={setIsAddressDirty}
          moveIn={false}
          errorProps={errors.address}
        />
      </Box>
      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>
      <Box theme={theme} textAlign="right" sx={{ mb: 5 }}>
        <Button
          type="submit"
          theme={theme}
          primary
          onClick={errorDialog?.visible == false ? handleSubmit(onSubmit) : () => {}}
          text="Save Employment Details"
        />
      </Box>
    </>
  )
}
export default EmploymentDetailsComponent
