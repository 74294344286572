import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { ConfirmationDialog, PageHeader } from 'components'
import { Typography, Button, Grid, Box, Menu, Icon } from '@ntpkunity/controls'
import {
  AssetDetails,
  Contracts,
  CustomerDetails,
  InsuranceDetails,
  OrderDetails,
  PaymentBreakdown,
  PaymentDetails,
  TradeInDetails
} from '@app/order-management/order-summary'
import { useGetOrderInformation, useUpdatePaymentStatus } from '@apis/order-management.service'
import { useNavigate, useParams } from 'react-router-dom'
import { DialogMessages, Reasons, Status } from '@helpers/enums'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { APP_ROUTES } from '@helpers/links'

const OrderApproved: FC = () => {
  let navigate = useNavigate()
  const theme = useTheme()
  const { mutate: getOrderInformation, data: orderDetail } = useGetOrderInformation()
  const [optionObject, setOptionObject] = useState<any>(orderDetail)
  const { reference_id } = useParams()
  const [paymentIndicator, setPaymentIndicator] = useState<boolean>(false)
  const [trigger, setTrigger] = useState<boolean>(true)
  const [allDocuments, setAllDocuments] = useState<any>()
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const { mutate: updatePaymentStatus } = useUpdatePaymentStatus(reference_id as any)
  const { mutate: updateOrderStatus } = useUpdateOrderStatus()
  const { actions } = useStoreContext()

  useEffect(() => {
    // const documents = [];
    // documents.push({
    //   identifier: 1,
    //   document_name: 'asd',
    //   document_signed_url: '',
    // });
    optionObject?.documents && setAllDocuments(optionObject?.documents)
    setPaymentIndicator(optionObject?.payment_recieved)
    //documents && setAllDocuments(documents);
  }, [optionObject])
  useEffect(() => {
    if (reference_id) {
      getOrderInformation(
        { reference_number: reference_id },
        {
          onSuccess(response: any) {
            if (response?.status === 'Approved') setOptionObject(response)
          }
        }
      )
    }
  }, [reference_id])

  let areAllDocumentsVerified =
    allDocuments?.length > 0
      ? allDocuments.every((doc: { is_verified: any }) => doc.is_verified)
      : false

  const onDeleteConfirm = () => {
    const data = {
      order_id: orderDetail?.reference_number,
      reqBody: {
        status: Status.Cancelled,
        user_id: 0,
        reason: Reasons.CANCELED_BY_DEALER
      }
    }
    updateOrderStatus(data, {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Order Cancelled',
          toastState: true
        })
        navigate(APP_ROUTES?.WORKQUEUE)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }
  return (
    <>
      <LayoutWithSideNav theme={theme}>
        <Box theme={theme} sx={{ mb: 2.5 }}>
          <PageHeader
            className="main-page-header border-bottom"
            theme={theme}
            container
            item
            spacing={2}
          >
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
                <Typography theme={theme} variant="h2" component="h2">
                  Order Approved
                </Typography>
                <Typography theme={theme} variant="body2" component="p">
                  The credit application has been approved. Once you receive the payment, please
                  update the payment status below to proceed.
                </Typography>
              </Grid>
              <Grid
                theme={theme}
                item
                lg={5}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  gap: 2
                }}
              >
                {(paymentIndicator && areAllDocumentsVerified) ||
                (paymentIndicator && !areAllDocumentsVerified && trigger) ? (
                  <Button
                    theme={theme}
                    secondary
                    id="ScheduleOrder"
                    text={paymentIndicator && areAllDocumentsVerified ? 'Schedule' : 'Save Order'}
                    onClick={() => {
                      if (paymentIndicator && areAllDocumentsVerified) {
                        updatePaymentStatus(
                          {
                            payment_status: paymentIndicator,
                            order_status: Status.SchedulePickup
                          },
                          {
                            onSuccess(_response: any) {
                              navigate(
                                `/dms/create-order-ui/${optionObject?.Vehicle_details?.vin}/${optionObject?.customer_info?.reference_id}/${optionObject?.reference_number}`
                              )
                            }
                          }
                        )
                      } else if (paymentIndicator && !areAllDocumentsVerified) {
                        updatePaymentStatus(
                          {
                            payment_status: paymentIndicator
                          },
                          {
                            onSuccess(_response: any) {
                              getOrderInformation(
                                { reference_number: reference_id },
                                {
                                  onSuccess(_response: any) {
                                    setOptionObject(orderDetail)
                                    setTrigger(false)
                                  }
                                }
                              )
                            }
                          }
                        )
                      }
                    }}
                  />
                ) : null}
                <Menu
                  theme={theme}
                  options={[
                    { optionText: 'Cancel Order', optionValue: 'Cancel' },
                    { optionText: 'Resend Email Invite', disabled: true },
                    { optionText: 'Send Order Link', disabled: true }
                  ]}
                  handleOptionClick={(_event, _key, value) => {
                    switch (value) {
                      case 'Cancel':
                        setConfirmationPopupState(true)
                        break
                    }
                  }}
                  render={(cb: any) => (
                    <Button
                      theme={theme}
                      secondary
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    />
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </Grid>
            </Grid>
          </PageHeader>
        </Box>
        <AssetDetails order_info={optionObject} />
        <Grid theme={theme} className="container" container spacing={2}>
          <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
            {allDocuments?.length > 0 ? (
              <Contracts
                documents={allDocuments as any}
                setAllDocuments={setAllDocuments}
                customerReferenceId={optionObject?.customer_info?.reference_id}
                orderStatus={orderDetail?.status}
              />
            ) : (
              ''
            )}

            <PaymentDetails
              paymentIndicator={optionObject?.payment_recieved}
              orderStatus={optionObject?.status}
              setTrigger={setTrigger}
              setPaymentIndicator={setPaymentIndicator}
            />

            <CustomerDetails customer_info={optionObject?.customer_info} />

            <OrderDetails order_detail={optionObject} />

            {optionObject?.Insurance_Inforation ? (
              <InsuranceDetails insurance={optionObject?.Insurance_Inforation} />
            ) : (
              ''
            )}

            {optionObject?.order_tradein ? (
              <TradeInDetails order_tradein={optionObject?.order_tradein} />
            ) : (
              ''
            )}
          </Grid>
          <PaymentBreakdown order_info={optionObject} />
        </Grid>
      </LayoutWithSideNav>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.cancelBtnText}
          isButtonTypeDanger
          icon={<Icon name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          hideCancelButton={false}
        />
      )}
    </>
  )
}

export default OrderApproved
