import { styled } from '@mui/material'
import { CustomColors, lightTheme } from '@styles/theme'

export const PackageWrap = styled('div')(({ theme }) => ({
  '&.package-wrap': {
    width: '100%',
    paddingBottom: 24,
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'thin',
    display: 'flex',

    '&::-webkit-scrollbar': {
      width: 7,
      height: 7,
      borderRadius: 7
    },
    '&::-webkit-scrollbar-track': {
      padding: '0 1px',
      backgroundColor: '#f0f0f0',
      borderRadius: 7
    },
    '&::-webkit-scrollbar-thumb': {
      transition: 'all 0.3s',
      backgroundColor: '#cdcdcd',
      borderRadius: 7
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a6a6a6'
    },

    '.package-container': {
      flex: 1,
      minWidth: 416,
      paddingRight: 24,

      '&:last-child': {
        paddingRight: 0
      },

      '.text-muted': {
        color: theme.palette.grey[500]
      },

      '.package-cell': {
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        display: 'inline-block',
        width: '100%',

        '.product-header': {
          padding: '15px 24px',
          textAlign: 'center',

          '.thumbnail': {
            width: '100%',
            height: 120,
            display: 'flex',
            verticalAlign: 'middle',
            textAlign: 'center',
            marginBottom: 16,

            img: {
              maxWidth: '100%',
              maxHeight: 120,
              width: 'auto',
              height: 'auto',
              display: 'block',
              margin: 'auto'
            }
          },

          '.order-id': {
            color: lightTheme.palette.primary.main
          }
        },

        '&.selected': {
          borderColor: theme.palette.primary.main
        },

        '.package-body': {
          display: 'inline-block',
          width: '100%',
          padding: 12
        },

        '.package-row': {
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.grey[900],
          fontSize: theme.typography.subtitle1.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          display: 'inline-block',
          width: '100%',
          clear: 'both',
          padding: '0 12px',
          height: 48,
          lineHeight: '48px',
          verticalAlign: 'middle',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          textAlign: 'right',
          borderRadius: 8,
          transition: 'all 0.3s',

          '&.h-default': {
            height: 'auto'
          },

          b: {
            fontWeight: theme.customVariables.fontWeightSemiBold
          },

          small: {
            fontSize: theme.typography.caption.fontSize
          },

          hr: {
            opacity: 0.35,
            margin: '24px 0 0 0'
          },

          '.u-form-group .MuiFormControl-root .u-form-control': {
            '.end-adornment': {
              marginLeft: 5
            },
            '.start-adornment': {
              marginRight: 5
            },
            '.MuiInputBase-input': {
              maskImage: 'none'
            }
          },
          '&:hover': {
            backgroundColor: theme.customVariables.tableStripedBg
          },

          '&.blank': {
            '&:hover': {
              backgroundColor: 'transparent'
            }
          },

          '&.toggle': {
            'span, .toggle-btn': {
              float: 'left'
            },
            span: {
              marginRight: 5
            },
            '.toggle-btn': {
              cursor: 'pointer',
              marginTop: 12
            },
            '.ch-up': {
              webkitTransform: 'scaleY(-1)',
              transform: 'scaleY(-1)'
            }
          },

          '&.header': {
            fontSize: theme.typography.h4.fontSize,
            fontWeight: theme.typography.fontWeightBold,
            borderBottom: '1px solid' + theme.palette.divider,
            padding: '15px 24px',
            textAlign: 'left',
            lineHeight: 'normal',
            height: 'auto',
            minHeight: '81px',
            borderRadius: 0,

            span: {
              float: 'left',
              margin: '13px 8px 0 0'
            },

            Button: {
              float: 'right'
            },

            '.btn-back': {
              paddingLeft: 0
            },

            a: {
              color: theme.palette.grey[900]
            },

            '&:hover': {
              backgroundColor: 'transparent'
            },

            '&.header-top': {
              minHeight: 230
            }
          },

          '&.editable': {
            '.btn': {
              display: 'inline-block',
              padding: 0,

              '&.btn-edit': {
                marginLeft: 5,
                svg: {
                  height: 20,
                  width: 'auto'
                }
              }
            },
            '.symbol': {
              margin: '0 2px 0 0'
            },
            '.editable-content': {
              display: 'flex',
              float: 'right',
              height: 50,
              '.caption': {
                marginRight: 5
              },
              '.u-form-group': {
                width: 130,
                float: 'right',
                margin: '7px 5px 0 0',
                '.MuiFormControl-root': {
                  '.u-form-control': {
                    height: 35,
                    '.MuiOutlinedInput-input': {
                      padding: '0 5px 0 0'
                    },
                    // ".MuiOutlinedInput-notchedOutline": {
                    //   border: "0 solid",
                    //   borderColor: "transparent",
                    // },
                    '&.Mui-focused': {
                      fontWeight: theme.typography.fontWeightBold
                    }
                  }
                }
              }
            },
            '&.right-align': {
              '.symbol': {
                margin: '0 0 0 2px'
              },
              '.u-form-group': {
                width: 115,
                '.MuiFormControl-root .u-form-control .MuiOutlinedInput-input': {
                  textAlign: 'right',
                  padding: '0 0 0 5px'
                }
              }
            }
          },
          '.alert': {
            display: 'flex',
            justifyContent: 'right',
            '.styled-tooltip': {
              display: 'inline-block',
              height: 24,
              padding: 5,
              position: 'relative',
              top: 5,
              lineHeight: 'initial',
              '.MuiTooltip-popper': {
                width: 290
              },
              div: {
                display: 'inline-block',
                wordWrap: 'break-word',
                whiteSpace: 'initial',
                textAlign: 'left'
              }
            },
            '&.alert-error': {
              color: theme.palette.error.main,
              '.styled-tooltip': {
                svg: {
                  path: {
                    stroke: theme.palette.error.main
                  }
                }
              }
            }
          }
        },

        '.grid-wrap': {
          '.grid': {
            display: 'flex',
            justifyContent: 'space-between',
            '.col': {
              flex: 1,
              maxWidth: '100%',
              minWidth: '50%'
            }
          },
          '.title-bg': {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h4.fontSize,
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: CustomColors.lightGrey,
            borderRadius: theme.shape.borderRadius,
            padding: '14px 16px',
            margin: 15
          },

          '.package-body': {
            borderTop: '1px solid' + theme.palette.divider
          }
        }
      },

      '&.fixed-container': {
        backgroundColor: theme.palette.common.white,
        position: 'sticky',
        left: 0,
        zIndex: 1000,

        '.package-cell': {
          border: '0 solid',
          borderColor: 'transparent',

          '.package-body': {
            padding: '12px 0'
          },

          '.package-row': {
            paddingLeft: 0,
            textAlign: 'left',
            borderRadius: 0,

            '&.header': {
              padding: '29px 0 29px 0'
            },

            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        }
      },

      '.MuiAccordion-root': {
        backgroundColor: 'transparent',
        boxShadow: '0 0 0 transparent',
        borderRadius: 0,
        margin: 0,

        '&:before, .MuiAccordionSummary-root': {
          display: 'none'
        },

        '.MuiAccordionDetails-root': {
          padding: 0,
          '.package-row': {
            color: theme.palette.grey[600]
          }
        }
      }
    }
  }
}))
