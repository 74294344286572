import { useReducer } from 'react'
import themeReducer, { initialState as themeInitialState, updateThemeConfig } from './ducks/theme'
import storageReducer, {
  initialState as storageInitialState,
  updateLocalStorage,
  updateSessionStorage
} from './ducks/browserStorage'
import { StoreContextType } from './configs'
import {
  AddOns,
  IDealerFee,
  IFinancialInsurance,
  IInventory,
  IPaginatedResponse,
  IPaginatedResponse_AddOns,
  IToast,
  IOffer,
  IOrderWorkQueue,
  IInsuranceInformation,
  ICustomerLicense,
  ICustomer,
  IOdometer,
  IContractTerms,
  IStipStoreState
} from '@models'
import inventoryReducer, {
  initialState as inventoryInitialState,
  updateInventoryData,
  updateInventoryDataRow
} from './ducks/inventory'
import ToastReducer, { initialState as toastInitialState, setToast } from './ducks/Toast'

import addonReducer, {
  addAddonDataRow,
  deleteAddonDataRow,
  initialState as AddonInitialState,
  updateAddonData,
  updateAddonDataRow
} from './ducks/add-ons'
import dealerFeeReducer, {
  deleteDealerFeeDataRow,
  initialState as dealerFeeInitialState,
  updateDealerFeeData,
  updateDealerFeeDataRow,
  AddDealerFeeDataRow
} from './ducks/dealer-fee'
import financialInsuranceReducer, {
  setFinancialInsuranceProductData,
  setFinancialInsuranceInitialData,
  initialState as FIProductInitialState,
  AddFinancialInsuranceDataRow,
  updateFinancialInsuranceDataRow,
  deleteFinancialInsuranceDataRow
} from './ducks/financial-insurance'
import customerReducer, {
  initialState as customerInitialState,
  setCustomerData,
  setCustomerInformation
} from './ducks/customer'
import dealerPreferenceReducer, {
  initialState as dealerPreferenceInitialState,
  setDealerPreferenceData
} from './ducks/dealer-preference'
import dealerProfileReducer, {
  initialState as dealerProfileInitialState,
  setDealerProfileData,
  setDealerAndLenderInfo,
  setLenderInfo
} from './ducks/dealer-profile'
import calculationReducer, {
  initialState as calculationInitialState,
  setCalculationData
} from './ducks/calculation'
import OrderReducer, { setOfferInitialData, initialState as orderInitialState } from './ducks/order'
import StipulationReducer, {
  setStipulationsInitialData,
  initialState as stipInitialState
} from './ducks/stipulations'
import TradeInReducer, {
  initialState as tradeInInitialState,
  setTradeInEquityData
} from './ducks/trade-in-equity'
import selectedOrderOptionsReducer, {
  initialState as selectedOptionsInitialState,
  setSelectedOrderOptions
} from './ducks/selected-order-options'
import vehicleReducer, {
  initialState as vehicleInitalState,
  setDealerVehicleAvailableConfirmationOrders,
  setDealerGetReadyCheckList,
  setDealerSchedulePickups,
  setDealerReturnVehicle,
  setDealerCompletedOrders,
  setDealerCancelledOrders,
  setDealerSelectedOrderData,
  setInsuranceInformation,
  setCustomerLicenseInformation,
  setOdometerData,
  setDealerSelectedReturnData,
  setSelectedANDealer
} from './ducks/dealer-work-queue'
import contractTermsRreducer, {
  initialState as contractTermsInitialState,
  setContractTermsData
} from './ducks/contract-terms'
import creditRatingRreducer, {
  initialState as creditRatingInitialState,
  setCreditRatingData
} from './ducks/credit-rating'
import userProfileRreducer, {
  initialState as userProfileInitialState,
  setUserProfile
} from './ducks/user-profile'

import quotationDefaultParamsReducer, {
  initialState as quotationDefaultParamInitialState,
  setQuotationDefaultParam
} from './ducks/quotation-parameters'

const useReducerActions = (): StoreContextType => {
  const [userProfile, userProfileDispatch] = useReducer(
    userProfileRreducer,
    userProfileInitialState
  )

  const [theme, themeDispatch] = useReducer(themeReducer, themeInitialState)
  const [browserStorage, stroageDispatch] = useReducer(storageReducer, storageInitialState)
  const [inventoryData, inventoryDispatch] = useReducer(inventoryReducer, inventoryInitialState)

  const [vehicleConfirmationData, vehicleConfirmationDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [licenseInformationData, customerLicenseInformationDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [insuranceInformationData, insuranceInformationDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [vehicleGetReadyCheckListData, vehicleGetReadyCheckListDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [vehicleSchedulePickupsData, dealerSchedulePickupDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [dealerVehicleReturnData, dealerVehicleReturnDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [dealersCompletedOrdersData, dealerCompletedOrdersDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [dealersCancelledOrdersData, dealerCancelledOrdersDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [dealersSelectedOrderData, dealerSelectedOrderDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [dealerSelectedReturnData, dealerSelectedReturnDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )

  const [selectedOrderOptions, selectedOrderOptionsDispatch] = useReducer(
    selectedOrderOptionsReducer,
    selectedOptionsInitialState
  )

  const [selectedANDealer, selectedANDealerDispatch] = useReducer(
    vehicleReducer,
    vehicleInitalState
  )
  const [customerData, customerDispatch] = useReducer(customerReducer, customerInitialState)

  const [customerInformation, customerInformationByRefDispatch] = useReducer(
    customerReducer,
    customerInitialState
  )

  const [odometerData, odometerDataDispatch] = useReducer(vehicleReducer, customerInitialState)

  const [dealerPreferenceData, dealerPreferenceDataDispatch] = useReducer(
    dealerPreferenceReducer,
    dealerPreferenceInitialState
  )

  const [dealerAndLenderInfo, dealerAndLenderInfoDispatch] = useReducer(
    dealerProfileReducer,
    dealerProfileInitialState
  )
  const [lenderInfo, lenderInfoDispatch] = useReducer(
    dealerProfileReducer,
    dealerProfileInitialState
  )

  const [dealerProfileData, dealerProfileDataDispatch] = useReducer(
    dealerProfileReducer,
    dealerProfileInitialState
  )

  const [tradeInEquityData, tradeInEquityDataDispatch] = useReducer(
    TradeInReducer,
    tradeInInitialState
  )

  const [calculationData, calculationDispatch] = useReducer(
    calculationReducer,
    calculationInitialState
  )

  const [toastData, toastDispatch] = useReducer(ToastReducer, toastInitialState)
  const [addonData, addonsDispatch] = useReducer(addonReducer, AddonInitialState)
  const [dealerFeeData, dealerFeeDispatch] = useReducer(dealerFeeReducer, dealerFeeInitialState)
  const [financialInsuranceProductData, financialInsuranceDispatch] = useReducer(
    financialInsuranceReducer,
    FIProductInitialState
  )
  const [financialInsuranceInitialData, financialInsuranceInitialDataDispatch] = useReducer(
    financialInsuranceReducer,
    FIProductInitialState
  )
  const [orderData, orderDataDispatch] = useReducer(OrderReducer, orderInitialState)
  const [stipulationsData, stipulationsDataDispatch] = useReducer(
    StipulationReducer,
    stipInitialState
  )

  const [contractTermsData, contractTermsDispatch] = useReducer(
    contractTermsRreducer,
    contractTermsInitialState
  )
  const [creditRatingData, creditRatingDispatch] = useReducer(
    creditRatingRreducer,
    creditRatingInitialState
  )

  const [quotationDefaultParam, quotationDefaultParamDispatch] = useReducer(
    quotationDefaultParamsReducer,
    quotationDefaultParamInitialState
  )
  const actions = {
    setTheme: (data: 'default' | 'dark') => {
      updateThemeConfig(data, themeDispatch)
    },
    setLocalStorage: (data: string) => updateLocalStorage(data, stroageDispatch),
    setSessionStorage: (data: string) => updateSessionStorage(data, stroageDispatch),
    setAssetInventoryInitialData: (data: IPaginatedResponse) =>
      updateInventoryData(data, inventoryDispatch),
    updateInventoryRow: (data: IInventory) => updateInventoryDataRow(data, inventoryDispatch),
    setToast: (data: IToast) => setToast(data, toastDispatch),
    setAddonInitialData: (data: IPaginatedResponse_AddOns) => updateAddonData(data, addonsDispatch),
    updateAddonRow: (data: AddOns) => updateAddonDataRow(data, addonsDispatch),
    deleteAddonRow: (data: AddOns) => deleteAddonDataRow(data, addonsDispatch),
    addAddonRow: (data: AddOns) => addAddonDataRow(data, addonsDispatch),
    setDealerFeeInitialData: (data: IDealerFee[]) => updateDealerFeeData(data, dealerFeeDispatch),
    deleteDealerFeeRow: (data: IDealerFee) => deleteDealerFeeDataRow(data, dealerFeeDispatch),
    updateDealerFeeRow: (data: IDealerFee) => updateDealerFeeDataRow(data, dealerFeeDispatch),
    addDealerFeeRow: (data: IDealerFee) => AddDealerFeeDataRow(data, dealerFeeDispatch),
    setFinancialInsuranceProductData: (data: any) =>
      setFinancialInsuranceProductData(data, financialInsuranceDispatch),
    setFinancialInsuranceInitialData: (data: any) =>
      setFinancialInsuranceInitialData(data, financialInsuranceInitialDataDispatch),
    addFinancialInsuranceRow: (data: IFinancialInsurance) =>
      AddFinancialInsuranceDataRow(data, financialInsuranceInitialDataDispatch),
    updateFinancialInsuranceRow: (data: IFinancialInsurance) =>
      updateFinancialInsuranceDataRow(data, financialInsuranceInitialDataDispatch),
    deleteFinancialInsuranceRow: (data: IFinancialInsurance) =>
      deleteFinancialInsuranceDataRow(data, financialInsuranceInitialDataDispatch),
    setOfferInitialData: (data: IOffer) => setOfferInitialData(data, orderDataDispatch),
    setStipulationsInitialData: (data: IStipStoreState) =>
      setStipulationsInitialData(data, stipulationsDataDispatch),
    setCustomerData: (data: any) => setCustomerData(data, customerDispatch),
    setCalculationData: (data: any) => setCalculationData(data, calculationDispatch),
    setDealerPreferenceData: (data: any) =>
      setDealerPreferenceData(data, dealerPreferenceDataDispatch),
    setDealerProfileData: (data: any) => setDealerProfileData(data, dealerProfileDataDispatch),
    setDealerAndLenderInfo: (data: any) =>
      setDealerAndLenderInfo(data, dealerAndLenderInfoDispatch),
    setLenderInfo: (data: any) => setLenderInfo(data, lenderInfoDispatch),
    setTradeInEquityData: (data: any) => setTradeInEquityData(data, tradeInEquityDataDispatch),
    setSelectedOrderOptions: (data: any) =>
      setSelectedOrderOptions(data, selectedOrderOptionsDispatch),
    setDealerVehicleAvailableConfirmationOrders: (data: Array<IOrderWorkQueue>) =>
      setDealerVehicleAvailableConfirmationOrders(data, vehicleConfirmationDispatch),
    setDealerGetReadyCheckList: (data: Array<IOrderWorkQueue>) =>
      setDealerGetReadyCheckList(data, vehicleGetReadyCheckListDispatch),
    setDealerSchedulePickups: (data: Array<IOrderWorkQueue>) =>
      setDealerSchedulePickups(data, dealerSchedulePickupDispatch),
    setDealerReturnVehicle: (data: Array<IOrderWorkQueue>) =>
      setDealerReturnVehicle(data, dealerVehicleReturnDispatch),
    setDealerCompletedOrders: (data: Array<IOrderWorkQueue>) =>
      setDealerCompletedOrders(data, dealerCompletedOrdersDispatch),
    setDealerCancelledOrders: (data: Array<IOrderWorkQueue>) =>
      setDealerCancelledOrders(data, dealerCancelledOrdersDispatch),
    setDealerSelectedOrderData: (data: IOrderWorkQueue) =>
      setDealerSelectedOrderData(data, dealerSelectedOrderDispatch),
    setInsuranceInformation: (data: IInsuranceInformation) =>
      setInsuranceInformation(data, insuranceInformationDispatch),
    setCustomerLicenseInformation: (data: ICustomerLicense) =>
      setCustomerLicenseInformation(data, customerLicenseInformationDispatch),
    setCustomerInformation: (data: ICustomer) =>
      setCustomerInformation(data, customerInformationByRefDispatch),
    setOdometerData: (data: IOdometer) => setOdometerData(data, odometerDataDispatch),
    setDealerSelectedReturnData: (data: any) =>
      setDealerSelectedReturnData(data, dealerSelectedReturnDispatch),
    setContractTermsData: (data: IContractTerms[]) =>
      setContractTermsData(data, contractTermsDispatch),
    setCreditRatingData: (data: any[]) => setCreditRatingData(data, creditRatingDispatch),
    setUserProfile: (data: any) => {
      setUserProfile(data, userProfileDispatch)
    },
    setSelectedANDealer: (data: any) => setSelectedANDealer(data, selectedANDealerDispatch),
    setQuotationDefaultParam: (data: any) =>
      setQuotationDefaultParam(data, quotationDefaultParamDispatch)
  }
  return {
    actions,
    states: {
      theme,
      localStorage: browserStorage.localStorage,
      sessionStorage: browserStorage.sessionStorage,
      inventoryData: inventoryData,
      dealerAndLenderInfo: dealerAndLenderInfo,
      lenderInfo: lenderInfo,
      vehicleAvailableConfirmationOrders: vehicleConfirmationData,
      vehicleGetReadyCheckListOrders: vehicleGetReadyCheckListData,
      vehicleSchedulePickupsData: vehicleSchedulePickupsData,
      vehicleCompletedOrdersData: dealersCompletedOrdersData,
      vehicleCancelledOrdersData: dealersCancelledOrdersData,
      dealerSelectedOrderData: dealersSelectedOrderData,
      dealerSelectedReturnData: dealerSelectedReturnData,
      insuranceInformation: insuranceInformationData,
      licenseInformation: licenseInformationData,
      vehicleReturnData: dealerVehicleReturnData,
      selectedANDealer: selectedANDealer,
      odometerData: odometerData,
      customerData: customerData,
      customerInformation: customerInformation,
      dealerPreferenceData: dealerPreferenceData,
      calculationData: calculationData,
      selectedOrderOptions: selectedOrderOptions,
      dealerProfileData: dealerProfileData,
      tradeInEquityData: tradeInEquityData,
      toastData: toastData,
      addonData: addonData,
      dealerFeeData: dealerFeeData,
      financialInsuranceProductData: financialInsuranceProductData,
      financialInsuranceInitialData: financialInsuranceInitialData,
      orderData: orderData,
      contractTermsData: contractTermsData,
      creditRatingData: creditRatingData,
      userProfile: userProfile,
      stipulationsData: stipulationsData,
      quotationDefaultParams: quotationDefaultParam
    }
  }
}

export default useReducerActions
