import React, { FC, memo, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Dialog, Icon, Button, Grid, Box, Typography } from '@ntpkunity/controls'
import { PriceList, ConfirmationDialog, Divider } from 'components'
import { TradeInPopup } from '../popup-screens'
import { useForm } from 'react-hook-form'
import { DialogMessages } from '@helpers/enums'

const OrderTradeIn: FC<{
  selectedTradeIn: any
  isDisable?: boolean
  tradeInRequestData: any
  defaultCurrency?: string
}> = ({ selectedTradeIn, isDisable, tradeInRequestData, defaultCurrency }) => {
  const theme = useTheme()
  const [openTradeDialog, setOpenTradeDialog] = React.useState(false)
  const [confirmationPopupState, setConfirmationPopupState] = React.useState<boolean>(false)
  const form: {
    handleSubmit: any
    reset: any
    getValues: any
    formState: any
    trigger: any
    clearErrors: any
  } = useForm()
  const payoff_amount = tradeInRequestData?.payoff_amount
    ? parseFloat(tradeInRequestData?.payoff_amount)
    : 0
  const trade_in_amount = tradeInRequestData?.trade_in_amount
    ? parseFloat(tradeInRequestData?.trade_in_amount)
    : 0
  let tradeInEquity: any = trade_in_amount - payoff_amount
  if (tradeInEquity && tradeInEquity > 0) {
    tradeInEquity =
      defaultCurrency +
      Math.abs(tradeInEquity).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
  } else if (tradeInEquity < 0) {
    tradeInEquity =
      `-${defaultCurrency}` +
      Math.abs(tradeInEquity).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
  }
  const handleCloseTradeDialog = () => {
    setOpenTradeDialog(false)
  }

  useEffect(() => {
    tradeInRequestData && form.reset(tradeInRequestData)
  }, [tradeInRequestData])
  const saveTradeIn = () => {
    selectedTradeIn({
      ...form.getValues(),
      trade_in_amount: +form.getValues('trade_in_amount'),
      payoff_amount: +form.getValues('payoff_amount'),
      asset_image: form.getValues('asset_image')?.length > 0 ? form.getValues('asset_image') : null
    })
    handleCloseTradeDialog()
  }
  const removeFormData = () => {
    form.reset({
      condition: undefined,
      lender_contract_number: undefined,
      asset_image: undefined,
      is_customer_titled: undefined,
      lender_address: undefined,
      lender_name: undefined,
      lender_contact_number: undefined,
      loan_lease_balance: undefined,
      make: undefined,
      mileage: undefined,
      model: undefined,
      payment_type: undefined,
      payoff_amount: undefined,
      trade_in_amount: undefined,
      trim: undefined,
      vin: undefined,
      year: undefined
    })
    selectedTradeIn(null)
    setConfirmationPopupState(false)
  }
  const handleClickOpenTrade = () => {
    form.reset()
    selectedTradeIn({})
    setOpenTradeDialog(true)
  }
  return (
    <>
      <Typography theme={theme} variant="h4" component="h4" sx={{ mb: 2.5 }}>
        Trade-In
      </Typography>
      {tradeInRequestData && Object.keys(tradeInRequestData)?.length > 0 ? (
        <>
          <PriceList className="price-list border-box" sx={{ mb: 2 }}>
            <Box theme={theme} sx={{ mb: 1 }}>
              <b>
                {tradeInRequestData?.make} {tradeInRequestData?.model} {tradeInRequestData?.year}{' '}
              </b>
            </Box>
            {tradeInRequestData?.asset_image && tradeInRequestData?.asset_image.length > 0 && (
              <Box theme={theme}>
                <img
                  style={{ maxWidth: '100%', marginBottom: '0.5rem' }}
                  src={tradeInRequestData?.asset_image[0] ? tradeInRequestData?.asset_image[0] : ''}
                  alt=""
                />
              </Box>
            )}
            <Box theme={theme} sx={{ mb: 3 }}>
              <b className="total">{tradeInEquity ? tradeInEquity : ' '}</b>
            </Box>
            <Box theme={theme}>
              <small>Our Offer:</small>{' '}
              <span>
                {trade_in_amount
                  ? defaultCurrency +
                    trade_in_amount.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  : '-'}
              </span>
            </Box>
            <Box theme={theme}>
              <small>Payoff Amount:</small>{' '}
              <span>
                {payoff_amount
                  ? defaultCurrency +
                    payoff_amount.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  : '-'}
              </span>
            </Box>
            <Box theme={theme}>
              <small>Trade-In Equity/Inequity:</small>{' '}
              <span className={tradeInEquity?.toString().startsWith('-') ? 'deduct' : ''}>
                {tradeInEquity ? tradeInEquity : '-'}
              </span>
            </Box>
            <Box theme={theme}>
              <small>Estimated Mileage:</small>{' '}
              <span>
                {tradeInRequestData?.mileage
                  ? Number(tradeInRequestData?.mileage).toLocaleString()
                  : '-'}
              </span>
            </Box>
            <Box theme={theme}>
              <small>Condition:</small>{' '}
              <span>{tradeInRequestData?.condition ? tradeInRequestData?.condition : '-'}</span>
            </Box>
          </PriceList>
          <Grid theme={theme} container columnSpacing={1}>
            <Grid theme={theme} item xs={4}>
              <Button
                theme={theme}
                secondary
                text="Remove"
                onClick={() => {
                  setConfirmationPopupState(true)
                }}
                disabled={isDisable}
                fullWidth
              />
            </Grid>
            <Grid theme={theme} item xs={8} textAlign="right">
              <Button
                theme={theme}
                secondary
                text="Edit Details"
                fullWidth
                disabled={isDisable}
                onClick={() => {
                  form.reset(tradeInRequestData)
                  setOpenTradeDialog(true)
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Box theme={theme} sx={{ mb: 4 }}>
          <Button
            theme={theme}
            secondary
            text="Add Trade-in Details"
            fullWidth
            onClick={handleClickOpenTrade}
            disabled={isDisable}
          />
        </Box>
      )}
      <Box theme={theme} sx={{ mb: 4 }}>
        <Divider />
      </Box>
      <Dialog
        disablePortal
        variant={undefined}
        size="lg"
        title="Trade-In"
        open={openTradeDialog}
        onCloseDialog={handleCloseTradeDialog}
        customFooter={
          <>
            <Button
              theme={theme}
              primary
              text="Continue"
              onClick={form.handleSubmit(saveTradeIn)}
            />
          </>
        }
        theme={theme}
        children={<TradeInPopup tradeInForm={form} defaultCurrency={defaultCurrency} />}
      />
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={removeFormData}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.tradeInConfirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.removeBtnText}
          secondaryBtnText={'Cancel'}
          icon={<Icon name="ErrorTriangle" />}
          isButtonTypeDanger
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          hideCancelButton={false}
        />
      )}
    </>
  )
}

export default memo(OrderTradeIn)
