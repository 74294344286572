import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { PageHeader } from 'components'
import { Typography, Grid, Box } from '@ntpkunity/controls'

import {
  AssetDetails,
  CustomerDetails,
  InsuranceDetails,
  OrderDetails,
  PaymentBreakdown,
  TradeInDetails
} from '@app/order-management/order-summary'
import { useGetOrderInformation } from '@apis/order-management.service'
import { useParams } from 'react-router-dom'

const OrderCancelled: FC = () => {
  const theme = useTheme()
  const { reference_id } = useParams()

  const { mutate: getOrderInformation, data: orderDetail } = useGetOrderInformation()
  const [orderinformation, setOrderInformation] = useState<any>(orderDetail)

  useEffect(() => {
    if (reference_id) {
      getOrderInformation(
        { reference_number: reference_id },
        {
          onSuccess(response: any) {
            if (response?.status === 'Cancelled') setOrderInformation(response)
          }
        }
      )
    }
  }, [reference_id])

  return (
    <LayoutWithSideNav theme={theme}>
      <Box theme={theme} sx={{ mb: 2.5 }}>
        <PageHeader
          className="main-page-header border-bottom"
          theme={theme}
          container
          item
          spacing={2}
        >
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
              <Typography theme={theme} variant="h2" component="h2">
                Order Cancelled
              </Typography>
              <Typography theme={theme} variant="body2" component="p">
                Sorry, the order has been cancelled and moved to your cancelled order queue.
              </Typography>
            </Grid>
          </Grid>
        </PageHeader>
      </Box>
      <AssetDetails order_info={orderinformation} />

      <Grid theme={theme} className="container" container spacing={2}>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
          <CustomerDetails customer_info={orderinformation?.customer_info} />

          <OrderDetails order_detail={orderinformation} />

          {orderinformation?.Insurance_Inforation ? (
            <InsuranceDetails insurance={orderinformation?.Insurance_Inforation} />
          ) : (
            ''
          )}

          {orderinformation?.order_tradein ? (
            <TradeInDetails order_tradein={orderinformation?.order_tradein} />
          ) : (
            ''
          )}
        </Grid>

        <PaymentBreakdown order_info={orderinformation} />
      </Grid>
    </LayoutWithSideNav>
  )
}

export default OrderCancelled
