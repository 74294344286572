import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid } from '@ntpkunity/controls'
import {
  Contracts,
  CustomerDetails,
  InsuranceDetails,
  OrderDetails,
  PaymentBreakdown,
  PaymentDetails,
  PickupAppointment,
  TradeInDetails
} from '@app/order-management/order-summary'

const OrderLayoutSummary: FC<{ orderDetail: any }> = ({ orderDetail }) => {
  const theme = useTheme()
  return (
    <Grid theme={theme} className="container" container spacing={2}>
      <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
        {orderDetail?.appointment_details ? (
          <PickupAppointment order_info={orderDetail}></PickupAppointment>
        ) : (
          ''
        )}

        {orderDetail?.documents && orderDetail?.documents.length > 0 ? (
          <Contracts
            documents={orderDetail?.documents as any}
            orderStatus={orderDetail?.status}
            customerReferenceId={orderDetail?.customer_info?.reference_id}
          />
        ) : (
          ''
        )}

        <PaymentDetails
          paymentIndicator={orderDetail?.payment_recieved}
          orderStatus={orderDetail?.status}
        />

        <CustomerDetails customer_info={orderDetail?.customer_info} />

        <OrderDetails order_detail={orderDetail} />

        {orderDetail?.Insurance_Inforation ? (
          <InsuranceDetails insurance={orderDetail?.Insurance_Inforation} />
        ) : (
          ''
        )}

        {orderDetail?.order_tradein ? (
          <TradeInDetails order_tradein={orderDetail?.order_tradein} />
        ) : (
          ''
        )}
      </Grid>
      <PaymentBreakdown order_info={orderDetail} />
    </Grid>
  )
}

export default OrderLayoutSummary
