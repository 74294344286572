import { useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Accordion, AccordionSummary, AccordionDetails, useTheme } from '@mui/material'
import { Icon, Button, Box } from '@ntpkunity/controls'
import PackageLease from '../order-management/quotation-package-type/package-lease'
import { IQuotationParameters } from 'types/quote-parameters'
import { useConvertQuotationToApplication } from '@apis/order-management.service'
import { AlertBox, PackageWrap } from '@components'
import { InstallationMode, Status } from '@helpers/enums'

const OrderQuotationComparison: any = ({
  setPopUpState,
  orderId,
  sendSaveOrderRequest,
  orderStatus,
  programCalculationData,
  allowedMileageData,
  quotationParameterData,
  setQuotationParameterData,
  quotationObject,
  setOrderStage,
  reCalculateQuote,
  discardChanges
}: any) => {
  const isOrderConditioned = orderStatus === Status.Conditioned
  const [, setSelectedValue] = useState('')
  const [quotationChanges, setQuotationChanges] = useState(false)
  const [selectedAccordion, setSelectedAccordion] = useState({
    periodicPayment: false,
    rvBalloon: false,
    options: false,
    fees: false,
    fIProducts: false,
    insurance: false
  })

  const {
    mutate: convertQuotationRequest
    // isLoading: convertQuotationLoading,
    // data: convertQuotationResponse,
    // error: convertQuotationError
  } = useConvertQuotationToApplication()

  const form: {
    control: any
    handleSubmit: any
    getValues: any
  } = useForm<IQuotationParameters>({
    defaultValues: useMemo(() => {
      return quotationParameterData
    }, [quotationParameterData])
  })

  const orderRequest = (finance_type: string) => {
    return quotationObject
      .filter((data: any) => data.finance_type === finance_type)
      .map((data: any) => {
        return { ...data, applicable_rate: 0 }
      })
  }

  const checkSelection = async (val: any) => {
    setPopUpState(false)
    if (!orderId) {
      sendSaveOrderRequest('Save and Select', val)
    } else {
      await sendSaveOrderRequest('Save and Select', val)
      setSelectedValue(val)
      convertQuotationRequest(orderRequest(val)[0], {
        onSuccess() {
          setOrderStage('Application')
        }
      })
    }
  }
  const trade_in_amount = quotationParameterData?.trade_in?.trade_in_amount
    ? parseFloat(quotationParameterData?.trade_in?.trade_in_amount)
    : 0
  const payoff_amount = quotationParameterData?.trade_in?.payoff_amount
    ? parseFloat(quotationParameterData?.trade_in?.payoff_amount)
    : 0
  const tradeInEquityValue = trade_in_amount - payoff_amount
  const sortedOptionsArray = quotationParameterData?.options?.sort((a: any, b: any) =>
    a?.installation_mode?.localeCompare(b.installation_mode)
  )
  const optionsSum = sortedOptionsArray?.reduce(function (lastSum: any, obj: any) {
    return (
      lastSum +
      ((obj.installation_mode === InstallationMode.PRE_INSTALLED && !obj.is_price_inclusive) ||
      obj.installation_mode !== InstallationMode.PRE_INSTALLED
        ? obj?.price || obj?.applied_price
        : 0)
    )
  }, 0)

  const theme = useTheme()
  return (
    <>
      {quotationChanges && (
        <AlertBox className="dark buttons">
          <span>You have done some changes. Please save them to update quotation.</span>
          <Box theme={theme} className="btn-wrap">
            <Button
              text="Discard Changes"
              onClick={() => {
                setQuotationChanges(false)
                discardChanges()
              }}
            />
            <Button text="Recalculate Quotation" onClick={() => reCalculateQuote(false)} />
          </Box>
        </AlertBox>
      )}
      <PackageWrap theme={theme} className="package-wrap" sx={{ mb: 5 }}>
        <Box theme={theme} className="package-container fixed-container">
          <Box theme={theme} className="package-cell">
            <Box theme={theme} className="package-row header">
              Quotation
            </Box>
            <Box theme={theme} className="package-body">
              <Box theme={theme} className="package-row toggle">
                <span>
                  <b>Estimated Periodic Payment</b>
                </span>
                {selectedAccordion.periodicPayment ? (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn ch-up"
                    onClick={() =>
                      setSelectedAccordion({
                        ...selectedAccordion,
                        periodicPayment: !selectedAccordion.periodicPayment
                      })
                    }
                  />
                ) : (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn"
                    onClick={() =>
                      setSelectedAccordion({
                        ...selectedAccordion,
                        periodicPayment: !selectedAccordion.periodicPayment
                      })
                    }
                  />
                )}
              </Box>
              <Accordion expanded={selectedAccordion.periodicPayment}>
                <AccordionSummary
                  aria-con-trols="panel1a-content"
                  id="panel1a-header"
                ></AccordionSummary>
                <AccordionDetails>
                  <Box theme={theme} sx={{ pl: 3 }}>
                    <Box theme={theme} className="package-row">
                      Net Periodic Payment
                    </Box>
                    <Box theme={theme} className="package-row">
                      Tax Amount
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Box theme={theme} className="package-row">
                <b>Due at Signing</b>
              </Box>
              <Box theme={theme} className="package-row">
                Terms
              </Box>
              <Box theme={theme} className="package-row">
                Rate
              </Box>
              <Box theme={theme} className="package-row">
                Annual Mileage
              </Box>
              <Box theme={theme} className="package-row">
                <hr />
              </Box>
              <Box theme={theme} className="package-row">
                Selling Price
              </Box>
              <Box theme={theme} className="package-row">
                Down Payment
              </Box>
              <Box theme={theme} className="package-row toggle">
                <span>RV/Balloon</span>
                {selectedAccordion.rvBalloon ? (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn ch-up"
                    onClick={() =>
                      setSelectedAccordion({
                        ...selectedAccordion,
                        rvBalloon: !selectedAccordion.rvBalloon
                      })
                    }
                  />
                ) : (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn"
                    onClick={() =>
                      setSelectedAccordion({
                        ...selectedAccordion,
                        rvBalloon: !selectedAccordion.rvBalloon
                      })
                    }
                  />
                )}
              </Box>
              <Accordion expanded={selectedAccordion.rvBalloon}>
                <AccordionSummary
                  aria-con-trols="panel1a-content"
                  id="panel1a-header"
                ></AccordionSummary>
                <AccordionDetails>
                  <Box theme={theme} sx={{ pl: 3 }}>
                    <Box theme={theme} className="package-row">
                      End of Term Payment
                    </Box>
                    <Box theme={theme} className="package-row">
                      RV on Options
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Box theme={theme} className="package-row toggle">
                <span>Options</span>
                {selectedAccordion.options ? (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn ch-up"
                    onClick={() =>
                      setSelectedAccordion({
                        ...selectedAccordion,
                        options: !selectedAccordion.options
                      })
                    }
                  />
                ) : (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn"
                    onClick={() =>
                      setSelectedAccordion({
                        ...selectedAccordion,
                        options: !selectedAccordion.options
                      })
                    }
                  />
                )}
              </Box>
              <Accordion expanded={selectedAccordion.options}>
                <AccordionSummary
                  aria-con-trols="panel1a-content"
                  id="panel1a-header"
                ></AccordionSummary>
                <AccordionDetails>
                  {sortedOptionsArray?.length > 0 &&
                    sortedOptionsArray?.map((option: any, index: any) => (
                      <Box theme={theme} sx={{ pl: 3 }} key={index}>
                        <>
                          {sortedOptionsArray[index - 1]?.installation_mode !==
                            option?.installation_mode && (
                            <Box theme={theme} className="package-row">
                              <b>
                                <small>{option?.installation_mode}</small>
                              </b>
                            </Box>
                          )}
                          <Box theme={theme} className="package-row">
                            {option?.product_name}
                          </Box>
                        </>
                      </Box>
                    ))}
                </AccordionDetails>
              </Accordion>

              <Box theme={theme} className="package-row toggle">
                <span>Fees</span>
                {selectedAccordion.fees ? (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn ch-up"
                    onClick={() => {
                      setSelectedAccordion({
                        ...selectedAccordion,
                        fees: !selectedAccordion.fees
                      })
                    }}
                  />
                ) : (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn"
                    onClick={() => {
                      setSelectedAccordion({
                        ...selectedAccordion,
                        fees: !selectedAccordion.fees
                      })
                    }}
                  />
                )}
              </Box>
              <Accordion expanded={selectedAccordion.fees}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                ></AccordionSummary>

                <AccordionDetails>
                  <Box theme={theme} sx={{ pl: 3 }}>
                    {(quotationParameterData?.lease_fees || [])
                      .concat(
                        (quotationParameterData?.finance_fees || [])?.filter(
                          (item: any) =>
                            (quotationParameterData?.lease_fees || [])?.findIndex(
                              (fee: any) => fee.dealer_fee_id == item.dealer_fee_id
                            ) < 0
                        )
                      )
                      ?.map((dealerData: any, index: number) => (
                        <Box theme={theme} key={index} className="package-row">
                          {dealerData?.fee_name}
                        </Box>
                      ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Box theme={theme} className="package-row toggle">
                <span>F&I Products</span>
                {selectedAccordion.fIProducts ? (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn ch-up"
                    onClick={() =>
                      setSelectedAccordion({
                        ...selectedAccordion,
                        fIProducts: !selectedAccordion.fIProducts
                      })
                    }
                  />
                ) : (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn"
                    onClick={() =>
                      setSelectedAccordion({
                        ...selectedAccordion,
                        fIProducts: !selectedAccordion.fIProducts
                      })
                    }
                  />
                )}
              </Box>
              <Accordion expanded={selectedAccordion.fIProducts}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                ></AccordionSummary>

                <AccordionDetails>
                  <Box theme={theme} sx={{ pl: 3 }}>
                    {(quotationParameterData?.lease_fni?.length > 0 ||
                      quotationParameterData?.finance_fni?.length > 0) &&
                      (quotationParameterData?.lease_fni || [])
                        .concat(
                          (quotationParameterData?.finance_fni || [])?.filter(
                            (item: any) =>
                              (quotationParameterData?.lease_fni || [])?.findIndex(
                                (fni: any) =>
                                  fni?.financial_insurance_id == item?.financial_insurance_id
                              ) < 0
                          )
                        )
                        .map((fniData: any, index: number) => (
                          <Box theme={theme} key={index} className="package-row">
                            {fniData?.product_name}
                          </Box>
                        ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Box theme={theme} className="package-row toggle">
                <span>Trade-In Equity/Inequity</span>
              </Box>
              <Box theme={theme} className="package-row toggle">
                <span>Taxes Capitalization</span>
              </Box>
              <Box theme={theme} className="package-row toggle">
                <span>Financed Amount</span>
              </Box>
            </Box>
          </Box>
        </Box>
        {programCalculationData?.length > 0 &&
          programCalculationData
            .sort((a: any, b: any) => a.program.finance_type.localeCompare(b.program.finance_type))
            .map((data: any) => (
              <Box theme={theme} className="package-container" key={data.program.fp_id}>
                <Box theme={theme} className="package-cell">
                  <Box theme={theme} className="package-row header">
                    <span>{data?.program?.finance_type}</span>
                    {!isOrderConditioned && orderStatus !== Status.PendingConfirmation && (
                      <Button
                        theme={theme}
                        secondary
                        text="Select"
                        onClick={() => checkSelection(data?.program?.finance_type)}
                      />
                    )}
                  </Box>
                  <PackageLease
                    orderStatus={orderStatus}
                    leaseObj={data?.program}
                    calculationData={data?.calculation}
                    form={form}
                    selectedAccordion={selectedAccordion}
                    sortedOptionsArray={sortedOptionsArray}
                    tradeInEquityValue={tradeInEquityValue}
                    optionsSum={optionsSum}
                    allowedMileageData={allowedMileageData}
                    quotationParameterData={quotationParameterData}
                    setQuotationParameterData={setQuotationParameterData}
                    setQuotationChanges={setQuotationChanges}
                  />
                </Box>
              </Box>
            ))}
      </PackageWrap>
    </>
  )
}

export default OrderQuotationComparison
