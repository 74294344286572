import { FC, useEffect, useState } from 'react'
import { Typography, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Grid, Box, DrawerWrap, ProductBanner, PageHeader } from 'components'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { useUpdateDocument } from '@apis/order-management.service'
import FinancialStipulation from './financial-stipulation'
import NonFinancialStipulation from './non-financial-stipulation'
import {
  FinancialStipType,
  HeaderButton,
  StipulationType
} from '@helpers/enums/stipulation-type.enum'
import { OrderApproved, Notification } from './order-stipulations-style'
import {
  useGetCreditResponse,
  useGetNonFinancialStips,
  useGetStipulationsByLenderId
} from '@apis/stipulation.service'
import { IOrderDetail, IStipulationResponse } from '@models'
import {
  useCreditResponse,
  useGetOrderInformation,
  useGetSubmittedOrderInformation,
  useUploadDocument
} from '@apis/order-management.service'
import { useGetInventoryByVin } from '@apis/inventory-management.service'
import { Status } from '@helpers/enums'
import { Icon, Menu } from '@ntpkunity/controls'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@helpers/enums'

export const OrderStipulation: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { reference_id, vin } = useParams()
  const { actions, states } = useStoreContext()
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const [filteredStipulations, setFilteredStipulations] = useState<IStipulationResponse[]>([])

  const { mutate: updateOrderStatus, isLoading } = useUpdateOrderStatus()
  const [order, setOrder] = useState<IOrderDetail>()
  const [nonStips, setNonStips] = useState([])
  const { mutate: getCreditDecision, data: creditDecision } = useGetCreditResponse()
  const { mutate: getNonStips } = useGetNonFinancialStips()
  const { mutate: getStipsByLenderId } = useGetStipulationsByLenderId()
  const { mutate: createDocument } = useUploadDocument()
  const { mutate: getOrderInformation } = useGetOrderInformation()
  const { mutate: creditResponse } = useCreditResponse()
  const { mutate: uploadDocument } = useUpdateDocument()
  const { mutate: getSubmittedOrderInformation } = useGetSubmittedOrderInformation()
  const [stipulationsByLender, setStipulationsByLender] = useState([])
  const { mutate: getVehicleByVin, data: vehicle } = useGetInventoryByVin()
  const [tempNonStip, setTempNonStip] = useState()
  const [isOrderSave, setIsOrderSave] = useState(false)
  const queryClient = useQueryClient()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  const navigateToOrderSummaryPage = (reference_id: string) => {
    setTimeout(() => {
      navigate(`/dms/order-summary/${reference_id}`)
    }, 3000)
  }

  useEffect(() => {
    getCreditDecision({ reference_number: reference_id })
    getVehicleByVin({ vin })
    getOrderInformation(
      { reference_number: reference_id },
      {
        onSuccess(response: any) {
          setOrder(response)
        }
      }
    )
  }, [])

  useEffect(() => {
    if (nonStips?.length > 0) {
      const newArray: any = nonStips.map((item: any) => ({
        ...item,
        content_type: 'Application'
      }))
      setDocuments(newArray)
    }
  }, [nonStips])

  useEffect(() => {
    if (filteredStipulations?.every((stip) => stip.fulfilled === true)) {
      setIsDisable(false)
    } else {
      setIsDisable(true)
    }
  }, [filteredStipulations])

  useEffect(() => {
    if (order?.id) {
      getNonStips(
        { order_id: order?.id },
        {
          onSuccess(res: any) {
            setNonStips(res)
            setTempNonStip(res)
          },
          onError() {
            setNonStips([] as any)
            setTempNonStip([] as any)
          }
        }
      )
    }
  }, [order])

  useEffect(() => {
    if (filteredStipulations?.length > 0) {
      const filteredNonStips = (nonStips as any)?.filter(
        (stips: any) => stips.document_status?.toLowerCase() !== 'draft'
      )
      const tempArr = [...filteredStipulations]
      for (const stips of tempArr) {
        if (
          stips.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL?.toLowerCase()
        ) {
          filteredNonStips?.find((st: any) => {
            if (st.stipulation_code === stips.stipulation_code && st.document_url !== null) {
              stips.fulfilled = true
            } else {
              stips.fulfilled = false
            }
          })
        }
      }
      setFilteredStipulations(tempArr)
    }
  }, [nonStips])

  useEffect(() => {
    const tempStips: IStipulationResponse[] = []
    stipulationsByLender?.filter((stip: any) =>
      creditDecision?.[0]?.stipulation?.forEach((el: any) => {
        if (stip.stipulation_code === el?.stipulation_code) {
          stip.fulfilled = false
          ;(stip.value_to = el.value_to), (stip.value_from = el.value_from)
          tempStips.push(stip)
        }
      })
    )
    setFilteredStipulations(tempStips)
    actions.setStipulationsInitialData({ ...states.stipulationsData, stipulations: tempStips })
  }, [stipulationsByLender, creditDecision])

  useEffect(() => {
    const tempStips = [...filteredStipulations]
    if (states?.stipulationsData) {
      tempStips
        ?.filter((stip) => stip.stipulation_type?.toLowerCase() === StipulationType.FINANCIAL)
        ?.forEach((st) => {
          if (st.parameter === FinancialStipType.TERMS) {
            if (
              states?.stipulationsData?.terms >= st?.value_from &&
              states?.stipulationsData?.terms <= st?.value_to
            ) {
              st.fulfilled = true
            } else {
              st.fulfilled = false
            }
          } else if (st.parameter === FinancialStipType.FINANCED_AMOUNT) {
            if (
              states?.stipulationsData?.finance_amount >= st?.value_from &&
              states?.stipulationsData?.finance_amount <= st?.value_to
            ) {
              st.fulfilled = true
            } else {
              st.fulfilled = false
            }
          } else if (st.parameter === FinancialStipType.PERIODIC_PAYMENT) {
            if (
              states?.stipulationsData?.monthly_payment >= st?.value_from &&
              states?.stipulationsData?.monthly_payment <= st?.value_to
            ) {
              st.fulfilled = true
            } else {
              st.fulfilled = false
            }
          }
        })
      setFilteredStipulations(tempStips)
    }
  }, [states.stipulationsData])

  useEffect(() => {
    if (order) {
      actions.setStipulationsInitialData({
        ...states?.stipulationsData,
        terms: order?.contract_term,
        monthly_payment: order?.estimated_monthly_payment,
        finance_amount: order?.finance_amount
      })
    }
  }, [order])

  useEffect(() => {
    if (states.lenderInfo.id) {
      getStipsByLenderId(
        { lender_id: states.lenderInfo.id },
        {
          onSuccess(res: any) {
            setStipulationsByLender(res)
          }
        }
      )
    }
  }, [states.lenderInfo.id])

  useEffect(() => {
    if (nonStips !== undefined && filteredStipulations?.length > 0 && order) {
      if ((nonStips as any)?.length === 0) {
        const tempArr = [] as any
        filteredStipulations
          ?.filter((stip) => stip.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL)
          ?.forEach((stip) => {
            const data = {
              customer_reference_id: order?.customer_info?.reference_id,
              external_reference_id: order?.id,
              document_upload_indicator: false,
              stipulation_code: stip.stipulation_code,
              document_type: stip.description,
              created_by: states.lenderInfo.id
            }
            tempArr.push(data)
          })
        tempArr?.forEach((obj: any) => {
          createDocument(obj, {
            onSuccess() {
              getNonStips(
                { order_id: order?.id },
                {
                  onSuccess(res: any) {
                    setNonStips(res)
                    setTempNonStip(res)
                  },
                  onError() {
                    setNonStips([] as any)
                    setTempNonStip([] as any)
                  }
                }
              )
            }
          })
        })
      }
    }
  }, [nonStips])

  const reSubmitOrder = () => {
    setIsOrderSave(true)
    const data = {
      order_id: reference_id,
      reqBody: {
        status: Status.ReSubmitted,
        user_id: 0
      }
    }
    updateOrderStatus(data, {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Order Saved Sucessfully',
          toastState: true
        })
        const data = [...filteredStipulations]
        data
          ?.filter((st) => st.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL)
          ?.forEach((stip) => {
            const matchedStip = (nonStips as any)?.find(
              (st: any) => st.stipulation_code === stip.stipulation_code
            )
            stip.document_url = matchedStip.document_url
            stip.document_type = matchedStip?.document_type
            stip.fulfilled = true
          })
        getSubmittedOrderInformation(
          { reference_number: reference_id, order_status: Status.ReSubmitted },
          {
            onSuccess(response: any) {
              setIsDisable(true)
              creditResponse(response, {
                onSuccess() {
                  actions.setToast({
                    toastMessage: 'Order Submitted for Credit Response',
                    toastState: true
                  })
                  navigateToOrderSummaryPage(reference_id as any)
                },
                onError(error: any) {
                  actions.setToast({
                    toastMessage: error.error.toString(),
                    toastState: true,
                    variant: 'error'
                  })
                }
              })
            }
          }
        )
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }

  const financialStipulations = filteredStipulations?.filter(
    (stip) => stip.stipulation_type?.toLowerCase() === StipulationType.FINANCIAL
  )

  const nonFinancialStipulations = filteredStipulations?.filter(
    (stip) => stip.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL
  )

  return (
    <>
      <OrderApproved theme={theme}>
        <DrawerWrap>
          <LayoutWithSideNav theme={theme}>
            <PageHeader className="main-page-header border-bottom">
              <Box flexGrow={'1'}>
                <Typography variant="h2" component="h2">
                  Order Conditioned
                </Typography>
                <Typography variant="body2" component="p" className="caption">
                  Please see the information below regarding the status of your credit application
                  review.
                </Typography>
              </Box>
              <Box className="action-area" flexShrink={0} display={'flex'} alignItems={'center'}>
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: 'Save Order',
                      optionValue: 'Save'
                    }
                  ]}
                  handleOptionClick={(_event, _key, value) => {
                    switch (value) {
                      case 'Save':
                        setIsOrderSave(true)
                        uploadDocument(documents, {
                          onSuccess() {
                            getNonStips({ order_id: order?.id })
                          }
                        })
                        break
                    }
                  }}
                  render={(onMenuSelection) => (
                    <Button
                      theme={theme}
                      secondary
                      iconText={<Icon name="MoreIcon" />}
                      onClick={onMenuSelection}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                />
                <Button
                  theme={theme}
                  primary
                  disabled={isLoading || isDisable}
                  text={HeaderButton.RE_SUBMIT_ORDER}
                  onClick={reSubmitOrder}
                  endIcon={isLoading && <img src={DisableLoader} alt="Loader" />}
                />
              </Box>
            </PageHeader>
            <ProductBanner>
              <Grid theme={theme} spacing={2} container>
                <Grid item xl={2} lg={3} md={3} sm={5} xs={12}>
                  <Box className="thumbnail">
                    <img
                      src={
                        vehicle?.photo_urls && vehicle?.photo_urls?.length > 0
                          ? vehicle?.photo_urls[0]?.location
                          : ''
                      }
                      alt="Product"
                    />
                  </Box>
                </Grid>
                <Grid item xl={6} lg={5} md={5} sm={7} xs={12} className="product-details">
                  <Box className="title-md">{vehicle?.vehicle_display_name}</Box>
                  <Box className="title-lg">
                    <Typography
                      className="text-muted"
                      display={'block'}
                      variant="body1"
                      component="p"
                    >
                      {vehicle?.year ? vehicle?.year : order ? order?.Vehicle_details?.year : ''}{' '}
                      {vehicle?.make ? vehicle?.make : order ? order?.Vehicle_details?.make : ''}{' '}
                      {vehicle?.model ? vehicle?.model : order ? order?.Vehicle_details?.model : ''}
                    </Typography>

                    {vehicle?.trim_description}
                  </Box>
                  <Box className="title-sm">
                    Order ID:{' '}
                    <b>{order?.reference_number ? order?.reference_number : reference_id}</b>
                  </Box>

                  <Box className="title-sm">
                    VIN:{' '}
                    <b>{vehicle?.vin ? vehicle?.vin : order ? order?.Vehicle_details?.vin : ''}</b>
                  </Box>
                </Grid>
                <Grid
                  theme={theme}
                  item
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{ mt: 2 }}
                  className="product-details"
                  textAlign="right"
                >
                  <Box theme={theme} className="title-md">
                    Net Financed Amount
                  </Box>
                  <Box theme={theme} className="title-lg">
                    {defaultCurrency}
                    {order?.finance_amount &&
                      order?.finance_amount.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                  </Box>
                  <Box theme={theme} className="title-sm">
                    Type:{' '}
                    <b>
                      {order?.contract_term} Month {order?.finance_type}
                    </b>
                    , Rate:{' '}
                    <b>
                      {order?.apr &&
                        order?.apr?.toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                          minimumFractionDigits: 4
                        })}
                      %
                    </b>
                  </Box>
                  <Box theme={theme} className="title-sm">
                    {order?.status == Status.SchedulePickup ||
                    order?.status == Status.Appointment ||
                    order?.status == Status.Completed ? (
                      <>Periodic Payment: </>
                    ) : (
                      <>Est. Periodic Payment: </>
                    )}

                    <b>
                      {defaultCurrency}
                      {order?.estimated_monthly_payment &&
                        order?.estimated_monthly_payment?.toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                          minimumFractionDigits: 2
                        })}
                    </b>
                  </Box>
                </Grid>
              </Grid>
            </ProductBanner>

            {financialStipulations?.length > 0 && (
              <Notification className="notification" theme={theme}>
                <FinancialStipulation
                  setOrder={setOrder}
                  isOrderSave={isOrderSave}
                  setIsOrderSave={setIsOrderSave}
                  filteredStips={financialStipulations}
                />
              </Notification>
            )}

            {nonFinancialStipulations?.length > 0 && (
              <Notification className="notification" sx={{ mt: 3 }} theme={theme}>
                <NonFinancialStipulation
                  stipulations={nonFinancialStipulations}
                  setDocuments={setDocuments}
                  documents={documents}
                  customer={order?.customer_info as any}
                  nonStips={tempNonStip as any}
                  setNonStips={setNonStips}
                  setTempNonStip={setTempNonStip}
                  orderReference={order?.reference_number}
                />
              </Notification>
            )}
          </LayoutWithSideNav>
        </DrawerWrap>
      </OrderApproved>
    </>
  )
}

export default OrderStipulation
