import { FC, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useTheme } from '@mui/material'
import { Box, Button, Icon, Input, Typography } from '@ntpkunity/controls'
import SunShineIcon from '../../public/assets/icons/SunShine-icon.svg'
import ChatSendIcon from '../../public/assets/icons/ChatSend-icon.svg'
import Typing from '../../public/assets/images/typing.gif'

export const Chatbot: FC<{
  conversation: any
  setConversation: any
}> = ({ conversation, setConversation }) => {
  const theme = useTheme()

  const [userInput, setUserInput] = useState<string>('')
  const [regenerate, setRegenerate] = useState<boolean>(false)
  const [typingIcon, setTypingIcon] = useState<boolean>(false)
  const [generatedCode, setGeneratedCode] = useState('')

  const generateRandomCode = (length: number) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      result += characters[randomIndex]
    }

    return result
  }
  useEffect(() => {
    if (conversation.length < 1) {
      setGeneratedCode(generateRandomCode(8))
    }
  }, [])

  const handleUserInput = (input: string) => {
    setUserInput(input)
  }
  const regenerateResponse = async () => {
    setRegenerate(false)
    setTypingIcon(true)
    botAPIFunc(conversation[conversation.length - 1].content)
  }
  const botAPIFunc = async (inputData: string) => {
    try {
      const response = await fetch(
        `${process.env.FARABI_API_GATEWAY}/affordability-bot/?message=${inputData}&conversation_id=${generatedCode}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'text/event-stream'
          }
        }
      )
      setTypingIcon(false)
      const reader = response.body?.pipeThrough(new TextDecoderStream()).getReader()
      let insert = false
      while (true) {
        const data = await reader?.read()
        if (data?.done) break
        if (data?.value !== undefined && !insert) {
          insert = true
          setConversation((prevConversation: any) => [
            ...prevConversation,
            { sender: 'Bot', content: data?.value }
          ])
        } else {
          setConversation((prevArray: any) => {
            // Create a new array with the updated last object
            return prevArray.map((obj: any, index: number) =>
              index === prevArray.length - 1
                ? { ...obj, content: obj.content.concat(data?.value ?? '') }
                : obj
            )
          })
        }
      }
    } catch (error: any) {
      setRegenerate(true)
      setTypingIcon(false)
      console.error('Error during fetch:', error.message)
    }
  }

  const handleSendMessage = async (inputData: string) => {
    setTypingIcon(true)
    if (inputData.trim() === '') return
    setConversation((prevConversation: any) => [
      ...prevConversation,
      { sender: 'You', content: inputData }
    ])
    setUserInput('')
    await botAPIFunc(inputData)
  }
  return (
    <Box theme={theme} className="chatbot-wrap">
      <Box theme={theme} className="scroll-container">
        <Box theme={theme} className="chatbot-container">
          {/*********** Chatbot Welcome ***********/}
          {conversation.length < 1 && (
            <>
              <Box theme={theme} className="blank-frame-wrap">
                <Box theme={theme} className="blank-frame">
                  <Box theme={theme} sx={{ mb: 3 }}>
                    {/* <img src={ChatGptIcon} alt="Chat GPT" /> */}
                    <Typography
                      theme={theme}
                      component="h3"
                      variant="h3"
                      children="Welcome to Finance First, your personal assistant"
                    />
                    <Typography
                      theme={theme}
                      component="p"
                      variant="body1"
                      children="Ask anything, get your answer"
                    />
                  </Box>
                  <Box theme={theme} sx={{ mb: 1 }}>
                    <img src={SunShineIcon} alt="Sun Shine" />
                  </Box>
                  <Box theme={theme} sx={{ mb: 2 }}>
                    <Typography theme={theme} component="p" variant="body1" children="Example" />
                  </Box>
                  <Box
                    theme={theme}
                    className={'rounded'}
                    onClick={() =>
                      handleSendMessage(
                        'Explain your order details including terms, upfront and monthly payment & credit score'
                      )
                    }
                  >
                    “Explain your order details including terms, upfront and monthly payment &
                    credit score”
                  </Box>
                  <Box
                    theme={theme}
                    className="rounded"
                    onClick={() => handleSendMessage('Got any promotions under my budget?')}
                  >
                    “Got any promotions under my budget?”
                  </Box>
                  <Box
                    theme={theme}
                    className="rounded"
                    onClick={() =>
                      handleSendMessage(
                        'How do I make a deal of my favourite car under the budget?'
                      )
                    }
                  >
                    “How do I make a deal of my favourite car under the budget?”
                  </Box>
                </Box>
              </Box>
              <Box theme={theme} className="rounded received">
                Hello! I am your personal chat assistant. How can i help you?
              </Box>
            </>
          )}
          {/*********** Chatbot Chat Wrap ***********/}
          <Box theme={theme} className="chat-wrap">
            {/*********** Chatbot Load Older Messages ***********/}
            {/* <Box theme={theme} className="toast top">
              <Typography
                theme={theme}
                component="div"
                variant="caption"
                className="light"
                children="Load Older Messages"
              />
            </Box> */}
            {/*********** Chatbot Chat ***********/}

            {conversation.map((message: any, index: number) =>
              message?.sender === 'You' ? (
                <Box key={index} theme={theme} className="rounded sent">
                  {message.content}
                </Box>
              ) : (
                <Box key={index} theme={theme} className="rounded received">
                  <ReactMarkdown>{message.content}</ReactMarkdown>
                </Box>
              )
            )}
            {/*********** Chatbot Regenerate response ***********/}
            {regenerate && (
              <Box theme={theme} className="toast">
                <Typography
                  theme={theme}
                  component="span"
                  variant="caption"
                  onClick={regenerateResponse}
                  className="dark"
                  children={
                    <>
                      <Icon name="ResetIcon" /> Regenerate response
                    </>
                  }
                />
              </Box>
            )}
            {/*********** Chatbot Typing ***********/}
            {typingIcon && (
              <Box theme={theme} className="toast">
                <Typography
                  theme={theme}
                  component="span"
                  variant="caption"
                  className="light no-shadow"
                  children={
                    <>
                      <img src={Typing} alt="Typing" height="5px" />
                    </>
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {/*********** Chatbot Footer ***********/}
      <Box theme={theme} className="drawer-footer">
        <Input
          type={''}
          theme={theme}
          fullWidth
          placeholder="Ask me anything..."
          value={userInput}
          onChange={(e) => {
            handleUserInput(e)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && userInput !== '') {
              handleSendMessage(userInput)
            }
          }}
          endAdornment={
            <Button
              theme={theme}
              primary
              iconText={<img src={ChatSendIcon} alt="Send" />}
              onClick={() => handleSendMessage(userInput)}
            />
          }
        />
      </Box>
    </Box>
  )
}
