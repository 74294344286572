import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'

import { Grid, Skeleton, Typography } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
import { utcToLocal } from '@helpers/methods'
import { useGetReasonsByLender } from '@apis/dealer-workqueue.service'
import { DetailCard } from 'shared/components/DetailCard'

const OrderDetailInformation: FC<{
  orderDataLoading: any
  contractsData?: any
  isCompleted?: any
}> = ({ orderDataLoading, contractsData, isCompleted }) => {
  const {
    states: { dealerSelectedOrderData }
  } = useStoreContext()
  const theme = useTheme()
  const [reasons, setReasons] = useState([])
  const { mutate: getReasonsByLenderId } = useGetReasonsByLender()
  const [reasonData, setReasonData] = useState<any>()

  const findReasonByCode = (code: any, reasons: any) => {
    return reasons.find((obj: any) => obj?.text === code)
  }

  useEffect(() => {
    if (reasons && dealerSelectedOrderData) {
      let reason = findReasonByCode(dealerSelectedOrderData?.cancellationCode, reasons)
      setReasonData(reason)
    }
  }, [reasons, dealerSelectedOrderData])
  useEffect(() => {
    getReasonsByLenderId(
      {},
      {
        onSuccess(response: any) {
          const newArray: any = []
          response?.map((obj: any) => {
            return newArray.push({
              text: obj?.reason_name,
              value: obj?.reason_description
            })
          })
          newArray.push({
            text: 'Other',
            value: '0'
          })
          setReasons(newArray)
        }
      }
    )
  }, [])
  return (
    <>
      <DetailCard className="detail-card" mb={2}>
        <Typography theme={theme} mb={2} component="h4" variant="h4">
          Order Details
        </Typography>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Contract Terms"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {!orderDataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                children={dealerSelectedOrderData?.orderItems[0].metadata.term ?? '-'}
              />
            )}
          </Grid>
        </Grid>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Odometer Reading"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {!orderDataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                children={
                  dealerSelectedOrderData?.orderItems[0].metadata.odometerReportedByDealer ?? '-'
                }
              />
            )}
          </Grid>
        </Grid>
        {isCompleted ? (
          <Grid theme={theme} className="container" container>
            <Grid theme={theme} item sm={3} xs={12}>
              <Typography
                theme={theme}
                variant="body2"
                component="h3"
                className="label"
                children="Subscription Start Date"
              />
            </Grid>
            <Grid theme={theme} item sm={9} xs={12}>
              {!orderDataLoading ? (
                <Skeleton theme={theme} width={'10%'} height={'20px'} />
              ) : (
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    contractsData && contractsData.length > 0 && contractsData[0]?.start_date
                      ? utcToLocal(contractsData[0]?.start_date, 'MM-DD-YYYY')
                      : '-'
                  }
                />
              )}
            </Grid>
          </Grid>
        ) : null}
        {!isCompleted ? (
          <>
            <Grid className="container" theme={theme} container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Cancelation Date"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                {!orderDataLoading ? (
                  <Skeleton theme={theme} width={'10%'} height={'20px'} />
                ) : (
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label-detail"
                    children={utcToLocal(dealerSelectedOrderData?.cancelledAt, 'MM-DD-YYYY') ?? '-'}
                  />
                )}
              </Grid>
            </Grid>
            <Grid className="container" theme={theme} container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Cancelation Reason"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                {!orderDataLoading ? (
                  <Skeleton theme={theme} width={'10%'} height={'20px'} />
                ) : (
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label-detail"
                    // children={dealerSelectedOrderData?.cancellationReason ?? '-'}
                    children={
                      dealerSelectedOrderData?.cancellationCode === 'OTHER'
                        ? dealerSelectedOrderData?.cancellationReason
                          ? dealerSelectedOrderData?.cancellationReason
                          : '-'
                        : reasonData?.value
                        ? reasonData?.value
                        : '-'
                    }
                  />
                )}
              </Grid>
            </Grid>
          </>
        ) : null}
      </DetailCard>
    </>
  )
}
export default OrderDetailInformation
