import React, { FC, useEffect, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { Dialog, Box, Button, Grid, Typography } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
// import { useGetFnIProductRate } from '@apis/financial-insurance.service'
import { VehicleCardUi } from '@components'
import FinancialInsurancePopupDetails from './financial_insurance_details'

const FinancialAndInsurancePopup: FC<{
  data: any
  activeTab: any
  financeRates?: any
  leaseRates?: any
  setSelectedFinanceItems?: any
  setSelectedLeaseItems?: any
  selectedFinanceItems?: any
  selectedLeaseItems?: any
  defaultCurrency?: any
  terms?: any
  fniPrice?: any
  setFniPrice?: any
}> = ({
  data,
  financeRates,
  leaseRates,
  activeTab,
  setSelectedFinanceItems,
  setSelectedLeaseItems,
  selectedFinanceItems,
  selectedLeaseItems,
  defaultCurrency,
  terms,
  fniPrice,
  setFniPrice
}) => {
  const theme = useTheme()
  const [openProductDialog, setOpenProductDialog] = React.useState(false)
  //@ts-ignore
  const { states } = useStoreContext()
  //@ts-ignore
  const [cardDetails, setCardDetails] = useState({ product_name: '' })
  //@ts-ignore
  const [rates, setRates] = useState<any>([])
  const [coverage, setCoverage] = useState<any>([])
  useEffect(() => {
    if (activeTab == 0 && financeRates) {
      setRates(financeRates)
    } else if (activeTab == 1 && leaseRates) {
      setRates(leaseRates)
    }
  }, [activeTab, financeRates, leaseRates])
  const calculateSum = (obj: any) => {
    if (activeTab == 0 && !selectedFinanceItems.some((x: any) => x.id == obj?.id)) {
      setSelectedFinanceItems([
        ...selectedFinanceItems,
        { ...obj, price: fniPrice?.finance?.find((x: any) => x.id == obj?.id)?.price ?? obj.price }
      ])
    } else if (activeTab == 1 && !selectedLeaseItems.some((x: any) => x.id == obj?.id)) {
      setSelectedLeaseItems([
        ...selectedLeaseItems,
        { ...obj, price: fniPrice?.lease?.find((x: any) => x.id == obj?.id)?.price ?? obj.price }
      ])
    }
  }
  const removeItem = (data: any) => {
    if (activeTab == 0) {
      setSelectedFinanceItems(selectedFinanceItems.filter((item: any) => item?.id !== data?.id))
      setFniPrice((prevState: any) => ({
        ...prevState,
        finance: prevState?.finance?.filter((item: any) => item?.id !== data?.id)
      }))
    } else {
      setSelectedLeaseItems(selectedLeaseItems.filter((item: any) => item?.id !== data?.id))
      setFniPrice((prevState: any) => ({
        ...prevState,
        lease: prevState?.lease?.filter((item: any) => item?.id !== data?.id)
      }))
    }
  }
  const isAdded = (id: any) => {
    return activeTab == 0
      ? selectedFinanceItems?.some((x: any) => x.id == id)
      : selectedLeaseItems?.some((x: any) => x.id == id)
  }
  const handleClickProductOpen = (cardData: any) => {
    setOpenProductDialog(true)
    setCardDetails(cardData)
    setCoverage(cardData.product_id)
  }

  const handleCloseProductDialog = () => {
    setOpenProductDialog(false)
  }

  const VehicleCard: FC<{ cardData: any }> = ({ cardData }: any) => {
    let smallestRetailPrice = Infinity
    for (let i = 0; i < rates?.length; i++) {
      if (rates?.[i]?.product_id == cardData?.product_id) {
        cardData.session_id = rates?.[i]?.session_id
        let getCoveragesWithMatchedTerm = rates?.[i]?.coverages?.coverage?.filter(
          (x: any) => x?.term_months == terms
        )
        getCoveragesWithMatchedTerm?.forEach((item: any) => {
          const retailPrice = item?.deductibles?.deductible?.retail_price
          cardData.form_id = item?.form?.form_id
          cardData.rate_id = item?.deductibles?.deductible?.rate_id
          if (retailPrice < smallestRetailPrice) {
            smallestRetailPrice = retailPrice
          }
        })
      }
    }
    const price = smallestRetailPrice
    cardData.price = price
    const getPrice = () => {
      if (
        activeTab == 0 &&
        selectedFinanceItems?.length > 0 &&
        selectedFinanceItems.some((item: any) => item.id == cardData?.id)
      ) {
        return `${defaultCurrency}${selectedFinanceItems
          .find((item: any) => item.id == cardData?.id)
          ?.price?.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}`
      } else if (
        activeTab == 1 &&
        selectedLeaseItems?.length > 0 &&
        selectedLeaseItems.some((item: any) => item.id == cardData?.id)
      ) {
        return `${defaultCurrency}${selectedLeaseItems
          .find((item: any) => item.id == cardData?.id)
          ?.price?.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}`
      } else if (
        activeTab == 0 &&
        fniPrice?.finance?.some((item: any) => item.id == cardData?.id && item?.price != Infinity)
      ) {
        return `${defaultCurrency}${fniPrice?.finance
          ?.find((item: any) => item.id == cardData?.id)
          ?.price.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}`
      } else if (
        activeTab == 1 &&
        fniPrice?.lease?.some((item: any) => item.id == cardData?.id && item?.price != Infinity)
      ) {
        return `${defaultCurrency}${fniPrice?.lease
          ?.find((item: any) => item.id == cardData?.id)
          ?.price.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}`
      } else if (cardData?.price !== Infinity) {
        return `${defaultCurrency}${cardData?.price?.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}`
      }
      return '-'
    }
    return (
      <Grid theme={theme} item lg={3} md={4} sm={6} xs={12}>
        <VehicleCardUi theme={theme} className="vehicle-card full-width">
          <Typography theme={theme} className="tag" component="span">
            Dealer
          </Typography>
          <Box theme={theme} className="vc-img-wrap h-120">
            <img
              src={
                cardData?.image?.length > 0
                  ? cardData?.image[0]?.location
                  : require('../../../../public/assets/images/no-img.svg')
              }
              alt="Product"
            />
          </Box>
          <Typography theme={theme} className="title" component="span">
            {cardData?.product_name}
          </Typography>
          <Link className="link" onClick={() => handleClickProductOpen(cardData)}>
            View Details
          </Link>
          <Box theme={theme} className="coverage">
            <Typography component="span" variant="body2" theme={theme} children="Coverage:" />
            <Typography component="b" variant="body2" theme={theme} children={getPrice()} />
          </Box>
          {isAdded(cardData?.id) ? (
            <Button
              fullWidth
              secondary
              theme={theme}
              onClick={() => removeItem(cardData)}
              text="Remove"
            />
          ) : (
            <Button
              fullWidth
              secondary
              theme={theme}
              onClick={() =>
                cardData?.price && cardData.price !== Infinity && calculateSum(cardData)
              }
              text="Add"
            />
          )}
        </VehicleCardUi>
      </Grid>
    )
  }
  return (
    <>
      <Grid theme={theme} spacing={2} container sx={{ mb: 5 }}>
        {data?.map((object: any, index: any) => (
          <VehicleCard cardData={object} key={index} />
        ))}
      </Grid>
      <Dialog
        disablePortal
        variant={undefined}
        size="xl"
        title={cardDetails && cardDetails.product_name}
        open={openProductDialog}
        onBackBtn={handleCloseProductDialog}
        // onCloseDialog={handleCloseProductDialog}
        theme={theme}
        children={
          <FinancialInsurancePopupDetails
            details={cardDetails}
            rates={rates}
            selectedProducts={coverage}
            setSelectedLeaseItems={setSelectedLeaseItems}
            setSelectedFinanceItems={setSelectedFinanceItems}
            selectedFinanceItems={selectedFinanceItems}
            selectedLeaseItems={selectedLeaseItems}
            activeTab={activeTab}
            defaultCurrency={defaultCurrency}
            fniPrice={fniPrice}
            setFniPrice={setFniPrice}
          />
        }
        noFooter
      />
    </>
  )
}

export default FinancialAndInsurancePopup
