import { FC, ReactNode, useEffect, useState } from 'react'
import { useTheme, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ConfirmationDialog, PageHeader } from '@components'
import { Typography, Button, Box, Icon } from '@ntpkunity/controls'
import { APP_ROUTES } from '@helpers/links'
import { PageHeaderStyle } from './pageHeader.style'
import {
  useReadyCheckListEvaluateRequest,
  useUpdateReturnDataRequest,
  useUpdateSubscriptionStatus
} from '@apis/dealer-workqueue.service'
// import { v4 as uuidv4 } from 'uuid'
import { useStoreContext } from '@store/storeContext'
import { getDecodedANToken, removeUndefinedKeys } from '@helpers/methods'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { getANToken } from '@helpers/methods'
import jwt_decode from 'jwt-decode'
import { useUpdateChecklistProgress } from '@apis/dealer-workqueue.service'
import { RETURNS } from '@helpers/enums'
import dayjs from 'dayjs'

//@ts-ignore
const ReturnPageHeader: FC<{
  handleSubmit: (e: any) => void
  formValues: any
  formIsDirty: any
  returnType: string
  setShowReturnConfirmationDialogue?: (value: boolean) => void
  returnStatus: string
  checklistData?: object
  setChecklistErrors?: any
  trigger?: any
  errors?: any
  setValue?: any
  checklistStartDate?: any
  keysData?: any
  setKeysData?: any
  schedulePickUpValue?: any
}> = ({
  trigger,
  formValues,
  setShowReturnConfirmationDialogue,
  returnStatus,
  checklistData,
  setChecklistErrors,
  errors,
  setValue,
  checklistStartDate,
  keysData,
  setKeysData,
  schedulePickUpValue
}: any) => {
  const theme = useTheme()
  const { actions, states } = useStoreContext()
  const { mutate: updateChecklistProgress, isLoading: updateCheckListLoading } =
    useUpdateChecklistProgress()
  const { mutate: updateReturnRequest, isLoading: updateReturnInProgress } =
    useUpdateReturnDataRequest()
  const { mutate: updateSubscriptionStatusCall, isLoading: updateSubscriptionStatusLoading } =
    useUpdateSubscriptionStatus()
  const { mutate: evaluateListCall, isLoading: evaluateListLoader } =
    useReadyCheckListEvaluateRequest()
  const decodedToken: any = jwt_decode(getANToken())
  const [showPopup, setShowConfirmationDialogue] = useState(false)
  const [actualReturnData, setActualReturnDate] = useState(false)
  const [evalateCall, setEvaluateCall] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      actions.setToast({
        toastMessage: '',
        toastState: false,
        showIcon: false
      })
    }
  }, [])

  useEffect(() => {
    if (checklistStartDate || actualReturnData) {
      saveChecklistStartDate()
    }
  }, [checklistStartDate, actualReturnData])
  const returnsKeysCheck = () => {
    const formData = formValues()?.checklist_questions
    const numberOfKeysReturnedQuestion = Object.entries(formData).find(
      ([question]) => question === 'Number of keys returned?'
    )
    const answer: string = numberOfKeysReturnedQuestion
      ? (numberOfKeysReturnedQuestion[1] as string)
      : ''
    if (numberOfKeysReturnedQuestion) {
      if (answer > schedulePickUpValue?.primary_answer) {
        actions.setToast({
          toastMessage: (
            <Typography
              textAlign={'center'}
              theme={theme}
              variant="body2"
              component="span"
              display="block"
            >
              There were {schedulePickUpValue?.primary_answer} keys provided at delivery. Are you
              certain that the key return count is correct, and they match this vehicle?
            </Typography>
          ),
          toastState: true,
          showButton: 'btn',
          yesButtonOnClick: () => {
            setKeysData(true)
            setEvaluateCall(true)
          },
          noButtonOnClick: () => {
            setKeysData(true)
            setEvaluateCall(false)
          }
        })
      } else if (answer < schedulePickUpValue?.primary_answer) {
        actions.setToast({
          toastMessage: (
            <Typography
              textAlign={'center'}
              theme={theme}
              variant="body2"
              component="span"
              display="block"
            >
              The customer was provided additional keys at delivery. Please inform the customer of
              the discrepancy and notify Mobility Customer Success.
            </Typography>
          ),
          toastState: true,
          showIcon: true
        })
        setEvaluateCall(true)
        setKeysData(true)
      } else {
        setEvaluateCall(true)
        setKeysData(true)
        actions.setToast({ toastMessage: '', toastState: false })
      }
    }
  }
  useEffect(() => {
    const checkListErrorMesages = compareAnswers()
    if (!errors.last_8_digits_of_vin && !errors.odometer_reading && keysData) {
      changeReturnDataOrStatusRequest(false)
    }
    if (checkListErrorMesages?.length > 0) {
      setChecklistErrors(checkListErrorMesages)
    }
    if (!errors.last_8_digits_of_vin && !errors.odometer_reading && keysData) {
      updateChecklistRequest()
    }
  }, [keysData])

  const onSubmit = async () => {
    await trigger()
    returnsKeysCheck()
  }

  const changeReturnDataOrStatusRequest = (isFavorable: boolean = false) => {
    const decodedToken = getDecodedANToken(getANToken())

    let data =
      isFavorable === false
        ? {
            current_odometer_reading: formValues()?.odometer_reading,
            planned_return_date: formValues()?.planned_return_date
              ? new Date(formValues()?.planned_return_date)?.toISOString()
              : null,
            // actual_return_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            ...(formValues()?.dealer_comments !== ''
              ? { dealer_comments: formValues()?.dealer_comments }
              : {}),
            updated_by: decodedToken?.email
          }
        : {
            current_odometer_reading: formValues()?.odometer_reading,
            ...(formValues()?.dealer_comments !== ''
              ? { dealer_comments: formValues()?.dealer_comments }
              : {}),
            updated_by: decodedToken?.email,
            return_status: 'PROCESSING_RETURN'
          }
    updateReturnRequest({
      return_identifier: states?.dealerSelectedReturnData?.identifier,
      ...(Object.keys(formValues())?.length > 0 && { data })
    })
  }

  const saveChecklistStartDate = () => {
    const decodedToken = getDecodedANToken(getANToken())
    const checklist_start_date = dayjs().toISOString()
    const actual_return_date = dayjs().toISOString()
    let data = {
      current_odometer_reading: formValues()?.odometer_reading
        ? formValues()?.odometer_reading
        : null,
      planned_return_date: formValues()?.planned_return_date
        ? new Date(formValues()?.planned_return_date)?.toISOString()
        : null,
      ...(formValues()?.dealer_comments !== ''
        ? { dealer_comments: formValues()?.dealer_comments }
        : {}),
      actual_return_date: actualReturnData ? actual_return_date : null,
      updated_by: decodedToken?.email,
      check_list_start_date: !actualReturnData
        ? checklist_start_date
        : formValues().check_list_start_date
    }
    updateReturnRequest(
      {
        return_identifier: states?.dealerSelectedReturnData?.identifier,
        ...(Object.keys(formValues())?.length > 0 && { data })
      },
      {
        onSuccess() {
          if (!actualReturnData) {
            setValue('check_list_start_date', checklist_start_date)
          } else if (actualReturnData) {
            setValue('actual_return_date', actual_return_date)
          }
        }
      }
    )
  }

  const updateChecklistRequest = () => {
    var selectedQuestionAnswersArrayForRequest = []
    const checklistWithoutUndefinedValues: any = removeUndefinedKeys(
      formValues()?.checklist_questions
    )
    if (
      checklistWithoutUndefinedValues &&
      Object.keys(checklistWithoutUndefinedValues)?.length > 0
    ) {
      for (const key in checklistWithoutUndefinedValues) {
        const obj: any = {
          customer_reference_id: states?.dealerSelectedOrderData?.customerId,
          order_reference_id: states?.dealerSelectedOrderData?.id,
          checklist_name: formValues()?.checklist_info?.checklist_name,
          event: formValues()?.checklist_info?.event_name,
          question_text: key,
          question_type: 'YES_NO',
          primary_answer:
            key === 'Number of keys returned?' && !evalateCall
              ? ''
              : checklistWithoutUndefinedValues[key],
          external_order_ref_number: states?.dealerSelectedOrderData?.id,
          checklist_identifier: formValues()?.checklist_info?.checklist_identifier,
          updated_by: decodedToken?.email,
          ...(formValues()?.keys_description !== '' && key === 'Number of keys returned?'
            ? { description: formValues()?.keys_description }
            : {})
        }
        selectedQuestionAnswersArrayForRequest.push(obj)
      }
      updateChecklistProgress(
        // Patch call (update checklist)
        { data: selectedQuestionAnswersArrayForRequest },
        {
          onSuccess() {
            evaluateChecklistlistRequest()
            setKeysData(false)
          },
          onError({ error }: any) {
            showToast(
              'error',
              <>
                <Typography theme={theme} variant="body2" component="span" display="block">
                  Something went wrong. Please try again or contact the Mobility Ops team for
                  support.
                </Typography>

                {error ? `error_details : { ${error?.response?.data?.detail?.message} }` : ''}
              </>,
              true
            )
          }
        }
      )
    }
  }

  const compareAnswers = () => {
    const checklistErrors = []

    for (const [question, answer] of Object.entries(formValues()?.checklist_questions)) {
      if (answer !== '' || answer !== undefined) {
        const matchingQuestion = checklistData[0][0]?.questions?.find(
          (q: { question: string }) => q?.question === question
        )

        if (matchingQuestion) {
          const matchingAnswer = matchingQuestion?.answers?.find(
            (a: { answer: any; is_correct?: boolean }) =>
              a?.answer === answer && (a?.is_correct ?? false)
          )

          if (!matchingAnswer?.is_correct) {
            checklistErrors?.push({ question, message: 'Amend the option to proceed.' })
          }
        }
      } else {
        checklistErrors?.push({ question, message: '' })
      }
    }
    return checklistErrors
  }

  const evaluateChecklistlistRequest = () => {
    const questionAnswersArrayForRequest: any = []
    const checklistWithoutUndefinedValues: any = removeUndefinedKeys(
      formValues()?.checklist_questions
    )
    if (
      checklistWithoutUndefinedValues &&
      Object.keys(checklistWithoutUndefinedValues)?.length > 0
    ) {
      for (const key in checklistWithoutUndefinedValues) {
        let answer = checklistWithoutUndefinedValues[key]
        if (key === 'Number of keys returned?' && !evalateCall) {
          answer = ''
        }
        const obj: any = { question: key, answer: answer }

        questionAnswersArrayForRequest.push(obj)
      }
      const requestData = {
        payload: [
          {
            checklist_name: formValues()?.checklist_info?.checklist_name,
            response: questionAnswersArrayForRequest
          }
        ]
      }
      evaluateListCall(
        { ...requestData },
        {
          onSuccess(res: any) {
            if (res && res[0]?.status === 'Favourable') {
              setActualReturnDate(true)
              setShowConfirmationDialogue(true)
            } else {
              if (!evalateCall) {
                actions.setToast({
                  toastMessage:
                    'Your other answers have been saved. Please return to complete the checklist once you have verified keys.',
                  toastState: true,
                  showIcon: true
                })
              }
            }
          },
          onError() {
            showToast('error', 'Some error occured in checklist evaluation', true)
          }
        }
      )
    }
  }

  const showToast = (type: string, message: string | ReactNode, showCancelIcon: boolean) => {
    actions.setToast({
      toastMessage: message,
      toastState: true,
      showIcon: showCancelIcon,
      ...(type === 'error' && { variant: type })
    })
  }

  const updateSubscriptionStatus = () => {
    updateSubscriptionStatusCall(
      {
        identifier: states?.dealerSelectedReturnData?.identifier,
        updated_by: decodedToken?.email,
        monthlyPayment: states?.dealerSelectedOrderData?.monthlyPayment,
        downPayment: states?.dealerSelectedOrderData?.downPayment
      },
      {
        onSuccess() {
          changeReturnDataOrStatusRequest(true)
          showToast('success', 'Status updated successfully', false)
          navigateToWorkqueuePage()
        },
        onError({ error }: any) {
          showToast(
            'error',
            <PageHeaderStyle className="page_header" theme={theme}>
              <Typography theme={theme} variant="body2" component="span" display="block">
                Something went wrong. Please try again or contact
                <Link
                  onClick={() =>
                    window.open(
                      'mailto:dealer-support@autonationmobility.com?subject=Dealer%20Tool%20Issue'
                    )
                  }
                >
                  {' '}
                  Mobility Store Support{' '}
                </Link>
                with the following error details.
              </Typography>
              <Typography theme={theme} variant="body2" component="span" display="block">
                {error &&
                error?.response?.data?.detail?.detail &&
                Object.keys(error?.response?.data?.detail?.detail)?.length > 0
                  ? `Error Details : { ${error?.response?.data?.detail?.detail?.message} }`
                  : error?.response?.data?.detail && error?.response?.data?.detail?.errorMessage
                  ? `Error Details : { ${error?.response?.data?.detail?.errorMessage} }`
                  : ''}
              </Typography>
            </PageHeaderStyle>,
            true
          )
        }
      }
    )
  }

  const navigateToWorkqueuePage = () => {
    setTimeout(() => {
      navigate(APP_ROUTES?.DEALER_WORK_QUEUE)
    }, 3000)
  }

  return (
    <>
      <PageHeader className="main-page-header border-bottom">
        <ConfirmationDialog
          openPopUp={showPopup}
          onConfirm={() => {
            setShowConfirmationDialogue(false)
            updateSubscriptionStatus()
          }}
          onCancel={() => {
            setShowConfirmationDialogue(false)
          }}
          setPopUpState={() => {}}
          confirmationTitle={'Do you confirm to proceed?'}
          confirmationText={
            'This will send the return request to the Operations team for their processing, and it will no longer be available in your queue.'
          }
          secondaryBtnText={'No'}
          primaryBtnText={'Yes'}
          icon={<Icon name="DialogAlertIcon" />}
          hideCancelButton={false}
        />
        <Box theme={theme} flexShrink={0} display={'flex'} sx={{ mr: 1 }}>
          <Button
            theme={theme}
            iconText={
              <Icon name="IconLeftArrow" onClick={() => navigate(APP_ROUTES?.DEALER_WORK_QUEUE)} />
            }
            className="btn-back"
          />
        </Box>
        <Box theme={theme} flexGrow={'1'}>
          <Typography
            theme={theme}
            variant="h2"
            component="h2"
            className="title"
            children={returnStatus}
          />
        </Box>
        <Box
          theme={theme}
          className="action-area"
          flexShrink={0}
          display={'flex'}
          alignItems={'center'}
        >
          {formValues().check_list_start_date ? (
            <Button
              theme={theme}
              primary
              text="Save Changes"
              disabled={
                // !formIsDirty ||
                updateReturnInProgress ||
                updateCheckListLoading ||
                evaluateListLoader ||
                updateSubscriptionStatusLoading
              }
              endIcon={
                (updateReturnInProgress ||
                  updateCheckListLoading ||
                  evaluateListLoader ||
                  updateSubscriptionStatusLoading) && <img src={DisableLoader} alt="Loader" />
              }
              onClick={onSubmit}
            />
          ) : (
            <Button
              theme={theme}
              primary
              text="Start Return"
              disabled={
                states?.dealerSelectedReturnData?.subscription_status !== RETURNS.PENDING_RETURN
              }
              onClick={() => {
                setShowReturnConfirmationDialogue(true)
                // changeReturnDataOrStatusRequest(false)
                // setValue(
                //           'checklist_return_start_date',

                //         )
              }}
            />
          )}
        </Box>
      </PageHeader>
    </>
  )
}

export default ReturnPageHeader
