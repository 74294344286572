import Http from '@helpers/http-api'
import { useMutation } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import {
  EP_GET_FINANCIAL_INSURANCE_PRODUCTS,
  EP_GET_FINANCIAL_INSURANCE,
  EP_GET_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_ID,
  EP_HUBEX_GET_FNIPRODUCT_RATES
} from '@helpers/endpoints'
import { IFinancialInsurance } from '@models'
import { getMarketPlaceToken } from '@helpers/methods'

const token = getMarketPlaceToken()
const headers = {
  Authorization: `Bearer ${token}`
}

export const useGetAllFinancialInsuranceInitialData = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_GET_FINANCIAL_INSURANCE)
    },
    {
      onSuccess(_response: any) {
        actions.setFinancialInsuranceInitialData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetFinancialInsuranceProductsByDealerId = (): any => {
  const { data, isLoading, mutate, error } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      let params = body.data ? `${body.data}` : ''
      return apiService.get<any>(
        params
          ? `${EP_GET_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_ID}?${params}`
          : EP_GET_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_ID,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, mutate, error }
}

export const useGetAllFinancialInsuranceProducts = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{ dealer_code: string }, any>(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_FINANCIAL_INSURANCE_PRODUCTS}`)
    },
    {
      onSuccess(_response: any) {
        actions.setFinancialInsuranceProductData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useSaveFinancialInsurance = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<IFinancialInsurance>(
    (body: any) => {
      // body.dealer_id = 1;
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_GET_FINANCIAL_INSURANCE}`, body)
    },
    {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Data Updated Sucessfully',
          toastState: true
        })
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true
        })
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateFinancialInsuranceById = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_GET_FINANCIAL_INSURANCE}/${body.id}`, body)
    },
    {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Data Updated Sucessfully',
          toastState: true
        })
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true
        })
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useDeleteFinancialInsuranceById = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.delete<any>(`${EP_GET_FINANCIAL_INSURANCE}/${body.id}`)
    },
    {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Record Deleted Sucessfully',
          toastState: true
        })
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true
        })
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetFnIProductRate = (): any => {
  const { data, isLoading, mutate, error } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY
      })
      const headers = {
        'x-pen-user-name': body?.headers?.pen_user_name,
        'x-pen-user-secret': body?.headers?.pen_user_secret,
        'x-pen-user-token': body?.headers?.pen_user_token
      }
      return apiService.post<any>(`${EP_HUBEX_GET_FNIPRODUCT_RATES}`, body?.data, {
        headers
      })
    },
    {
      onSuccess(_response: any) {},
      onError(_error: any) {}
    }
  )
  return { data, isLoading, mutate, error }
}
