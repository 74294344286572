import { FC, useEffect, useState } from 'react'
import { Typography, useTheme } from '@mui/material'
import { Button, Grid, Box, OrderReview } from 'components'
import { useCustomerFraudCheck } from '@apis/customer-management.service'
import { useNavigate, useParams } from 'react-router-dom'
import { useStoreContext } from '@store/storeContext'
import { APP_ROUTES } from '@helpers/links'
import { Status } from '@helpers/enums'

const OrderInReview: FC<{ setFinalStatus: any; orderId: string }> = ({
  setFinalStatus,
  orderId
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { customer_reference_id } = useParams()
  const [orderStatus, setOrderStatus] = useState<string | null>(null)
  const { mutate: saveCustomerFraudCheck } = useCustomerFraudCheck()
  const { actions } = useStoreContext()
  useEffect(() => {
    saveCustomerFraudCheck(
      {
        user_id: 0,
        reference_id: customer_reference_id,
        order_reference_number: orderId
      },
      {
        onSuccess: (response: any) => {
          setOrderStatus(response?.status)
          setFinalStatus(response?.status)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }, [])

  return (
    <OrderReview>
      <Grid className="container" theme={theme} spacing={2} container>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12} className="box">
          <Box className="content">
            {orderStatus === Status.Reviewed ? (
              <>
                <Typography className="heading">KYC Successful</Typography>
                <Typography className="text">
                  Customer verification has been completed successfully. Now you can proceed with
                  this order and submit the credit application.
                </Typography>
              </>
            ) : orderStatus === Status.Cancelled ? (
              <>
                <Typography className="heading">KYC Unsuccessful</Typography>
                <Typography className="text">
                  The order has been cancelled due to customer verification failure.
                </Typography>
                <Button
                  className="button"
                  theme={theme}
                  primary
                  onClick={() => navigate(APP_ROUTES.WORKQUEUE)}
                  text="Go To Order Page"
                />
              </>
            ) : (
              <>
                <Typography className="heading">KYC in progress</Typography>
                <Typography className="text">
                  This should not take too long. We will notify you once the customer verification
                  process is complete.
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </OrderReview>
  )
}

export default OrderInReview
