import { FC, useEffect, memo } from 'react'
import { useTheme } from '@mui/material'
import { Button, Grid, Box, Input, Typography } from '@ntpkunity/controls'
import { useWatch } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import {
  useGetContractTerms,
  useGetAllowedMileage,
  useGetRating
} from '@apis/configurations.service'
import { Controller } from 'react-hook-form'
import { useGetInventory } from '@apis/inventory-management.service'
import { useStoreContext } from '@store/storeContext'
import { RENTAL_FREQUENCY } from '@helpers/constants'
import { BtnGroupList, Divider } from '@components'

const Rating = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  '.selector': {
    width: '100%',
    display: 'inline-block',
    cursor: 'pointer',
    '.circle': {
      width: 60,
      height: 60,
      display: 'flex',
      verticalAlign: 'middle',
      textAlign: 'center',
      border: '1px solid',
      borderColor: theme.palette.divider,
      borderRadius: 60,
      clear: 'both',
      transition: 'all 0.3s',
      margin: 'auto auto 12px',
      '.icon': {
        width: 24,
        height: 24,
        display: 'table',
        margin: 'auto',
        transition: 'all 0.3s',
        path: {
          stroke: theme.palette.grey[300],
          transition: 'all 0.3s'
        }
      }
    },

    '.caption': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600],
      width: '100%',
      display: 'block',
      textAlign: 'center',
      marginBottom: 5,
      transition: 'all 0.3s'
    },
    span: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600],
      width: '100%',
      display: 'block',
      textAlign: 'center',
      transition: 'all 0.3s',
      opacity: 0,
      visibility: 'none'
    },

    '&:hover': {
      '.circle': {
        borderColor: theme.palette.primary.main,
        '.icon': {
          opacity: 0.6,
          path: {
            stroke: theme.palette.primary.main
          }
        }
      },
      span: {
        opacity: 0.5
      }
    },

    '&.selected': {
      '.circle': {
        borderColor: theme.palette.primary.main,
        '.icon': {
          path: {
            stroke: theme.palette.primary.main
          }
        }
      },
      '.caption': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[900]
      },
      span: {
        opacity: 1,
        visibility: 'visible'
      },

      '&:hover': {
        '.circle': {
          '.icon': {
            opacity: 1
          }
        }
      }
    }
  }
}))

const SectionWrap = styled('div')(() => ({
  '.icon-title': {
    width: '100%',
    display: 'inline-block',
    span: {
      float: 'left',
      marginRight: 5
    },
    '.icon': {
      float: 'left'
    }
  }
}))

const GeneralPreferences: FC<{
  isOrderConditioned?: boolean
  isDisable?: boolean
  defaultCurrency?: string
  form: any
  getSelectedDataForRatesRequest: any
  programs: any
  isStipTerms?: boolean
}> = ({
  form,
  getSelectedDataForRatesRequest,
  programs,
  isDisable,
  defaultCurrency,
  isOrderConditioned,
  isStipTerms
}) => {
  const theme = useTheme()
  const { mutate: getTerms, data: TermsData } = useGetContractTerms()
  const { mutate: getAllowedMilage, data: MilageData } = useGetAllowedMileage()
  const { mutate: getInventory } = useGetInventory()
  const { mutate: getCreditRating, data: RatingData } = useGetRating()
  const { states } = useStoreContext()
  const {
    control,
    watch,
    formState: { errors },
    setValue
  } = form
  const leaseProgram = programs?.filter((program: any) => program.finance_type == 'Lease')
  const financeProgram = programs?.filter((program: any) => program.finance_type == 'Finance')
  const minLeaseDownpayment =
    leaseProgram && leaseProgram.length > 0
      ? leaseProgram[0]?.downpayment_chart?.[0]?.minimum_down_payment
      : 0
  const maxLeaseDownpayment =
    leaseProgram && leaseProgram.length > 0
      ? leaseProgram[0]?.downpayment_chart?.[0]?.maximum_down_payment
      : 0
  const minFinanceDownpayment =
    financeProgram && financeProgram.length > 0
      ? financeProgram[0]?.downpayment_chart?.[0]?.minimum_down_payment
      : 0
  const maxFinanceDownpayment =
    financeProgram && financeProgram.length > 0
      ? financeProgram[0]?.downpayment_chart?.[0]?.maximum_down_payment
      : 0
  const handleClick = (key: any, value: any) => {
    if (key == 'contract_term_id') {
      setValue('contract_term_id', value)
      getSelectedDataForRatesRequest({ contract_term_id: value })
    }
    if (key == 'credit_rating_id') {
      setValue('credit_rating_id', value)
    }
    if (key == 'annual_mileage_id') {
      setValue('annual_mileage_id', value)
      getSelectedDataForRatesRequest({ annual_mileage_id: value })
    }
    if (key == 'rental_frequency') {
      setValue('rental_frequency', value)
    }
  }

  useEffect(() => {
    if (states?.lenderInfo?.id) {
      getTerms({ lender_id: states?.lenderInfo?.id })
      getAllowedMilage({ lender_id: states?.lenderInfo?.id })
      getInventory()
      getCreditRating({ lender_id: states?.lenderInfo?.id })
    }
  }, [states?.lenderInfo])

  useWatch({
    control,
    name: ['contract_term_id', 'credit_rating_id', 'annual_mileage_id', 'rental_frequency']
  })
  return (
    <>
      <SectionWrap>
        <Typography theme={theme} variant="h4" component="h4" sx={{ mb: 2 }}>
          Choose Term
        </Typography>
        <BtnGroupList>
          <Grid theme={theme} container rowSpacing={1} columnSpacing={1} sx={{ mb: 4 }}>
            {TermsData?.sort((a: any, b: any) => {
              return a.term - b.term
            })?.map((terms: any) => (
              <Grid theme={theme} item xs={2.4} key={terms.id}>
                <Button
                  disabled={isOrderConditioned && !isStipTerms}
                  theme={theme}
                  secondary={watch('contract_term_id') == terms.id ? false : true}
                  primary={watch('contract_term_id') == terms.id ? true : false}
                  fullWidth
                  text={terms.term}
                  onClick={() => handleClick('contract_term_id', terms.id)}
                />
              </Grid>
            ))}
          </Grid>
        </BtnGroupList>
        <Box theme={theme} sx={{ mb: 4 }}>
          <Divider />
        </Box>
        <Typography theme={theme} variant="h4" component="h4" sx={{ mb: 2 }}>
          Select Down Payment
        </Typography>
        <Grid theme={theme} container rowSpacing={2} columnSpacing={3} sx={{ mb: 3 }}>
          {leaseProgram?.length > 0 && (
            <Grid theme={theme} item xs={6}>
              <Controller
                name="lease_down_payment"
                control={control}
                defaultValue={''}
                rules={{
                  required: { value: true, message: 'empty' },
                  ...(!isOrderConditioned && {
                    min: {
                      value: minLeaseDownpayment,
                      message: `Downpayment should be between ${minLeaseDownpayment} and ${maxLeaseDownpayment}`
                    },
                    max: {
                      value: maxLeaseDownpayment,
                      message: `Downpayment should be between ${minLeaseDownpayment} and ${maxLeaseDownpayment}`
                    }
                  })
                }}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    label="For Lease"
                    fullWidth
                    placeholder={' Add here..'}
                    type="decimal"
                    endAdornment={'% '}
                    {...field}
                    error={errors?.finance_down_payment?.message}
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    disabled={isDisable}
                  />
                )}
              />
            </Grid>
          )}
          {financeProgram?.length > 0 && (
            <Grid theme={theme} item xs={6}>
              <Controller
                name="finance_down_payment"
                control={control}
                defaultValue={''}
                rules={{
                  required: { value: true, message: 'empty' },
                  ...(!isOrderConditioned && {
                    min: {
                      value: minFinanceDownpayment,
                      message: `Downpayment should be between ${minFinanceDownpayment} and ${maxFinanceDownpayment}`
                    },
                    max: {
                      value: maxFinanceDownpayment,
                      message: `Downpayment should be between ${minFinanceDownpayment} and ${maxFinanceDownpayment}`
                    }
                  })
                }}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    label="For Finance"
                    fullWidth
                    placeholder={' Add here..'}
                    type="decimal"
                    endAdornment={'% '}
                    {...field}
                    error={errors?.finance_down_payment?.message}
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    disabled={isDisable}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Box theme={theme} sx={{ mb: 4 }}>
          <Divider />
        </Box>
        <Typography theme={theme} variant="h4" component="h4" sx={{ mb: 2 }}>
          What is the Credit Score
        </Typography>
        <BtnGroupList>
          <Grid theme={theme} container rowSpacing={2} columnSpacing={1} sx={{ mb: 3 }}>
            {RatingData?.map((rating: any) => (
              <Grid theme={theme} item xs={4} key={rating.id}>
                <Button
                  theme={theme}
                  secondary={watch('credit_rating_id') == rating.id ? false : true}
                  primary={watch('credit_rating_id') == rating.id ? true : false}
                  text={
                    <>
                      {rating?.description}
                      <Box theme={theme} className="caption-text" key={rating.id}>
                        {rating?.score_from}-{rating?.score_to}
                      </Box>
                    </>
                  }
                  onClick={() => handleClick('credit_rating_id', rating.id)}
                  disabled={isOrderConditioned}
                />
              </Grid>
            ))}
          </Grid>
        </BtnGroupList>
        <Box theme={theme} sx={{ mb: 4 }}>
          <Divider />
        </Box>
        <Typography theme={theme} variant="h4" component="h4" className="icon-title" sx={{ mb: 2 }}>
          <span>Annual Vehicle Mileage</span>
        </Typography>
        <BtnGroupList>
          <Rating sx={{ mb: 3 }}>
            <Grid theme={theme} container rowSpacing={1} columnSpacing={1} sx={{ mb: 4 }}>
              {MilageData?.filter((x: any) => x.is_active == true)?.map((milage: any) => (
                <Grid theme={theme} item xs={2.4} key={milage.id}>
                  <Button
                    theme={theme}
                    secondary={watch('annual_mileage_id') === milage.id ? false : true}
                    primary={watch('annual_mileage_id') === milage.id ? true : false}
                    fullWidth
                    text={milage.value / 1000 + 'K'}
                    onClick={() => handleClick('annual_mileage_id', milage.id)}
                    disabled={isOrderConditioned}
                  />
                </Grid>
              ))}
            </Grid>
          </Rating>
        </BtnGroupList>
        <Box theme={theme} sx={{ mb: 4 }}>
          <Divider />
        </Box>
        <Typography theme={theme} variant="h4" component="h4" className="icon-title" sx={{ mb: 2 }}>
          <span>Payment Frequency</span>
        </Typography>
        <BtnGroupList>
          <Rating sx={{ mb: 3 }}>
            <Grid theme={theme} container rowSpacing={1} columnSpacing={1} sx={{ mb: 4 }}>
              {RENTAL_FREQUENCY?.map((frequency: any) => (
                <Grid theme={theme} item xs={6} key={frequency.id}>
                  <Button
                    theme={theme}
                    secondary={watch('rental_frequency') === frequency.id ? false : true}
                    primary={watch('rental_frequency') === frequency.id ? true : false}
                    fullWidth
                    text={frequency.text}
                    onClick={() => handleClick('rental_frequency', frequency.id)}
                    disabled={isOrderConditioned}
                  />
                </Grid>
              ))}
            </Grid>
          </Rating>
        </BtnGroupList>
        <Box theme={theme} sx={{ mb: 4 }}>
          <Divider />
        </Box>
        <Typography theme={theme} variant="h4" component="h4" sx={{ mb: 2 }}>
          Selling Price
        </Typography>
        <Controller
          name="selling_price"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value } }) => (
            <Input
              theme={theme}
              fullWidth
              placeholder={' Add here..'}
              type="decimal"
              startAdornment={defaultCurrency}
              // {...field}
              value={value}
              onChange={onChange}
              masking
              maskDecimalScale={2}
              maskNumeric
              disabled={isDisable}
            />
          )}
        />
        <Box theme={theme} sx={{ mb: 4 }}>
          <Divider />
        </Box>
      </SectionWrap>
    </>
  )
}

export default memo(GeneralPreferences)
