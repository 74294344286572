import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  Icon,
  Menu,
  PaginatedHeader,
  Skeleton,
  Button,
  Grid,
  Box,
  Dialog,
  Typography
} from '@ntpkunity/controls'
import {
  DrawerWrap,
  ProductBanner,
  AlertBox,
  ResponsiveCarousel,
  PageHeader,
  ConfirmationDialog,
  Farabi,
  VehicleCardUi
} from 'components'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetExpiryDate,
  useSaveOrderRequest,
  useUpdateQuotationById,
  useCreditResponse,
  useGetSubmittedOrderInformation,
  useConvertQuotationToApplication,
  useGetAllQuotations,
  useConvertApplicationToQuotation,
  useOrderSubmit
} from '@apis/order-management.service'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import { useGetInventoryByVin } from '@apis/inventory-management.service'
import { useModal } from 'react-modal-hook'
import { LayoutWithSideNav } from '@styles/layout'
import { ToastContainer } from 'react-toastify'
import { OrderQuotationDrawer } from '../order-preferences'
import CreateOrderQuotation from './create-order-quotation'
import useToastFunction from '@hooks/useToast'
import { useGetOrderInformation } from '@apis/order-management.service'
import RescheduleAppointmentDialog from '../order-summary/schedule-appointment-dialog'
import { Status, Reasons, DialogMessages, InstallationMode, QueryKeys } from '@helpers/enums'
import { useGetVehicleDetails } from '@apis/dealer-addon.service'
import { IQuotationParameters } from 'types/quote-parameters'
import { OrderLayoutSummary } from '../order-summary'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { CustomerDetails, OrderInReview } from '@pages'
import { APP_ROUTES } from '@helpers/links'
import { useGetIntegrationByProviderName } from '@apis/customer-detail.service'
import { ILenderIntegration } from '@models'
import { formatDate } from '@helpers/methods'
import { useHasFocus } from '@hooks/useHasHook'
import { useGetStates } from '@helpers/querykey'
import { useGetDealerProfileById } from '@apis/dealer-configurations.service'

interface IOrderLayoutProps {
  setOrder?: (data: any) => void
  isOrderStip?: boolean
  isOrderSave?: any
  setIsOrderSave?: (data: boolean) => void
}

const pendingConfirmationMenu = [
  { optionText: 'Available', optionValue: 'available' },
  { optionText: 'Not Available', optionValue: 'Cancel' }
]

const OrderLayout: FC<IOrderLayoutProps> = ({
  isOrderSave,
  isOrderStip,
  setOrder,
  setIsOrderSave
}) => {
  const { vin, customer_reference_id, reference_id, reschedule_popup } = useParams()

  let navigate = useNavigate()
  const {
    mutate: getOrderInformation,
    isLoading: orderInformationLoading,
    data: orderDetail
  } = useGetOrderInformation()
  const [programResponse, setProgramResponse] = useState<any>()
  const { mutate: getallQuotations } = useGetAllQuotations()
  const { mutate: getSubmittedOrderInformation, isLoading: getSubmittedOrderInformationLoading } =
    useGetSubmittedOrderInformation()
  const [orderObject, setOrderObject] = useState<any>(orderDetail)
  const [quotationObject, setQuotationObject] = useState<Array<any>>([])
  const theme = useTheme()
  const [popUpState, setPopUpState] = useState(false)
  const [quotationParameterData, setQuotationParameterData] = useState<IQuotationParameters>()
  const [orderId, setOrderId] = useState<string>('')
  const [programCalculationData, setProgramCalculationData] = useState<any[]>([])
  const [maxCreditRating, setMaxCreditRating] = useState<any>({})
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const { states, actions } = useStoreContext()
  const { mutate: getExpiryDate, data: expiryDate } = useGetExpiryDate()
  const [orderStatus, setOrderStatus] = useState<string>('Draft')
  const [orderStage, setOrderStage] = useState<string>('Quotation')
  const [mode, setMode] = useState<string>('New')
  const [, setIsOrderSubmit] = useState(false)
  const { mutate: creditResponse, isLoading: creditResponseLoading } = useCreditResponse()
  const { mutate: updateOrderStatus, isLoading: orderStatusLoading } = useUpdateOrderStatus()
  const { mutate: updateOrderStage, isLoading: orderStageLoading } =
    useConvertApplicationToQuotation()
  const { mutate: getProviderbyName } = useGetIntegrationByProviderName('Socure')
  const [provider, setProvider] = useState<ILenderIntegration>()
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const [confirmationPopupStateComplete, setConfirmationPopupStateComplete] =
    useState<boolean>(false)
  const [, setCalculationPayload] = useState<Array<any>>([])
  const [quoteCalculate, setQuoteCalculate] = useState(false)
  const [tabStates, setTabStates] = useState(false)
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const { mutate: getDealerProfile } = useGetDealerProfileById()
  const { mutate: submitCreditOrder } = useOrderSubmit()
  const {
    mutate: getVehicleByVin,
    isLoading: vehicleLoading,
    data: vehicle
  } = useGetInventoryByVin()
  const { mutate: getVehicleDetailsOption } = useGetVehicleDetails()
  const {
    mutate: saveOrderRequest,
    isLoading: saveRequestLodaing,
    data: saveResponse,
    error: saveRequestError
  } = useSaveOrderRequest()
  const focus = useHasFocus()
  const {
    mutateAsync: updateOrderRequest,
    isLoading: updateRequestLoading,
    data: updateResponse,
    error: updateRequestError
  } = useUpdateQuotationById()
  const handleshow = () => {
    if (!vehicleLoading) {
      setPopUpState(true)
    }
  }
  const queryClient = useQueryClient()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  useEffect(() => {
    if (states?.dealerAndLenderInfo?.dealer_code) {
      getDealerProfile({ dealer_id: states?.dealerAndLenderInfo?.dealer_code }, {})
    }
  }, [states?.dealerAndLenderInfo?.dealer_code])
  useEffect(() => {
    if (focus) {
      getProviderbyName(
        { provider_name: 'Socure' },
        {
          onSuccess(response: any) {
            setProvider(response?.is_active)
          }
        }
      )
    }
  }, [focus])

  useEffect(() => {
    if (orderStatus?.toLowerCase() !== Status.Conditioned?.toLowerCase()) {
      getProviderbyName(
        { provider_name: 'Socure' },
        {
          onSuccess(response: any) {
            setProvider(response?.is_active)
          }
        }
      )
    }
  }, [])

  useEffect(() => {
    if (isOrderSave) {
      sendSaveOrderRequest()
      setIsOrderSave?.(false)
    }
  }, [isOrderSave])

  useEffect(() => {
    reschedule_popup && setOpenPopup(reschedule_popup == 'true' ? true : false)
  }, [reschedule_popup])
  const onStepClick = (step: any) => {
    if (step == 1 && orderStatus !== Status.PendingConfirmation) {
      localStorage.setItem('step', 'GoToStep1')
      navigate('/dms/create-order/' + customer_reference_id)
    } else if (step == 2) {
      localStorage.setItem('step', 'GoToStep2')
    }
  }
  const onDeleteConfirm = () => {
    const data = {
      order_id: orderObject?.reference_number ?? orderId,
      reqBody: {
        status: Status.Cancelled,
        user_id: 0,
        reason:
          orderObject?.status === Status.PendingConfirmation
            ? Reasons.ASSET_NOT_AVAILABLE
            : Reasons.CANCELED_BY_DEALER
      }
    }
    updateOrderStatus(data, {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Order Cancelled',
          toastState: true
        })
        navigate(APP_ROUTES?.WORKQUEUE)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }

  const onStageChange = () => {
    setProgramCalculationData([])
    updateOrderStage(
      {
        identifier: orderObject && orderObject?.identifier,
        reference_number: orderObject && orderObject?.reference_number
      },
      {
        onSuccess() {
          setOrderStage('Quotation')
          getallQuotations(
            { reference_number: reference_id },
            {
              onSuccess(response: any) {
                setQuotationObject(response)
              }
            }
          )
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }
  useEffect(() => {
    if (states?.dealerAndLenderInfo?.id) {
      if (orderStage === 'Application') {
        getExpiryDate({
          order_state: 1,
          dealer_id: states?.dealerAndLenderInfo?.id
        })
      } else {
        getExpiryDate({
          order_state: 2,
          dealer_id: states?.dealerAndLenderInfo?.id
        })
      }
    }
  }, [states?.dealerAndLenderInfo?.id, orderStage])

  useEffect(() => {
    if (vin) {
      getVehicleByVin({ vin: vin })
      getVehicleDetailsOption(
        { vin: vin },
        {
          onSuccess(response: any) {
            actions.setSelectedOrderOptions(
              response?.dealer_options.map((option: any) => {
                return {
                  installation_mode: option.installation_mode,
                  option_id: option.option_id ? option.option_id : option.id,
                  applied_price: (option.applied_price ? option.applied_price : option.price) || 0,
                  product_name: option.product_name,
                  is_price_inclusive: option.is_price_inclusive,
                  rv_balloon_percentage:
                    (option.rv_balloon_percentage
                      ? option.rv_balloon_percentage
                      : option.rv_adder) || 0,
                  rv_balloon_value:
                    (option.rv_balloon_value ? option.rv_balloon_value : option.rv_adder) || 0
                }
              })
            )
          }
        }
      )
    }
    if (reference_id || orderStage == 'Application' || orderId) {
      if (reference_id) {
        setOrderId(reference_id)
      }
      getOrderInformation(
        { reference_number: reference_id ?? orderId },
        {
          onSuccess(response: any) {
            if (response) {
              setOrderObject(response)
              setOrderStatus(response.status)
              setOrderStage(response.order_stage)
              setMode('Edit')
            } else {
              getallQuotations(
                { reference_number: reference_id },
                {
                  onSuccess(response: any) {
                    setQuotationObject(response)
                    setMode('Edit')
                  }
                }
              )
            }
          }
        }
      )
    }
  }, [orderStage])
  const submitOrder = () => {
    const payload = {
      order_id: orderId,
      reqBody: {
        status: 'Submitted',
        user_id: 0
      }
    }
    updateOrderStatus(payload, {
      onSuccess(response: any) {
        submitCreditApprover(response.status)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }
  const submitCreditApprover = (status: string) => {
    getSubmittedOrderInformation(
      { reference_number: orderId, order_status: status },
      {
        onSuccess(response: any) {
          creditResponse(response, {
            onSuccess() {
              actions.setToast({
                toastMessage: 'Order Submitted for Credit Response',
                toastState: true
              })
              navigate(`/dms/order-summary/${orderId}`)
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          })
        }
      }
    )
  }
  const submitFraudCheck = () => {
    const payload = {
      order_id: orderId,
      reqBody: {
        status: 'In Review',
        user_id: 0
      }
    }
    updateOrderStatus(payload, {
      onSuccess(response: any) {
        setOrderStatus(response?.status)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }

  const completeOrder = () => {
    const payload = {
      order_id: reference_id,

      reqBody: {
        status: Status.Completed,
        user_id: 0
      }
    }
    updateOrderStatus(payload, {
      onSuccess() {
        navigate(APP_ROUTES?.WORKQUEUE)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }

  const [openVdModal, closeVdModal] = useModal(
    () => (
      <>
        <Dialog
          disablePortal
          className="dialog-with-border"
          theme={theme}
          title={'Vehicle Details'}
          open
          onCloseDialog={closeVdModal}
          noFooter
        >
          <VehicleCardUi theme={theme} className="vehicle-card full-width vd-popup m-dialog">
            <Box
              theme={theme}
              className="vc-info-head"
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box theme={theme} className="vc-type-name">
                <Typography
                  theme={theme}
                  className="vc-type text-muted"
                  display={'block'}
                  variant="body1"
                  component="p"
                >
                  {vehicle?.year} {vehicle?.make} {vehicle?.model}
                </Typography>
                <Typography
                  theme={theme}
                  className="vc-name text-dark"
                  display={'block'}
                  variant="h3"
                  component="h3"
                >
                  {vehicle?.trim_description}
                </Typography>
              </Box>
              <Box theme={theme} className="price-info">
                <Typography
                  theme={theme}
                  className="price-title text-muted"
                  display={'block'}
                  variant="body1"
                  component="p"
                >
                  Selling Price
                </Typography>
                <Typography
                  theme={theme}
                  className="vc-price text-primary"
                  display={'block'}
                  variant="h3"
                  component="h3"
                >
                  {defaultCurrency}
                  {vehicle?.internet_price?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </Typography>
              </Box>
            </Box>
            {vehicle?.photo_urls?.length > 0 && (
              <ResponsiveCarousel responsive={responsive} autoPlaySpeed={5000}>
                {vehicle?.photo_urls.map((obj: any, index: any) => (
                  <Box theme={theme} className="vc-img-wrap" key={index}>
                    <img src={obj?.location} alt="vehicle" />
                  </Box>
                ))}
              </ResponsiveCarousel>
            )}
            {vehicle?.photo_urls?.length <= 0 && <Icon name="NoPreviewAvailable" />}

            <Box theme={theme} className="vc-features">
              <Typography
                theme={theme}
                className="vf-title text-dark"
                display={'block'}
                variant="h4"
                component="h4"
              >
                Vehicle Features & Specifications
              </Typography>
              <Grid theme={theme} container item spacing={3}>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Drivetrain:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {vehicle?.drive_train}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Engine:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {vehicle?.engine_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Exterior Color:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {vehicle?.exterior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Interior Color:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {vehicle?.interior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Transmission Type:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {vehicle?.transmission_type}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Stock Number:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {vehicle?.stock_number}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </VehicleCardUi>
        </Dialog>
      </>
    ),
    [vehicle]
  )
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const saveOrderRequestObject = () => {
    return programCalculationData.map((data: any) => {
      const identifier =
        quotationObject && quotationObject.length > 0
          ? quotationObject.filter(
              (quotation: any) => quotation.finance_type == data?.program?.finance_type
            )[0]?.identifier
          : orderObject?.identifier
          ? orderObject?.identifier
          : ''
      const requestObject = {
        reference_number: orderId ? orderId : undefined,
        identifier: identifier ? identifier : undefined,
        dealer_id: states?.dealerAndLenderInfo?.id,
        lender_id: states?.dealerAndLenderInfo?.lender_id,
        reference_id: customer_reference_id ? customer_reference_id : '',
        contract_term: states?.contractTermsData?.filter(
          (term: any) => term.id === quotationParameterData?.contract_term_id
        )[0]?.term,
        allowed_usage: quotationParameterData?.annual_mileage_id,
        credit_rating: quotationParameterData?.credit_rating_id,
        apr: data?.program?.final_customer_rate ? data?.program?.final_customer_rate : 0,
        estimated_monthly_payment: data?.calculation?.estimated_monthly_payment
          ? data?.calculation?.estimated_monthly_payment
          : 0,
        annual_usage: quotationParameterData?.annual_mileage_id,
        due_at_signing: data?.calculation?.due_at_signing ? data?.calculation?.due_at_signing : 0,
        selling_price: quotationParameterData?.selling_price,
        down_payment:
          data?.program?.finance_type == 'Finance'
            ? quotationParameterData?.finance_down_payment
            : quotationParameterData?.lease_down_payment,
        down_payment_lease: quotationParameterData?.lease_down_payment,
        margin: data?.program?.interest_chart ? data?.program?.interest_chart[0].margin : 0,
        base_rate: data?.program?.base_rate_chart?.base_rate || 0,
        rv_balloon_percentage:
          (data?.program?.finance_type == 'Finance'
            ? quotationParameterData?.finance_rv_rate
            : quotationParameterData?.lease_rv_rate) || 0,
        rv_balloon_value:
          (data?.program?.finance_type == 'Finance'
            ? quotationParameterData?.finance_rv_rate
            : quotationParameterData?.lease_rv_rate) || 0,
        fp_id: data?.program?.fp_id,
        vehicle_id: vehicle?.id,
        vin: vehicle?.vin,
        finance_amount: quotationParameterData?.selling_price || 0,
        finance_type: data?.program?.finance_type ? data?.program?.finance_type : '',
        order_options:
          quotationParameterData?.options && quotationParameterData?.options.length > 0
            ? quotationParameterData?.options?.map((option: any) => {
                return {
                  installation_mode: option.installation_mode,
                  is_price_inclusive: option.is_price_inclusive,
                  option_id: option.option_id ? option.option_id : option.id,
                  applied_price: (option.applied_price ? option.applied_price : option.price) || 0,
                  rv_balloon_percentage:
                    (option.rv_balloon_percentage
                      ? option.rv_balloon_percentage
                      : option.rv_adder) || 0,
                  rv_balloon_value:
                    (option.rv_balloon_value ? option.rv_balloon_value : option.rv_adder) || 0
                }
              })
            : undefined,
        order_fnI:
          data?.program?.finance_type == 'Finance'
            ? (quotationParameterData?.finance_fni || [])?.map((fni: any) => {
                return {
                  financial_insurance_id: fni.financial_insurance_id,
                  applied_price: fni.applied_price,
                  form_id: fni?.form_id,
                  rate_id: fni?.rate_id,
                  session_id: fni?.session_id
                }
              })
            : (quotationParameterData?.lease_fni || [])?.map((fni: any) => {
                return {
                  financial_insurance_id: fni.financial_insurance_id,
                  applied_price: fni.applied_price,
                  form_id: fni?.form_id,
                  rate_id: fni?.rate_id,
                  session_id: fni?.session_id
                }
              }),
        order_tradein: quotationParameterData?.trade_in,
        order_fees:
          data?.program?.finance_type == 'Finance'
            ? quotationParameterData?.finance_fees
            : quotationParameterData?.lease_fees,
        order_asset: {
          make: vehicle?.make,
          model: vehicle?.model,
          trim: vehicle?.trim_description,
          year_manufacturer: vehicle?.year,
          msrp: vehicle?.msrp,
          exterior_color: vehicle?.exterior_color_description,
          classification: vehicle?.vehicle_class,
          engine_cylinder: vehicle?.engine_cylinders,
          power_type: vehicle?.engine_power,
          engine_displacement: vehicle?.engine_displacement,
          fuel_type: vehicle?.fuel_type,
          quantity: 1,
          unit_price: vehicle?.internet_price,
          vin: vehicle?.vin
        },
        asset_usage: quotationParameterData?.asset_usage,
        schedule_option: quotationParameterData?.schedule_option,
        rental_mode: data?.program?.rental_mode ?? '',
        rental_frequency: quotationParameterData?.rental_frequency,
        insurance:
          quotationParameterData?.insurance &&
          Object.keys(quotationParameterData?.insurance).length > 0
            ? {
                ...quotationParameterData?.insurance,
                expiration_date: quotationParameterData?.insurance.expiration_date?.split('T')[0]
                  ? quotationParameterData?.insurance.expiration_date?.split('T')[0]
                  : quotationParameterData?.insurance.expiration_date
              }
            : null,
        calcuation_method: data?.program?.calculation_method
      }
      requestObject.finance_amount =
        (requestObject.selling_price || 0) -
        (((quotationParameterData?.selling_price || 0) *
          ((data?.program?.finance_type == 'Finance'
            ? quotationParameterData?.finance_down_payment
            : quotationParameterData?.lease_down_payment) || 0)) /
          100 || 0) +
        (requestObject.order_fees?.reduce(
          (total: number, currentItem: any) => total + currentItem.applied_price,
          0
        ) || 0) +
        ((requestObject.order_fnI &&
          requestObject.order_fnI?.reduce(
            (total: number, currentItem: any) => total + currentItem.applied_price,
            0
          )) ||
          0) +
        (requestObject.order_options?.reduce(
          (total: number, currentItem: any) =>
            total +
            ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
              !currentItem.is_price_inclusive) ||
            currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
              ? currentItem.applied_price || currentItem.price
              : 0),
          0
        ) || 0) -
        ((requestObject?.order_tradein?.trade_in_amount || 0) -
          (requestObject?.order_tradein?.payoff_amount || 0))
      return requestObject
    })
  }

  const { mutate: convertQuotationRequest } = useConvertQuotationToApplication()

  const sendSaveOrderRequest = (saveType: string = 'Save Order', financeType: string = '') => {
    const reqObj = saveOrderRequestObject()
    if (!orderId) {
      if (saveType === 'Save and Select') {
        saveOrderRequest(reqObj, {
          onSuccess(response: any) {
            const quotationObject = response
              .filter((data: any) => data?.finance_type === financeType)
              .map((data: any) => {
                return { ...data, base_rate: 0, margin: 0, applicable_rate: 0 }
              })
            convertQuotationRequest(quotationObject[0], {
              onSuccess() {
                setOrderStage('Application')
              }
            })
            setOrderId(response[0].reference_number)
            setQuotationObject(response)
          }
        })
      } else {
        saveOrderRequest(reqObj, {
          onSuccess(response: any) {
            setOrderId(response[0].reference_number)
            setQuotationObject(response)
            const currentPath = location.pathname
            const newUrl = `${currentPath}/${response[0].reference_number}`
            navigate(newUrl)
          }
        })
      }
    } else {
      let updatedQuotation: any[] = []
      reqObj.map(async (request: any) => {
        await updateOrderRequest(request).then((response: any) => {
          const {
            order_fees,
            order_fni,
            order_options,
            down_payment,
            finance_amount,
            estimated_monthly_payment,
            due_at_signing,
            allowed_usage,
            annual_usage,
            contract_term,
            credit_rating,
            order_tradein,
            rental_frequency,
            selling_price
          } = response
          const updatedOrder = {
            ...orderObject,
            order_fees,
            order_fni,
            order_options,
            down_payment,
            finance_amount,
            estimated_monthly_payment,
            due_at_signing,
            allowed_usage,
            annual_usage,
            contract_term,
            credit_rating,
            order_tradein,
            rental_frequency,
            selling_price
          }
          setOrder?.(updatedOrder)
          if (orderObject && Object.keys(orderObject)?.length > 0) {
            setOrderObject?.(updatedOrder)
          }
          updatedQuotation = [...updatedQuotation, response]
          setQuotationObject(updatedQuotation)
        })
      })
    }
  }
  const handleAvailableClick = () => {
    submitCreditOrder(
      { referenceNumber: reference_id },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: 'Order Submitted for Credit Response',
            toastState: true
          })
          navigate(`/dms/order-summary/${reference_id}`)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }

  useToastFunction(saveRequestLodaing, saveResponse, saveRequestError, {
    text: saveRequestLodaing
      ? 'Saving Data'
      : saveRequestError
      ? 'Some Error Orccured'
      : 'Successful',
    type: saveRequestError ? 'error' : saveRequestLodaing ? 'info' : 'success'
  })
  useToastFunction(updateRequestLoading, updateResponse, updateRequestError, {
    text: updateRequestLoading
      ? 'Saving Data'
      : updateRequestError
      ? 'Some Error Orccured'
      : 'Successful',
    type: updateRequestError ? 'error' : updateRequestLoading ? 'info' : 'success'
  })
  useGetStates()
  return (
    <>
      <DrawerWrap open={popUpState}>
        <LayoutWithSideNav theme={theme} isDisplay={!isOrderStip}>
          <ToastContainer />
          <Box theme={theme} sx={{ mb: 2.5 }}>
            {isOrderStip ? (
              <></>
            ) : orderStatus == Status.SchedulePickup || orderStatus == Status.Appointment ? (
              <PageHeader
                className="main-page-header border-bottom"
                theme={theme}
                container
                item
                spacing={2}
              >
                <Grid theme={theme} container spacing={2}>
                  <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
                    <Typography theme={theme} variant="h2" component="h2">
                      {orderStatus == Status.SchedulePickup
                        ? `Schedule Pickup/Delivery`
                        : orderStatus == Status.Appointment
                        ? `Appointment`
                        : ''}
                    </Typography>
                    <Typography theme={theme} variant="body2" component="p">
                      {orderStatus == Status.SchedulePickup
                        ? `The contracts are signed and payment is done. Now schedule the appointment.`
                        : orderStatus == Status.Appointment &&
                          `The appointment has been scheduled for this order. Mark this order complete once the pickup/delivery is done.`}
                    </Typography>
                  </Grid>
                  <Grid
                    theme={theme}
                    item
                    lg={5}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                      gap: 2
                    }}
                  >
                    <Menu
                      theme={theme}
                      options={[
                        {
                          optionText: 'Cancel Order',
                          optionValue: 'Cancel'
                        }
                      ]}
                      handleOptionClick={(_event, _key, value) => {
                        switch (value) {
                          case 'Cancel':
                            setConfirmationPopupState(true)
                            break
                        }
                      }}
                      render={(onMenuSelection) => (
                        <Button
                          theme={theme}
                          secondary
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                    />
                    {orderStatus == Status.SchedulePickup ? (
                      <Button
                        theme={theme}
                        primary
                        id="ScheduleAppointment"
                        text={'Schedule Appointment'}
                        onClick={() => {
                          setOpenPopup(true)
                        }}
                      />
                    ) : orderStatus == Status.Appointment ? (
                      <Button
                        theme={theme}
                        primary
                        id="CompleteOrder"
                        text={'Complete Order'}
                        onClick={() => {
                          setConfirmationPopupStateComplete(true)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </PageHeader>
            ) : orderStatus === Status.InReview ||
              orderStatus === Status.Reviewed ||
              orderStatus === Status.Cancelled ||
              (orderStatus === Status.Draft && orderStage === 'Application') ? (
              <PaginatedHeader
                theme={theme}
                text={'Customer Details'}
                step={3}
                onClick={(e: any) => {
                  if (e === 2) {
                    onStageChange()
                  } else if (e === 1) {
                    localStorage.setItem('step', 'GoToStep1')
                    navigate('/dms/create-order/' + customer_reference_id)
                  }
                }}
                actionArea={
                  <>
                    {orderStatus === Status.Reviewed ? (
                      <>
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: 'Cancel Order',
                              optionValue: 'Cancel'
                            }
                          ]}
                          handleOptionClick={(_event, _key, value) => {
                            switch (value) {
                              case 'Cancel':
                                setConfirmationPopupState(true)
                                break
                            }
                          }}
                          render={(onMenuSelection) => (
                            <Button
                              theme={theme}
                              secondary
                              iconText={<Icon name="MoreIcon" />}
                              onClick={onMenuSelection}
                            ></Button>
                          )}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                        />
                        <Button
                          theme={theme}
                          secondary
                          disabled={
                            orderStatusLoading ||
                            creditResponseLoading ||
                            getSubmittedOrderInformationLoading ||
                            orderStageLoading
                          }
                          text="Submit Credit Application"
                          onClick={submitOrder}
                        />
                      </>
                    ) : orderStatus === Status.Draft && orderStage === 'Application' ? (
                      <>
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: 'Cancel Order',
                              optionValue: 'Cancel'
                            }
                          ]}
                          handleOptionClick={(_event, _key, value) => {
                            switch (value) {
                              case 'Cancel':
                                setConfirmationPopupState(true)
                                break
                            }
                          }}
                          render={(onMenuSelection) => (
                            <Button
                              theme={theme}
                              secondary
                              iconText={<Icon name="MoreIcon" />}
                              onClick={onMenuSelection}
                            ></Button>
                          )}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                        />
                        <Button
                          disabled={!tabStates}
                          theme={theme}
                          onClick={() => {
                            provider ? submitFraudCheck() : submitOrder()
                          }}
                          secondary
                          text={provider ? 'Submit Order' : 'Submit Credit Application'}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                }
              />
            ) : (
              !orderInformationLoading && (
                <PaginatedHeader
                  theme={theme}
                  text={'Create Quotation'}
                  disablePreviousStep={orderStatus === Status.PendingConfirmation ? true : false}
                  step={2}
                  onClick={onStepClick}
                  actionArea={
                    <>
                      {orderId && (
                        <Menu
                          theme={theme}
                          options={
                            orderStatus === Status.PendingConfirmation
                              ? pendingConfirmationMenu
                              : [
                                  {
                                    optionText: 'Cancel Order',
                                    optionValue: 'Cancel'
                                  },
                                  { optionText: 'Resend Email Invite', disabled: true },
                                  { optionText: 'Send Order Link', disabled: true }
                                ]
                          }
                          handleOptionClick={(_event, _key, value) => {
                            switch (value) {
                              case 'Cancel':
                                setConfirmationPopupState(true)
                                break
                              case 'available':
                                handleAvailableClick() // implement available logic here
                                break
                            }
                          }}
                          render={(onMenuSelection) => (
                            <Button
                              theme={theme}
                              secondary
                              iconText={<Icon name="MoreIcon" />}
                              onClick={onMenuSelection}
                            ></Button>
                          )}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                        />
                      )}
                      {((states?.calculationData?._response &&
                        !vehicleLoading &&
                        programResponse?.length > 0) ||
                        orderStatus === Status.PendingConfirmation) && (
                        <Button
                          theme={theme}
                          secondary
                          text="Save Order"
                          onClick={() => sendSaveOrderRequest()}
                        />
                      )}
                    </>
                  }
                />
              )
            )}
          </Box>
          {!vehicleLoading && (orderDetail != null ? true : programResponse?.length > 0) ? (
            <>
              {orderStatus === Status.SchedulePickup ||
              orderStatus === Status.Appointment ||
              orderStatus === Status.PendingConfirmation ||
              orderStatus === Status.Conditioned ? (
                <></>
              ) : (
                <>
                  {orderDetail?.expiry_date === null ||
                  (expiryDate && orderDetail?.expiry_date === null) ? (
                    <></>
                  ) : orderDetail?.expiry_date !== null &&
                    orderDetail?.expiry_date !== undefined ? (
                    <AlertBox className="default">
                      {'Expires on ' + formatDate(new Date(orderDetail?.expiry_date))}
                    </AlertBox>
                  ) : expiryDate !== null && expiryDate !== undefined ? (
                    <AlertBox className="default">
                      {'Expires on ' + formatDate(new Date(expiryDate))}
                    </AlertBox>
                  ) : (
                    <></>
                  )}
                </>
              )}

              <ProductBanner>
                <Grid theme={theme} spacing={2} container>
                  <Grid theme={theme} item xl={2} lg={3} md={3} sm={5} xs={12}>
                    <Box theme={theme} className="thumbnail">
                      <img
                        src={
                          vehicle?.photo_urls && vehicle?.photo_urls?.length > 0
                            ? vehicle?.photo_urls[0]?.location
                            : ''
                        }
                        alt="Product"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    theme={theme}
                    item
                    xl={6}
                    lg={5}
                    md={5}
                    sm={7}
                    xs={12}
                    className="product-details"
                  >
                    <Box theme={theme} className="title-md">
                      {vehicle?.vehicle_display_name}
                    </Box>
                    <Box theme={theme} className="title-lg">
                      <Typography
                        theme={theme}
                        className="text-muted"
                        display={'block'}
                        variant="body1"
                        component="p"
                      >
                        {vehicle?.year
                          ? vehicle?.year
                          : orderObject
                          ? orderObject?.Vehicle_details?.year
                          : ''}{' '}
                        {vehicle?.make
                          ? vehicle?.make
                          : orderObject
                          ? orderObject?.Vehicle_details?.make
                          : ''}{' '}
                        {vehicle?.model
                          ? vehicle?.model
                          : orderObject
                          ? orderObject?.Vehicle_details?.model
                          : ''}
                      </Typography>

                      {vehicle?.trim_description}
                    </Box>
                    <Box theme={theme} className="title-sm">
                      Order ID: <b>{orderId ? orderId : ' - '}</b>
                    </Box>

                    <Box theme={theme} className="title-sm">
                      VIN:{' '}
                      <b>
                        {vehicle?.vin
                          ? vehicle?.vin
                          : orderObject
                          ? orderObject?.Vehicle_details?.vin
                          : ''}
                      </b>
                    </Box>
                  </Grid>
                  {(!orderInformationLoading &&
                    orderStage === 'Quotation' &&
                    orderStatus === Status.Draft) ||
                  orderStatus === Status.Conditioned ||
                  orderStatus === Status.PendingConfirmation ? (
                    <Grid theme={theme} item md={4} sm={12} xs={12} textAlign="right">
                      <Box theme={theme} className="product-opions">
                        <Button
                          theme={theme}
                          secondary
                          text="View Vehicle Details"
                          onClick={() => {
                            openVdModal()
                          }}
                        />
                        <Button
                          theme={theme}
                          primary
                          text="Configure Quotation"
                          onClick={handleshow}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      theme={theme}
                      item
                      md={4}
                      sm={12}
                      xs={12}
                      sx={{ mt: 2 }}
                      className="product-details"
                      textAlign="right"
                    >
                      <Box theme={theme} className="title-md">
                        Net Financed Amount
                      </Box>
                      <Box theme={theme} className="title-lg">
                        {defaultCurrency}
                        {orderObject?.finance_amount &&
                          orderObject?.finance_amount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}
                      </Box>
                      <Box theme={theme} className="title-sm">
                        Type:{' '}
                        <b>
                          {orderObject?.contract_term} Months {orderObject?.finance_type}
                        </b>
                        , Rate:{' '}
                        <b>
                          {orderObject?.apr &&
                            orderObject?.apr?.toLocaleString(undefined, {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 4
                            })}
                          %
                        </b>
                      </Box>
                      <Box theme={theme} className="title-sm">
                        {orderObject?.status == Status.SchedulePickup ||
                        orderObject?.status == Status.Appointment ||
                        orderObject?.status == Status.Completed ? (
                          <>Periodic Payment: </>
                        ) : (
                          <>Est. Periodic Payment: </>
                        )}

                        <b>
                          {defaultCurrency}
                          {orderObject?.estimated_monthly_payment &&
                            orderObject?.estimated_monthly_payment?.toLocaleString(undefined, {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 2
                            })}
                        </b>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </ProductBanner>
            </>
          ) : (
            !programResponse && <Skeleton theme={theme} width={'100%'} height={'80px'}></Skeleton>
          )}
          {!orderInformationLoading &&
          (orderStatus === Status.SchedulePickup || orderStatus === Status.Appointment) ? (
            <OrderLayoutSummary orderDetail={orderObject}></OrderLayoutSummary>
          ) : orderStatus === Status.InReview ||
            orderStatus === Status.Reviewed ||
            orderStatus === Status.Cancelled ? (
            <OrderInReview setFinalStatus={setOrderStatus} orderId={orderId} />
          ) : (orderStatus === Status.Draft && orderStage === 'Quotation') ||
            orderStatus === Status.Conditioned ||
            orderStatus === Status.PendingConfirmation ? (
            <>
              <CreateOrderQuotation
                setPopUpState={setPopUpState}
                setProgramResponse={setProgramResponse}
                sendSaveOrderRequest={sendSaveOrderRequest}
                orderId={orderId}
                orderStatus={orderStatus}
                vehicle={vehicle}
                programCalculationData={programCalculationData}
                setProgramCalculationData={setProgramCalculationData}
                maxCreditRating={maxCreditRating}
                setMaxCreditRating={setMaxCreditRating}
                setQuotationParameterData={setQuotationParameterData}
                quotationParameterData={quotationParameterData}
                quotationObject={quotationObject}
                setOrderStage={setOrderStage}
                setCalculationPayload={setCalculationPayload}
                setQuoteCalculate={setQuoteCalculate}
                quoteCalculate={quoteCalculate}
                orderStage={orderStage}
                orderObject={orderObject}
                mode={mode}
              ></CreateOrderQuotation>
              {popUpState && quotationParameterData && (
                <OrderQuotationDrawer
                  orderStatus={orderStatus}
                  popUpState={popUpState}
                  setPopUpState={setPopUpState}
                  quotationParameterData={quotationParameterData}
                  setQuotationParameterData={setQuotationParameterData}
                  programs={programCalculationData?.map((data: any) => {
                    return data.program
                  })}
                  setQuoteCalculate={setQuoteCalculate}
                ></OrderQuotationDrawer>
              )}
            </>
          ) : orderStage === 'Application' && orderStatus === Status.Draft ? (
            <CustomerDetails
              customerReferenceId={customer_reference_id}
              setIsOrderSubmit={setIsOrderSubmit}
              setTabStates={setTabStates}
            ></CustomerDetails>
          ) : (
            <></>
          )}
          {orderStage === 'Quotation' && orderStatus === Status.Draft && <Farabi />}
        </LayoutWithSideNav>
      </DrawerWrap>
      <RescheduleAppointmentDialog
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        order_detail={orderObject}
      ></RescheduleAppointmentDialog>
      {confirmationPopupStateComplete && (
        <ConfirmationDialog
          openPopUp={confirmationPopupStateComplete}
          onConfirm={completeOrder}
          setPopUpState={setConfirmationPopupStateComplete}
          confirmationTitle={DialogMessages.completeOrderTitle}
          confirmationText={DialogMessages.completeOrderText}
          primaryBtnText={DialogMessages.cancelBtnText}
          icon={<Icon name="CircleTickIcon" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupStateComplete(false)
          }}
          hideCancelButton={false}
        />
      )}
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.cancelBtnText}
          isButtonTypeDanger
          icon={<Icon name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          hideCancelButton={false}
        />
      )}
    </>
  )
}
export default OrderLayout
