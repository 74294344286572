export const EP_AUTH_LOGIN = 'dealer/authenticate'
export const EP_CHANGE_PASSWORD = 'dealer/change-password'
export const EP_AUTH_GET_USERS = 'users/all'
export const EP_CUSTOMER_STATUS_BY_EMAIL = 'customer-by-email?email'
export const EP_CUSTOMER_CHECK_CREATE_BY_EMAIL = 'check-customer-by-email'
export const EP_CREATE_CUSTOMER_CONSENT = 'address'
export const EP_SAVE_CUSTOMER_CONSENT = 'consents'
export const EP_VEHICLE = 'vehicle'
export const EP_GET_VEHICLES_BY_DEALER_CODE = 'vehicle/by-dealer-code'
export const EP_GET_REFERENCE_DATA_BY_DEALER_CODE = 'inventory/reference-data-by-dealer-code'
export const EP_GET_VEHICLES_BY_FILTER = 'inventory/filter'
export const EP_GET_VEHICLES_INVENTORY_BY_DEALER_CODE = 'inventory/filter'
export const EP_VEHICLE_DETAIL = 'vehicle-detail'
export const EP_REFERRAL_INVITE = 'referral'
export const EP_SAVE_ADD_ON = 'dealer/option'
export const EP_ADD_ON_ACCESSORIES = 'dealer/option/filter'
export const EP_GET_TRIMS = 'config/asset-model-trim'
export const EP_GET_MASTER_LIST_ACCESSORIES = 'master-list-accessories'
export const EP_GET_DEALER_FEE = 'dealer/fee'
export const EP_GET_DEALER_PROFILE_BY_ID = 'dealer/profile'
export const EP_DEALER_PREFERENCE = 'dealer/preference'
export const EP_DEALER_PAYMENT = 'dealer/payment-detail'
export const EP_DEALER_PAYMENT_BY_DEALER_ID = 'dealer/payment-detail_by_dealer_id'
export const EP_DEALER_PREFERENCE_BY_DEALER_CODE = 'dealer/preference-by-dealer-code'
export const EP_DEALER_TRADEIN = 'dealer/tradein'
export const EP_DEALER_TRADEIN_BY_DEALER_ID = 'dealer/tradein-by-dealer-id'
export const EP_SAVE_DEALER_PROFILE = 'dealer/profile'
export const EP_SAVE_PEN_DEALER_ID = 'dealer/profile/pen-dealer-id'
export const EP_SAVE_ADDRESS = 'address'
export const EP_GET_STATES = 'state'
export const EP_GET_CONFIG_CONTRACT_TEMRS = 'contract-term'
export const EP_GET_CONFIG_SETUP_DATA = 'get-setup-data'
export const EP_GET_FARABI_BOT_DATA = 'affordability-bot'
export const EP_GET_FIND_VEHICLES = 'affordability-check/'
export const EP_GET_FIND_MONTHLY_PAYMENTS = 'affordability-check/monthly-payment-calculation/V2/'
export const EP_GET_CONFIG_ALLOWED_MILEAGE = 'contract-allowed-annum-mileage'
export const EP_GET_FEES = 'config/fee-name'
export const EP_GET_INVENTORY_OPTIONS_DETAIL = 'config/inventory/attached-option'
export const EP_UPDATE_VEHICLE_DETAIL = 'vehicle-detail'
export const EP_GET_FINANCIAL_INSURANCE_PRODUCTS = 'financial-insurance-products'
export const EP_GET_FINANCIAL_INSURANCE = 'financial-insurance'
export const EP_GET_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_ID = 'financial-insurances/filter'
export const EP_PEN_GET_PROVIDER = 'pen/get-providers'
export const EP_PEN_GET_PRODUCT = 'pen/get-products'
export const EP_PEN_SET_DEALER = 'pen/set-dealer'
export const EP_GET_OPTIONS = 'dealer/option-trim-code'
export const EP_DEALER_CONFIGURATIONS = 'dealer/configurations'
export const EP_GET_RATING = 'credit-ratings'
export const EP_GET_CONFIGURED_QUOTATION = 'configure/quotation'
export const EP_CONVERT_QUOTATION_TO_APPLICATION = 'configure/quotation-to-order'
export const EP_GET_QUOTATION_FEE = 'configure/quotation/fee'
export const EP_VEHICLE_BY_VIN = 'vehicle'
export const EP_VEHICLE_BY_MODEL_CODE = 'configure/assets-by-trim-code' // quoate-comparison changed to configure/assets-by-trim-code/
export const EP_CALCULATE_ORDER_QUOTATION = 'quote/calculation/V2'
export const EP_GET_WORK_QUEUE = 'configure/workqueue/search'
export const EP_SAVE_ORDER_QUOTATION = 'configure/save-quotation'
export const EP_HUBEX_GET_FNIPRODUCT_RATES = 'get-rates'
export const EP_ORDER_EXPIRY_DATE = 'order/expiry'
export const EP_ORDER_BY_ID = 'configure/order/filter'
export const EP_GET_READY_CHECKLIST = 'get_event_associated_checklist/'
export const EP_READY_CHECKLIST_EVALUATE = 'config/checklist_response_verification/'
export const EP_GET_COUNTRIES = 'countries'
export const EP_GET_INSURANCE_INFO = 'customer/insurance-information-by-customer'
export const EP_GET_CUSTOMER_LICENSE_INFO = 'customer/license-info'
export const EP_GET_CUSTOMER_DETAILS = 'customer'
export const EP_GET_LENDAR_BY_TENANT_ID = 'lender/by-tenant-id'
export const EP_GET_DEALER_AND_LENDER = 'dealer/find/by-tenant'
export const EP_ASSET_CONDITIONS = 'config/asset-conditions'
export const EP_GET_ODOMETER = 'config/odometer'
export const EP_GET_REASONS_BY_LENDER_ID = 'config/reason'
export const EP_GET_CUSTOMER_NAMES = 'customers'
export const EP_DOWNLOAD_DOCUMENT = 'customer/document/download'
export const EP_UPLOAD_DOCUMENT = 'customer/document'
export const EP_UPLOAD_DOCUMENT_by_CUSTOMER_REFERENCE = 'customer/documents'
export const EP_FRAUD_CHECK = 'customer/fraud-check'
export const EP_DOCUMENT_PACKAGE_BY_REFERENCE_ID = `configure/document-package-by-reference-id`
export const EP_GET_SUBMITTED_ORDER_INFORMATION = 'order/order_information_for_submitted_order'
export const EP_GET_SAVED_CHECKLIST_OPTIONS = 'order/order-checklist'
export const EP_SAVE_CHECKLIST_PROGRESS = 'order/order-checklist'
export const EP_GET_AD_USER = 'user/ad-user'
export const UPDATE_ORDER_STATUS = 'configure/order/update-status'
export const EP_GET_PROGRAMS = 'programs/all'
export const EP_SAVE_CONTACT_DETAILS = 'customer/'
export const EP_SAVE_EMPLOYMENT_DETAILS = 'customer/employment-info'
export const LICENSE_DETAILS = 'customer/license-info'
export const EP_GET_INTEGRATION_BY_PROVIDER_NAME = 'integration/by_provider_name'
export const LICENSE_VERIFICATION_MITEK = '/mitek/verify-license'
export const ADD_FINANCE_DETAILS = 'customer/personal-finance'
export const EP_GET_EMPLOYMENT_INFO = 'customer/employment-info'
export const EP_UPDATE_EMPLOYMENT_DETAILS = 'customer/employment-info'
export const EP_FETCH_INSURANCE_DETAILS = 'customer/insurance-information'
export const LICENSE_VERIFICATION = 'customer/license-verification'
export const EP_GET_PREFIX = 'config/prefix'
export const EP_GET_CUSTOMER = 'customer'
export const EP_CANCEL_ORDER = 'order/update-credit-approver-decision/'
export const EP_CREDIT_REQUEST = 'credit-request'
export const EP_GET_ORDER_INFORMATION = 'order/order_information'
export const EP_GET_CURRENCIES = 'currencies'
export const EP_UPDATE_PAYMENT_STATUS = 'order/update-payment-status'
export const EP_GET_ALL_ORDERS = 'all-quotation-by-reference-number'
export const EP_GET_LENDER_INTEGRATIONS = 'integration/lender'
export const EP_UPDATE_CONTACT_DETAILS = 'customer'
export const EP_FINANCIAL_STIPULATION = 'stipulation/financial'
export const EP_Appointment = 'configure/appointment'
export const EP_UPDATE_SUBSCRIPTION = 'return/proceed-request'
export const EP_UPDATE_RETURN = 'return/update-request'
export const EP_GET_RETURNS = 'return/get-requests'
export const GET_USER_PROFILE = 'ums/user-profile'
export const GET_VEHICLE_DETAILS = 'vehicle'
export const EP_UPLOAD_DOCUMENT_BY_REFERENCE_NUMBER = 'order/order_information'
export const EP_UPLOAD_FILE = 'order/upload-signed-document'
export const EP_VERIFY_DOCUMENT = 'order/order-verify-document'
export const EP_STIPULATION = 'stipulation/stipulation-by-lender'
export const EP_CREDIT_RESPONSE = 'order/credit-decision'
export const EP_GET_CONTRACT = 'contract'
export const EP_GET_ALL_QUOTATIONS = 'configure/all-quotation'
export const EP_GET_FP_BY_ID = 'config/financial-product'
export const EP_CUSTOMER_SIGNATURE = 'customer/signature'
export const EP_CUSTOMER_DOCUMENT_SIGNATURE = 'customer/document/signature-document'
export const EP_CUSTOMER_SEARCH = 'customer/all-customers-dynamic-search'
export const EP_GET_ORDER_BY_CUSTOMER = 'configure/quotation-by-customers'
export const EP_GET_CONTRACTS = 'contracts'
export const EP_GET_CONTRACTS_SEARCH = 'contracts/search'
export const EP_GET_ACTIVE_ASSET_USAGE = 'asset-usage/active'
export const EP_AN_DEALERS = 'an-dealers'
export const EP_UPDATE_ORDER_TO_QUOTATION = 'configure/order-to-quotation'
export const EP_EXTERNAL_KEYS = 'external-keys/all'
export const EP_DYNAMIC_ORDERS_DATA = 'customer/all-customers-orders-dynamic-search'
export const EP_SUBMIT_ORDER = 'submit-order'
