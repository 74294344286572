import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { DetailCard } from 'components'
import { Icon, Typography, Grid, Box } from '@ntpkunity/controls'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Status } from '@helpers/enums'
import { useGetAllowedMileage } from '@apis/configurations.service'

const PaymentBreakdown: FC<{ order_info: any }> = ({ order_info }) => {
  const [openEpp, setOpenEpp] = useState(false)
  const [openOption, setOpenOption] = useState(false)
  const [openFees, setOpenFees] = useState(false)
  const [openFni, setOpenFni] = useState(false)
  const theme = useTheme()
  const sortedOptionsArray = order_info?.order_options?.sort((a: any, b: any) =>
    a.installation_mode.localeCompare(b.installation_mode)
  )
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const { mutate: getAllowedMileage, data: allowedMileageData } = useGetAllowedMileage()

  useEffect(() => {
    getAllowedMileage({ lender_id: states?.dealerAndLenderInfo?.lender_id })
  }, [states?.dealerAndLenderInfo?.lender_id])
  let tradeInEquity: any =
    (order_info?.order_tradein?.trade_in_amount && order_info?.order_tradein?.trade_in_amount) -
    (order_info?.order_tradein?.payoff_amount && order_info?.order_tradein?.payoff_amount)
  if (tradeInEquity && tradeInEquity > 0) {
    tradeInEquity =
      defaultCurrency +
      Math.abs(tradeInEquity).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })
  } else if (tradeInEquity < 0) {
    tradeInEquity =
      `-${defaultCurrency}` +
      Math.abs(tradeInEquity).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })
  }

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  return (
    <Grid theme={theme} item xl={4} lg={4} md={4} sm={12} xs={12}>
      <DetailCard mb={3}>
        <Box theme={theme} className="card-header">
          <Typography theme={theme} component="h3" variant="h3">
            {order_info?.contract_term}-Months {order_info?.finance_type}
          </Typography>
          <Typography theme={theme} className="text" component="span" variant="body2">
            Payment Breakdown
          </Typography>
        </Box>
        <Box theme={theme} className="line">
          <hr />
        </Box>
        <Box theme={theme} className="card-body">
          <Box theme={theme} className="summary-row toggle" sx={{ mb: 2 }}>
            <Box theme={theme} className="row">
              <Box theme={theme} className="center">
                <span className="card-text">
                  {order_info?.status == Status.SchedulePickup ||
                  order_info?.status == Status.Appointment ||
                  order_info?.status == Status.Completed ? (
                    <b>Periodic Payment</b>
                  ) : (
                    <b>Estimated Periodic Payment</b>
                  )}
                </span>
                {openEpp ? (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn ch-up"
                    onClick={() => setOpenEpp(!openEpp)}
                  />
                ) : (
                  <Icon
                    name="ChevronDown"
                    className="toggle-btn"
                    onClick={() => setOpenEpp(!openEpp)}
                  />
                )}
              </Box>
              <Typography theme={theme} className="card-text" component="span" variant="body2">
                <b>
                  {order_info?.estimated_monthly_payment &&
                  order_info?.estimated_monthly_payment > 0
                    ? defaultCurrency +
                      order_info?.estimated_monthly_payment?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    : '-'}
                </b>
              </Typography>
            </Box>
          </Box>
          <Accordion expanded={openEpp}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
            <AccordionDetails sx={{ ml: 2 }}>
              <Box theme={theme} className="row" sx={{ mb: 2 }}>
                <Typography
                  theme={theme}
                  className="card-text text"
                  component="span"
                  variant="body2"
                >
                  Net Periodic Payment
                </Typography>
                <Typography
                  theme={theme}
                  className="card-text text"
                  component="span"
                  variant="body2"
                >
                  {order_info?.estimated_monthly_payment &&
                  order_info?.estimated_monthly_payment > 0
                    ? defaultCurrency +
                      order_info?.estimated_monthly_payment?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    : '-'}
                </Typography>
              </Box>
              <Box theme={theme} className="row">
                <Typography
                  theme={theme}
                  className="card-text text"
                  component="span"
                  variant="body2"
                >
                  Tax Amount
                </Typography>
                <Typography
                  theme={theme}
                  className="card-text text"
                  component="span"
                  variant="body2"
                >
                  -
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box theme={theme} className="row" sx={{ mb: 2 }}>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              <b>Due at Signing</b>
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.due_at_signing && order_info?.due_at_signing > 0
                ? defaultCurrency +
                  order_info?.due_at_signing?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
            </Typography>
          </Box>
          <Box theme={theme} className="row" sx={{ mb: 2 }}>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              Terms
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.contract_term}-Months
            </Typography>
          </Box>
          <Box theme={theme} className="row" sx={{ mb: 2 }}>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              Rate
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.apr && order_info?.apr?.toFixed(4).toLocaleString()}%
            </Typography>
          </Box>
          {order_info?.finance_type == 'Lease' ? (
            <Box theme={theme} className="row" sx={{ mb: 2 }}>
              <Typography theme={theme} className="card-text" component="span" variant="body2">
                Annual Mileage
              </Typography>
              <Typography theme={theme} className="card-text" component="span" variant="body2">
                {allowedMileageData && order_info?.annual_usage && order_info?.annual_usage > 0 ? (
                  <>
                    {allowedMileageData
                      .filter((mileage: any) => mileage.id == order_info?.annual_usage)?.[0]
                      ?.value?.toLocaleString()}{' '}
                    <span className="symbol">{states?.lenderInfo?.mileage_unit}</span>
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </Box>
          ) : (
            ''
          )}
          <Box theme={theme} className="line">
            <hr />
          </Box>
          <Box theme={theme} className="row" sx={{ mb: 2 }}>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              Selling Price
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.selling_price && order_info?.selling_price > 0
                ? defaultCurrency +
                  order_info?.selling_price?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
            </Typography>
          </Box>
          <Box theme={theme} className="row">
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              Down Payment
            </Typography>
            <Typography theme={theme} className="card-text" component="span" variant="body2">
              {order_info?.down_payment && order_info?.down_payment > 0
                ? defaultCurrency +
                  ((order_info?.down_payment * order_info?.selling_price) / 100).toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }
                  )
                : '-'}
            </Typography>
          </Box>
          {order_info?.order_options && order_info?.order_options?.length > 0 ? (
            <>
              <Box theme={theme} className="summary-row toggle" sx={{ mt: 2 }}>
                <Box theme={theme} className="row">
                  <Box theme={theme} className="center">
                    <span className="card-text">
                      <b>Options</b>
                    </span>

                    {openOption ? (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn ch-up"
                        onClick={() => setOpenOption(!openOption)}
                      />
                    ) : (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn"
                        onClick={() => setOpenOption(!openOption)}
                      />
                    )}
                  </Box>
                  <Typography theme={theme} className="card-text" component="span" variant="body2">
                    <b>
                      {defaultCurrency}
                      {order_info?.order_options
                        ?.reduce((sum: any, current: any) => sum + current.applied_price, 0)
                        ?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion expanded={openOption}>
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                ></AccordionSummary>
                <AccordionDetails sx={{ ml: 2 }}>
                  {sortedOptionsArray?.length > 0 &&
                    sortedOptionsArray?.map((option: any, index: any) => (
                      <Box theme={theme} sx={{ pl: 3 }} key={index}>
                        <>
                          {sortedOptionsArray[index - 1]?.installation_mode !==
                            option?.installation_mode && (
                            <Box theme={theme} className="package-row  text">
                              <Typography
                                theme={theme}
                                className="card-text text"
                                component="span"
                                variant="body2"
                              >
                                <b>{option?.installation_mode}</b>
                              </Typography>
                            </Box>
                          )}
                          <Box theme={theme} className="row">
                            <Typography
                              theme={theme}
                              className="card-text text"
                              component="span"
                              variant="body2"
                            >
                              {option?.product_name}
                            </Typography>
                            <Typography
                              theme={theme}
                              className="card-text text"
                              component="span"
                              variant="body2"
                            >
                              {option?.applied_price === 0.0 ? (
                                '-'
                              ) : (
                                <span>
                                  {defaultCurrency}
                                  {option?.applied_price?.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  })}
                                </span>
                              )}
                            </Typography>
                          </Box>
                        </>
                      </Box>
                    ))}
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            ''
          )}
          {order_info?.order_fees && order_info?.order_fees.length > 0 ? (
            <>
              <Box theme={theme} className="summary-row toggle" sx={{ mt: 2 }}>
                <Box theme={theme} className="row">
                  <Box theme={theme} className="center">
                    <span className="card-text">
                      <b>Fees</b>
                    </span>

                    {openFees ? (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn ch-up"
                        onClick={() => setOpenFees(!openFees)}
                      />
                    ) : (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn"
                        onClick={() => setOpenFees(!openFees)}
                      />
                    )}
                  </Box>
                  <Typography
                    theme={theme}
                    className="card-text text"
                    component="span"
                    variant="body2"
                  >
                    <b>
                      {defaultCurrency}
                      {order_info?.order_fees
                        ?.reduce((sum: any, current: any) => sum + current.applied_price, 0)
                        ?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion expanded={openFees}>
                <AccordionSummary
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                ></AccordionSummary>

                <AccordionDetails sx={{ ml: 2 }}>
                  {order_info?.order_fees?.map((feeData: any, index: number) => (
                    <Box theme={theme} className="row" key={index}>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {feeData?.fee_name}
                      </Typography>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {feeData?.applied_price && feeData?.applied_price > 0
                          ? defaultCurrency +
                            feeData?.applied_price?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })
                          : '-'}
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            ''
          )}
          {order_info?.order_fnI && order_info?.order_fnI.length > 0 ? (
            <>
              <Box theme={theme} className="summary-row toggle" sx={{ mt: 2 }}>
                <Box theme={theme} className="row">
                  <Box theme={theme} className="center">
                    <span className="card-text">
                      <b>F&I Products</b>
                    </span>

                    {openFni ? (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn ch-up"
                        onClick={() => setOpenFni(!openFni)}
                      />
                    ) : (
                      <Icon
                        name="ChevronDown"
                        className="toggle-btn"
                        onClick={() => setOpenFni(!openFni)}
                      />
                    )}
                  </Box>
                  <Typography theme={theme} className="card-text" component="span" variant="body2">
                    <b>
                      {defaultCurrency}
                      {order_info?.order_fnI
                        ?.reduce((sum: any, current: any) => sum + current.applied_price, 0)
                        ?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion expanded={openFni}>
                <AccordionSummary
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                ></AccordionSummary>

                <AccordionDetails sx={{ ml: 2 }}>
                  {order_info?.order_fnI?.map((fniData: any, index: number) => (
                    <Box theme={theme} className="row" key={index}>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {fniData?.product_name}
                      </Typography>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {fniData?.applied_price === 0.0 ? (
                          '-'
                        ) : (
                          <span>
                            {defaultCurrency}
                            {fniData?.applied_price?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}
                          </span>
                        )}
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            ''
          )}
          {order_info?.order_tradein ? (
            <>
              <Box theme={theme} className="summary-row toggle" sx={{ mt: 2 }}>
                <Box theme={theme} className="row">
                  <Box theme={theme} className="center">
                    <span className="card-text">Trade-In Equity/Inequity</span>
                  </Box>
                  <Typography theme={theme} className="card-text" component="span" variant="body2">
                    <b>{tradeInEquity}</b>
                  </Typography>
                </Box>
              </Box>
              <Accordion>
                <AccordionSummary
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                ></AccordionSummary>

                <AccordionDetails sx={{ ml: 2 }}></AccordionDetails>
              </Accordion>
            </>
          ) : (
            ''
          )}

          <Box theme={theme} className="summary-row toggle" sx={{ mt: 2 }}>
            <Box theme={theme} className="row">
              <Box theme={theme} className="center">
                <span className="card-text">Taxes Capitalization</span>
              </Box>
              <Typography theme={theme} className="card-text" component="span" variant="body2">
                <b>-</b>
              </Typography>
            </Box>
          </Box>
          <Accordion>
            <AccordionSummary
              aria-controls="panel4a-content"
              id="panel4a-header"
            ></AccordionSummary>

            <AccordionDetails sx={{ ml: 2 }}></AccordionDetails>
          </Accordion>

          <Box theme={theme} className="summary-row toggle" sx={{ mt: 2 }}>
            <Box theme={theme} className="row">
              <Box theme={theme} className="center">
                <span className="card-text">Financed Amount</span>
              </Box>
              <Typography theme={theme} className="card-text" component="span" variant="body2">
                {order_info?.finance_amount && order_info?.finance_amount > 0
                  ? defaultCurrency +
                    order_info?.finance_amount?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  : '-'}
              </Typography>
            </Box>
          </Box>
          <Accordion>
            <AccordionSummary
              aria-controls="panel4a-content"
              id="panel4a-header"
            ></AccordionSummary>

            <AccordionDetails sx={{ ml: 2 }}></AccordionDetails>
          </Accordion>
        </Box>
      </DetailCard>
    </Grid>
  )
}

export default PaymentBreakdown
