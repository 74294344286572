import {
  IMitekInterface,
  IEmploymentDetails,
  IPersonalFinance,
  IContactDetails,
  ICustomerLicenseData
} from '../models/customer-details.interface'

import Http from '../helpers/http-api'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  ADD_FINANCE_DETAILS,
  EP_SAVE_CONTACT_DETAILS,
  EP_GET_INTEGRATION_BY_PROVIDER_NAME,
  LICENSE_DETAILS,
  EP_SAVE_EMPLOYMENT_DETAILS,
  EP_UPDATE_EMPLOYMENT_DETAILS,
  EP_GET_EMPLOYMENT_INFO,
  EP_GET_STATES,
  EP_GET_CUSTOMER_LICENSE_INFO,
  LICENSE_VERIFICATION,
  EP_CUSTOMER_SIGNATURE,
  EP_CUSTOMER_DOCUMENT_SIGNATURE
} from '../helpers/endpoints'

import {
  EP_GET_PREFIX,
  EP_GET_LENDER_INTEGRATIONS,
  EP_GET_CUSTOMER,
  EP_UPDATE_CONTACT_DETAILS
} from '@helpers/endpoints'
import { StoreContext, useStoreContext } from '../store/storeContext'
import { getMarketPlaceToken } from '@helpers/methods'
import { useContext } from 'react'
import { QueryKeys } from '@helpers/enums'
const token = getMarketPlaceToken()
const headers: any = {
  Authorization: `Bearer ${token}`
}

export const useSaveCustomerContactDetails = (): any => {
  const { data, isLoading, mutate } = useMutation<IContactDetails, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IContactDetails>(`${EP_SAVE_CONTACT_DETAILS}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useAddLicenseDetails = (): any => {
  const queryClient = useQueryClient()

  const { data, mutate, error } = useMutation<ICustomerLicenseData, any>(
    async (body) => {
      const apiService = Http.createConnection({})
      return await apiService.post<any>(LICENSE_DETAILS, body)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_LICENSE_DETAILS)
      }
    }
  )
  return { data, mutate, error: error?.message }
}
export const useUpdateCustomerLicenseInfo = () => {
  const queryClient = useQueryClient()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const { reference_id, ...restPayload } = body
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_GET_CUSTOMER_LICENSE_INFO}?reference_id=${reference_id}&is_presigned_url_required=true`,
        restPayload
      )
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_LICENSE_DETAILS)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetStates = (): any => {
  const { data, error } = useQuery(
    EP_GET_STATES,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return await apiService.get<any>(EP_GET_STATES)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}

const getCustomerContact = (reference_id: string) => {
  const apiService = Http.createConnection()
  return apiService.get<any>(
    EP_GET_CUSTOMER + `?reference_id=${encodeURIComponent(reference_id)}`,
    {}
  )
}

export const UseQuery_Get_CustomerContact = (reference_id: string) => {
  return useQuery(
    [QueryKeys.GET_CUSTOMER_CONTACT_DETAILS, reference_id],
    async () => {
      return getCustomerContact(reference_id)
    },
    { keepPreviousData: true }
  )
}

export const useUpdateCustomerContact = () => {
  const queryClient = useQueryClient()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const { reference_id, ...restPayload } = body
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_UPDATE_CONTACT_DETAILS}?reference_id=${reference_id}`,
        restPayload
      )
    },
    {
      onSuccess: async (_responce: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_CONTACT_DETAILS)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetPreFix = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get<any>(EP_GET_PREFIX)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useSaveEmploymentDetails = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<IEmploymentDetails, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IEmploymentDetails>(`${EP_SAVE_EMPLOYMENT_DETAILS}`, body)
    },
    {
      onSuccess: async (_responce: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_EMPLOYMENT_DETAILS)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateEmployInfo = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<IEmploymentDetails, any>(
    (body: any) => {
      const { reference_id, ...restPayload } = body
      const apiService = Http.createConnection()
      return apiService.patch<IEmploymentDetails>(
        `${EP_UPDATE_EMPLOYMENT_DETAILS}?reference_id=${reference_id}`,
        restPayload
      )
    },
    {
      onSuccess: async (_responce: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_EMPLOYMENT_DETAILS)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useAddPersonalFinance = (): any => {
  const queryClient = useQueryClient()
  const { data, mutate, error } = useMutation<IPersonalFinance, any>(
    async (body) => {
      const apiService = Http.createConnection({})
      return await apiService.post<any>(ADD_FINANCE_DETAILS, body)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_PERSONAL_FINANCE)
      }
    }
  )
  return { data, mutate, error: error?.message }
}

const getPersonalFinanceInfo = (reference_id: string) => {
  const apiService = Http.createConnection()
  return apiService.get<any>(
    ADD_FINANCE_DETAILS + `?reference_id=${encodeURIComponent(reference_id)}`,
    {}
  )
}

export const UseQuery_Get_Personal_Finance = (reference_id: string) => {
  return useQuery(
    [QueryKeys.GET_PERSONAL_FINANCE, reference_id],
    async () => {
      return getPersonalFinanceInfo(reference_id)
    },
    { enabled: !!reference_id, keepPreviousData: true }
  )
}

const getLicenseInfo = (reference_id: string) => {
  const apiService = Http.createConnection()
  return apiService.get<any>(
    EP_GET_CUSTOMER_LICENSE_INFO +
      `?reference_id=${encodeURIComponent(reference_id)}&is_presigned_url_required=true`,
    {}
  )
}

export const UseQuery_Get_License_Information = (reference_id: string) => {
  return useQuery(
    [QueryKeys.GET_LICENSE_DETAILS, reference_id],
    async () => {
      return getLicenseInfo(reference_id)
    },
    { enabled: !!reference_id, keepPreviousData: true }
  )
}
export const useUpdatePeronalFinanceInfo = () => {
  const queryClient = useQueryClient()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const { reference_id, ...restPayload } = body
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${ADD_FINANCE_DETAILS}?reference_id=${reference_id}`,
        restPayload
      )
    },
    {
      onSuccess: async (_response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_PERSONAL_FINANCE)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetIntegrationByProviderName = (provider_name: string): any => {
  const url = EP_GET_INTEGRATION_BY_PROVIDER_NAME
  const { data, isLoading, error, mutate } = useMutation(() => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    return apiService.get<any>(`${url}/${provider_name}`)
  })
  return { data, isLoading, error, mutate }
}

export const useAddLicenseDetailsToMitek = (): any => {
  const { actions } = useContext(StoreContext)

  const { data, mutate, error } = useMutation<IMitekInterface, any>(
    async (body) => {
      const apiService = Http.createConnection()
      return await apiService.post<any>(LICENSE_VERIFICATION, body)
    },
    {
      onSuccess(response: any) {
        if (response.mitek_verification_status === 'Manual Review') {
          actions.setToast({
            toastMessage: 'Please wait. License review is in progress.',
            toastState: true
          })
        }
      }
    }
  )
  return { data, mutate, error: error?.message }
}

const getGetEmployInfo = (reference_id: string) => {
  const apiService = Http.createConnection()
  return apiService.get<any>(
    EP_GET_EMPLOYMENT_INFO + `?reference_id=${encodeURIComponent(reference_id)}`,
    {}
  )
}

export const UseQuery_Get_CustomerEmployment = (reference_id: string) => {
  return useQuery(
    [QueryKeys.GET_CUSTOMER_EMPLOYMENT_DETAILS, reference_id],
    async () => {
      return getGetEmployInfo(reference_id)
    },
    { keepPreviousData: true }
  )
}

export const useGetLenderIntegrations = (): any => {
  const { states } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      headers.lender_id = states?.dealerAndLenderInfo?.lender_id
      return apiService.get<any>(EP_GET_LENDER_INTEGRATIONS, {}, { headers })
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetCustomerSignatures = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(`${EP_CUSTOMER_SIGNATURE}?reference_id=${body.reference_id}`, {})
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveCustomerSignatures = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.post<any>(`${EP_CUSTOMER_SIGNATURE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveCustomerDocumentSignature = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.post<any>(`${EP_CUSTOMER_DOCUMENT_SIGNATURE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
