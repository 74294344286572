import { styled } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import { CustomColors, lightTheme } from '@styles/theme'

export const RescheduleAppointmentWrap = styled(Box)(({ theme }) => ({
  '&.dialog': {
    '.choose-time': {
      '.time': {
        backgroundColor: theme.customVariables.tableStripedBg,
        color: theme.palette.grey[900],
        borderRadius: theme.shape.borderRadius,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.body1.fontWeight,
        padding: '12px 12px',
        width: '100%',
        '&:hover': {
          backgroundColor: theme.customVariables.tableStripedBg
        },
        '&:focus': {
          backgroundColor: theme.customVariables.tableStripedBg
        },
        '&.selected': {
          backgroundColor: theme.palette.grey[700],
          color: theme.palette.common.white
        },
        '&.booked': {
          textDecoration: 'line-through',
          color: theme.palette.text.disabled,
          pointerEvents: 'none'
        }
      }
    },
    '&.date-time-picker': {
      '.MuiFormControl-root': {
        width: '100%',
        '.MuiFormLabel-root, .MuiFormLabel-root.Mui-focused': {
          color: CustomColors.inputLableTextColor
        },
        '.MuiInputBase-root': {
          fontFamily: lightTheme.typography.fontFamily,
          color: lightTheme.palette.grey[900],
          '.MuiInputBase-root .MuiInputBase-input::placeholder': {
            color: CustomColors.inputPlaceholder
          }
        }
      }
    },
    '.address-layout': {
      '.MuiGrid-root.MuiGrid-container': {
        '.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.MuiGrid-grid-md-3.MuiGrid-grid-lg-3':
          {
            [theme.breakpoints.up('xl')]: {
              maxWidth: '50%',
              flexBasis: '50%'
            },
            [theme.breakpoints.down('xl')]: {
              maxWidth: '50%',
              flexBasis: '50%'
            },
            [theme.breakpoints.down('lg')]: {
              maxWidth: '50%',
              flexBasis: '50%'
            },
            [theme.breakpoints.down('md')]: {
              maxWidth: '100%',
              flexBasis: '100%'
            },
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
              flexBasis: '100%'
            },
            [theme.breakpoints.down('xs')]: {
              maxWidth: '100%',
              flexBasis: '100%'
            }
          }
      }
    }
  }
}))
