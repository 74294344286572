import { FC, useEffect, useState } from 'react'
import { Divider, useTheme } from '@mui/material'
import {
  Typography,
  FileDragDrop,
  FilePreview,
  DatePicker,
  Box,
  Grid,
  Input
} from '@ntpkunity/controls'
import { Controller } from 'react-hook-form'
import { IOrderInsurance } from '@models'
import AddressDetailsComponent from '@app/customer-details/address-detail'

const InsurancePopup: FC<{
  InsuranceForm?: any
  formData?: IOrderInsurance
}> = ({ InsuranceForm }) => {
  const theme = useTheme()

  const {
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = InsuranceForm
  const image = watch('image')
  const [imageFiles, setImageFiles] = useState<any[]>([])
  const onimageUpload = (e: any) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      addFilesForPreview(e.target.files)
      clearErrors('insurance_documents')
    }
  }
  const onimageDrop = (e: any) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      addFilesForPreview(e.dataTransfer.files)
      clearErrors('insurance_documents')
    }
  }
  const addFilesForPreview = async (files: any) => {
    const newImagesArray: any[] = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      try {
        const result = await readFileAsDataURL(file)
        newImagesArray.push({
          document: result,
          content_type: file.type,
          name: file.name,
          file_size: `${(file.size / 1024).toFixed(2)}KB`,
          created_by: 'null'
        })

        if (i === files.length - 1) {
          setValue('insurance_documents', [...(image || []), ...newImagesArray])
          setImageFiles([...imageFiles, ...newImagesArray])
        }
      } catch (error) {
        console.error('Error reading file:', error)
      }
    }
  }

  const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()

      fileReader.onload = (event) => {
        if (event.target && event.target.result) {
          resolve(event.target.result.toString())
        } else {
          reject('Failed to read file as data URL.')
        }
      }

      fileReader.onerror = () => {
        reject('Error reading file as data URL.')
      }

      fileReader.readAsDataURL(file)
    })
  }

  const onRemoveFile = (e: any, _index: number) => {
    e.preventDefault()
    const newImagesArray = imageFiles.filter((_obj: any, index: number) => index !== _index)
    setImageFiles(newImagesArray)
    const formImages = InsuranceForm.getValues('insurance_documents').filter(
      (_obj: any, index: number) => index !== _index
    )
    setValue('insurance_documents', formImages)
  }
  const base64ToFile = (base64Strings: any[]): File[] => {
    return base64Strings
      ?.map((base64String, index) => {
        const matches = base64String?.document?.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/)
        if (!matches || matches.length !== 3) {
          console.error(`Invalid Base64 string format for element at index ${index}.`)
          return null
        }
        const mime = matches[1]
        const bstr = matches[2]
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], base64String.name || `file_${index + 1}`, {
          type: mime
        })
      })
      .filter((file) => file !== null) as File[]
  }
  useEffect(() => {
    if (InsuranceForm?.getValues('insurance_documents')?.length > 0) {
      const filesArray = base64ToFile(InsuranceForm?.getValues('insurance_documents'))
      setImageFiles(filesArray)
    }
  }, [InsuranceForm?.getValues('insurance_documents')])
  return (
    <>
      <Grid theme={theme} container rowSpacing={3} columnSpacing={3}>
        <Grid item theme={theme} xs={12} sm={6}>
          <Controller
            name="insurer_name"
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                label="Insurance Provider Name"
                placeholder="Add here . . "
                type="text"
                {...field}
                error={errors?.insurer_name?.message}
              ></Input>
            )}
          />
        </Grid>
        <Grid item theme={theme} xs={12} sm={6}>
          <Controller
            name="agent_name"
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                label="Insurance Agent Name"
                placeholder="Add here . . "
                type="text"
                {...field}
                error={errors?.agent_name?.message}
              ></Input>
            )}
          />
        </Grid>
        <Grid item theme={theme} xs={12} sm={6}>
          <Controller
            name="policy_number"
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                label="Policy Number"
                placeholder="Add here . . "
                type="text"
                {...field}
                error={errors?.policy_number?.message}
              ></Input>
            )}
          />
        </Grid>
        <Grid item theme={theme} xs={12} sm={6}>
          <Controller
            name="expiration_date"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <DatePicker
                label="Expiration"
                disablePast
                disablePortal
                theme={theme}
                value={value ?? ''}
                onChange={(e: any) => {
                  if (
                    e instanceof Date &&
                    e.getFullYear() === 1970 &&
                    e.getMonth() === 0 &&
                    e.getDate() === 1
                  ) {
                    onChange(null)
                  } else {
                    onChange(e.toISOString())
                  }
                }}
                error={errors.expiration_date?.message}
              />
            )}
          />
        </Grid>
        <Grid item theme={theme} xs={12}>
          <Divider />
        </Grid>
        <Grid item theme={theme} xs={12}>
          <Typography
            theme={theme}
            variant="subtitle1"
            component="h5"
            children="Insurance Provider Address"
          />
        </Grid>
        <Grid item theme={theme} xs={12}>
          <AddressDetailsComponent
            form={InsuranceForm}
            googleApiKey={null}
            addressValue={'insurer_address'}
            moveIn={false}
            errorProps={errors.insurer_address}
            control={control}
            contactError={errors?.agent_phone?.message}
            showContactNumber
          />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 3, mt: 3 }} />
      <Box theme={theme} sx={{ mb: 1 }}>
        <Typography
          theme={theme}
          variant="subtitle1"
          component="h5"
          children="Proof of Insurance"
        />
        <Typography
          theme={theme}
          variant="caption"
          component="span"
          children="Supported formats: JPG, JPEG, PNG."
        />
      </Box>
      <Controller
        name="insurance_documents"
        control={control}
        render={({ field }) => (
          <FileDragDrop
            {...field}
            theme={theme}
            width="300"
            height="200px"
            backgroundColor="white"
            onDrop={onimageDrop}
            onChange={onimageUpload}
            allowMultiple={true}
            error={errors?.insurance_documents?.message}
          />
        )}
      />
      {imageFiles && imageFiles.length > 0 && imageFiles[0] != null && (
        <FilePreview
          files={imageFiles.length > 0 ? imageFiles : []}
          onRemoveFile={onRemoveFile}
          theme={theme}
        />
      )}
      <Divider sx={{ mt: 4 }} />
    </>
  )
}
export default InsurancePopup
