import { FC } from 'react'
import { useTheme } from '@mui/material'

import { LayoutWithSideNav } from '@styles/layout'
import { DashboardStyle } from './dashboard.style'
import { Box, Grid, Typography } from '@ntpkunity/controls'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
} from 'chart.js'

const CustomerJourneyAnalytics: FC = () => {
  const theme = useTheme()
  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Title,
    Legend
  )

  return (
    <LayoutWithSideNav theme={theme}>
      <DashboardStyle theme={theme} className="content-wrap">
        <Typography theme={theme} component="h2" variant="h2" sx={{ mt: 3 }}>
          Customer Journey Analytics
        </Typography>
        <Box theme={theme} sx={{ opacity: 0.3, mt: 3, mb: 3 }}>
          <hr />
        </Box>
        <Grid theme={theme} container spacing={3}>
          <Grid theme={theme} item lg={12} md={12} sm={12}>
            <iframe
              width="100%"
              height="2480"
              title="dashboard"
              style={{ border: '1px solid #EBECF2', borderRadius: '8px' }}
              src="https://lookerstudio.google.com/embed/reporting/3b0f9d66-4bbf-4316-82e0-c42d3388170f/page/a0jcD"
            ></iframe>
          </Grid>
        </Grid>
      </DashboardStyle>
    </LayoutWithSideNav>
  )
}

export default CustomerJourneyAnalytics
