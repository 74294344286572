import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Typography, Grid, Checkbox } from '@ntpkunity/controls'
import { DetailCard } from 'components'
import { Status } from '@helpers/enums'

const PaymentDetails: FC<{
  paymentIndicator: boolean
  orderStatus: string
  setPaymentIndicator?: any
  setTrigger?: any
}> = ({ paymentIndicator, orderStatus, setPaymentIndicator, setTrigger }) => {
  const theme = useTheme()
  const onCheckChange = (e: any) => {
    setPaymentIndicator(e.target.checked)
    setTrigger(true)
  }
  return (
    <DetailCard mb={2}>
      <Typography component="h4" variant="h4" mb={2} theme={theme}>
        Payment Details
      </Typography>
      <Grid className="container" theme={theme} container>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12} theme={theme}>
          <Typography variant="body2" component="h3" className="label" theme={theme}>
            Status
          </Typography>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={12} theme={theme}>
          {orderStatus == Status.Appointment ||
          orderStatus == Status.SchedulePickup ||
          orderStatus == Status.Completed ? (
            <Typography variant="body2" component="h3" className="label" theme={theme}>
              Payment Received
            </Typography>
          ) : (
            <Box className="checkbox-cell fixed-cell" theme={theme}>
              <Checkbox
                label="Payment Received"
                theme={theme}
                checkBoxChecked={paymentIndicator ? true : false}
                onChange={onCheckChange}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </DetailCard>
  )
}

export default PaymentDetails
