import '@styles/theme-builder.css'
import { FC, useEffect, useState, memo } from 'react'
import { useTheme } from '@mui/material'
import { Box, PageHeader } from 'components'
import { Typography, Icon } from '@ntpkunity/controls'
import OrdersListUi from '@app/order-management/order-view/orders-list'
import { useGetCompletedOrdersRequest } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { IDealerWorkQueueOrderList } from '@models'
import { CustomerColumns, SortOrder, SortOrderFields, vehcileStatuses } from '@helpers/constants'
import { addConditionToRequestBody } from '@helpers/methods'

const CancelledCompletedOrders: FC<IDealerWorkQueueOrderList> = ({ heading, subheading }) => {
  const theme = useTheme()
  const { actions, states } = useStoreContext()
  const [showAccordionDetail, setShowAccordionDetail] = useState(false)
  const { mutate: getOrders, isLoading: completedOrdersLoading } = useGetCompletedOrdersRequest()

  useEffect(() => {
    if (showAccordionDetail === true) {
      getCompletedAndCancelledOrders()
    }
  }, [showAccordionDetail])

  const getCompletedAndCancelledOrders = () => {
    let params = `page_size=25&page_number=0&sort=${SortOrder.desc}&sort_by=${SortOrderFields.order_updated_at}`
    let requestBody: any[] = []
    // if (Object.keys(states?.selectedANDealer).length > 0 && states?.selectedANDealer?.id !== '') {
    //   params += `&hyperionIds=${states?.selectedANDealer?.id}`
    // }
    const checkCondition =
      Object.keys(states?.selectedANDealer)?.length > 0 && states?.selectedANDealer?.id !== ''

    if (checkCondition) {
      addConditionToRequestBody(
        CustomerColumns.DEALER_ID,
        states?.selectedANDealer?.id,
        'Equals',
        requestBody
      )
    }
    // Concatenate heading value against each param
    if (heading === 'Completed') {
      addConditionToRequestBody(
        CustomerColumns.Order_Status,
        vehcileStatuses?.COMPLETED,
        'Equals',
        requestBody
      )
      getOrders(
        { params, requestBody },
        {
          onSuccess(response: any) {
            actions.setDealerCompletedOrders(response)
          }
        }
      )
    } else if (heading === 'Cancelled') {
      addConditionToRequestBody(
        CustomerColumns.Order_Status,
        vehcileStatuses?.CANCELED,
        'Equals',
        requestBody
      )

      getOrders(
        { params, requestBody },
        {
          onSuccess(response: any) {
            actions.setDealerCancelledOrders(response)
          }
        }
      )
    } else {
      // Handle other cases, if necessary
    }
  }

  return (
    <>
      <PageHeader className="main-page-header">
        <Box flexGrow={'1'}>
          <Typography theme={theme} variant="h2" component="h2" className="title">
            {heading}
            <Icon
              name="ChevronDown"
              className={showAccordionDetail ? 'toggle-btn ch-up' : 'toggle-btn'}
              onClick={() => setShowAccordionDetail(!showAccordionDetail)}
            />
          </Typography>
          {showAccordionDetail && (
            <Typography theme={theme} variant="body2" component="span" className="caption">
              {subheading}
            </Typography>
          )}
        </Box>
      </PageHeader>
      <Box theme={theme} sx={{ mb: 4 }}>
        {showAccordionDetail && (
          <OrdersListUi
            heading={heading}
            ordersLoading={completedOrdersLoading}
            data={
              heading == 'Completed'
                ? states?.vehicleCompletedOrdersData
                : states?.vehicleCancelledOrdersData
            }
          />
        )}
      </Box>
      {heading === 'Completed' && (
        <Box theme={theme} sx={{ mb: 2 }}>
          <hr className="spr" />
        </Box>
      )}
    </>
  )
}

export default memo(CancelledCompletedOrders)
