import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { DetailCard } from 'components'
import { Typography, Grid, Box } from '@ntpkunity/controls'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@helpers/enums'
import { IOrderTradeIn } from '@models'
import { useStoreContext } from '@store/storeContext'

const TradeInDetails: FC<{ order_tradein: IOrderTradeIn | any }> = ({ order_tradein }) => {
  const theme = useTheme()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const payoff_amount = order_tradein?.payoff_amount ? parseFloat(order_tradein?.payoff_amount) : 0
  const trade_in_amount = order_tradein?.trade_in_amount
    ? parseFloat(order_tradein?.trade_in_amount)
    : 0
  let tradeInEquity: any = trade_in_amount - payoff_amount
  if (tradeInEquity && tradeInEquity > 0) {
    tradeInEquity =
      defaultCurrency +
      Math.abs(tradeInEquity).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
  } else if (tradeInEquity < 0) {
    tradeInEquity =
      `-${defaultCurrency}` +
      Math.abs(tradeInEquity).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
  }
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  return (
    <DetailCard mb={2}>
      <Typography theme={theme} component="h4" variant="h4" mb={2}>
        Trade-In
      </Typography>
      <Grid theme={theme} className="container" container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            {order_tradein?.year} {order_tradein?.make} {order_tradein?.model}
          </Typography>
          <Typography theme={theme} variant="subtitle1" component="div" className="card-title">
            {order_tradein?.trim}
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          {order_tradein && order_tradein?.asset_image && (
            <Box theme={theme} className="thumbnail">
              <img
                src={
                  order_tradein && order_tradein?.asset_image ? order_tradein?.asset_image[0] : ''
                }
                alt="Product"
              />
            </Box>
          )}
        </Grid>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Box theme={theme} className="priceList">
            <Typography theme={theme} className="card-title" mb={1} component="h4" variant="h4">
              {tradeInEquity ? tradeInEquity : '-'}
            </Typography>
            <Box theme={theme} className="price-detail">
              <Typography theme={theme} className="detail offer" component="span" variant="caption">
                Our Offer:&nbsp;
              </Typography>
              <Typography theme={theme} className="detail price" component="span" variant="caption">
                {' '}
                {defaultCurrency}
                {order_tradein?.trade_in_amount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}{' '}
              </Typography>
            </Box>
            <Box theme={theme} className="price-detail">
              <Typography theme={theme} className="detail offer" component="span" variant="caption">
                Lease Balance:&nbsp;{' '}
              </Typography>
              <Typography theme={theme} className="detail price" component="span" variant="caption">
                {' '}
                {defaultCurrency}
                {order_tradein?.payoff_amount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}{' '}
              </Typography>
            </Box>
            <Box theme={theme} className="price-detail">
              <Typography theme={theme} className="detail offer" component="span" variant="caption">
                Net Trade-In Amount:&nbsp;
              </Typography>
              <Typography theme={theme} className="detail price" component="span" variant="caption">
                {' '}
                {tradeInEquity ? tradeInEquity : '-'}{' '}
              </Typography>
            </Box>
            <Box theme={theme} className="price-detail">
              <Typography theme={theme} className="detail offer" component="span" variant="caption">
                Estimated Mileage:&nbsp;
              </Typography>
              <Typography theme={theme} className="detail price" component="span" variant="caption">
                {' '}
                {order_tradein?.mileage?.toLocaleString()}{' '}
              </Typography>
            </Box>
            <Box theme={theme} className="price-detail">
              <Typography theme={theme} className="detail offer" component="span" variant="caption">
                Condition:&nbsp;
              </Typography>
              <Typography theme={theme} className="detail price" component="span" variant="caption">
                {' '}
                {order_tradein?.condition}{' '}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </DetailCard>
  )
}

export default TradeInDetails
