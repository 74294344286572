import { FC, useEffect, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { Grid, Box, Button } from '@ntpkunity/controls'
import { ProductBanner } from 'components'
import { OrderInformationDetails } from 'models/order-information.interface'
import { useGetVehicleAgainstVin } from '@apis/customer-management.service'
import { Status, QueryKeys } from '@helpers/enums'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import { useOrderSubmit } from '@apis/order-management.service'

const AssetDetails: FC<{
  order_info: OrderInformationDetails
  setConfirmationPopupState?: any
  setAvailableState?: any
}> = ({ order_info, setConfirmationPopupState, setAvailableState }) => {
  const theme = useTheme()
  const { mutate: getVehicle, data: vehicleDetails } = useGetVehicleAgainstVin()
  const [vehicleInformation, setVehicleInformation] = useState<any>(vehicleDetails)
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const { actions } = useStoreContext()
  const { mutate: submitOrder, isLoading: submitOrderLoading } = useOrderSubmit()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  useEffect(() => {
    if (order_info?.Vehicle_details?.vin) {
      getVehicle(
        { vin: order_info?.Vehicle_details?.vin },
        {
          onSuccess(response: any) {
            setVehicleInformation(response)
          }
        }
      )
    }
  }, [order_info?.Vehicle_details?.vin])

  const handleAvailableClick = () => {
    submitOrder(
      { referenceNumber: order_info?.reference_number },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: 'Order Submitted for Credit Response',
            toastState: true
          })
          setAvailableState(true)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }

  return (
    <ProductBanner>
      <Grid theme={theme} spacing={2} container>
        <Grid theme={theme} item xl={2} lg={3} md={3} sm={5} xs={12}>
          <Box theme={theme} className="thumbnail">
            <img
              src={
                vehicleInformation?.photo_urls && vehicleInformation?.photo_urls?.length > 0
                  ? vehicleInformation?.photo_urls[0]?.location
                  : ''
              }
            />
          </Box>
        </Grid>
        <Grid
          theme={theme}
          item
          xl={5}
          lg={4.5}
          md={4.5}
          sm={12}
          xs={12}
          className="product-details"
        >
          <Box theme={theme} className="title-md">
            {order_info?.Vehicle_details?.year} {order_info?.Vehicle_details?.make}{' '}
            {order_info?.Vehicle_details?.model}
          </Box>
          <Box theme={theme} className="title-lg">
            {order_info?.Vehicle_details?.trim_description}
          </Box>
          <Box theme={theme} className="title-sm">
            Order ID: <b>{order_info?.reference_number}</b>, Credit Score:{' '}
            <Link className="link" href="#">
              View Credit Document
            </Link>
          </Box>
          <Box theme={theme} className="title-sm">
            VIN: <b>{order_info?.Vehicle_details?.vin}</b>
          </Box>
        </Grid>
        <Grid
          theme={theme}
          item
          xl={5}
          lg={4.5}
          md={4.5}
          sm={12}
          xs={12}
          sx={{ mt: 2 }}
          className="product-details"
          textAlign="right"
        >
          <Box theme={theme} className="actions-wrap">
            <Box theme={theme}>
              <Box theme={theme} className="title-md">
                Net Financed Amount
              </Box>
              <Box theme={theme} className="title-lg">
                {defaultCurrency}
                {order_info?.finance_amount?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </Box>
              <Box theme={theme} className="title-sm">
                Type:{' '}
                <b>
                  {order_info?.contract_term} Months {order_info?.finance_type}
                </b>
                , Rate:{' '}
                <b>
                  {order_info?.apr?.toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4
                  })}
                  %
                </b>
              </Box>
              <Box theme={theme} className="title-sm">
                {order_info?.status == Status.SchedulePickup ||
                order_info?.status == Status.Appointment ||
                order_info?.status == Status.Completed ? (
                  <>Periodic Payment: </>
                ) : (
                  <>Est. Periodic Payment: </>
                )}
                <b>
                  {defaultCurrency}
                  {order_info?.estimated_monthly_payment?.toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 2
                  })}
                </b>
              </Box>
            </Box>
            {order_info?.status == Status.PendingConfirmation && (
              <Box theme={theme} className="actions">
                <Button
                  secondary
                  fullWidth
                  theme={theme}
                  onClick={() => {
                    setConfirmationPopupState(true)
                  }}
                  text="Not Available"
                />
                <Button
                  primary
                  fullWidth
                  theme={theme}
                  onClick={handleAvailableClick}
                  disabled={submitOrderLoading}
                  text="Available"
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </ProductBanner>
  )
}

export default AssetDetails
