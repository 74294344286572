import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'
import { lightTheme } from '@styles/theme'

export const DetailCard: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: '1px solid' + theme.palette.divider,
  padding: '32px',

  '.text-center': {
    textAlign: 'center'
  },
  '.label': {
    color: theme.palette.grey[600],
    marginBottom: '2px'
  },
  '.label-detail': {
    color: theme.palette.grey[900]
  },
  '.link': {
    color: lightTheme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  '.thumbnail': {
    display: 'flex',
    height: 144,
    width: '100%',
    verticalAlign: 'middle',
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      maxHeight: 144,
      width: 'auto',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    }
  },
  '.priceList': {
    textAlign: 'right',
    '.price-detail': {
      display: 'flex',
      justifyContent: 'end',
      marginBottom: '2px',
      '.detail': {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        fontWeight: theme.typography.caption.fontWeight,
        color: theme.palette.grey[600]
      },
      '.price': {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[900]
      }
    }
  },

  '.package-row': {
    display: 'flex',
    alignItems: 'center',
    clear: 'both',
    padding: '0 12px',
    height: 25,
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    transition: 'all 0.3s',
    paddingLeft: 0,
    textAlign: 'left',
    borderRadius: 0,
    span: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.grey[900],
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightRegular
    },

    '&.h-default': {
      height: 'auto'
    },

    b: {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },

    small: {
      fontSize: theme.typography.caption.fontSize
    },

    hr: {
      opacity: 0.35,
      margin: '24px 0 0 0'
    },

    '&:hover': {
      backgroundColor: 'transparent'
    },

    '&.blank': {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },

    '&.toggle': {
      'span, .toggle-btn': {
        float: 'left'
      },
      span: {
        marginRight: 5
      },
      '.toggle-btn': {
        cursor: 'pointer',
        marginTop: 3
      },
      '.ch-up': {
        webkitTransform: 'scaleY(-1)',
        transform: 'scaleY(-1)'
      }
    }
  },
  '.MuiAccordion-root': {
    backgroundColor: 'transparent',
    boxShadow: '0 0 0 transparent',
    borderRadius: 0,
    margin: 0,

    '&:before, .MuiAccordionSummary-root': {
      display: 'none'
    },

    '.MuiAccordionDetails-root': {
      padding: 0,
      '.account-detail': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.palette.grey[600]
      }
    }
  },
  '.line': {
    hr: {
      opacity: 0.35,
      margin: '24px 0'
    }
  },
  '.card-header': {
    '.text': {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      fontWeight: theme.typography.body2.fontWeight,
      color: theme.palette.grey[600]
    }
  },
  '.card-body': {
    '.row': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '.center': {
        display: 'flex',
        alignItems: 'center'
      },
      '.icon': {
        marginRight: 16
      },
      '.card-text': {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        b: {
          fontWeight: theme.typography.button.fontWeight
        }
      },
      '.text': {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        fontWeight: theme.typography.body2.fontWeight,
        color: theme.palette.grey[600]
      }
    },
    '.summary-row': {
      display: 'flex',
      alignItems: 'center',
      clear: 'both',
      height: 25,
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      transition: 'all 0.3s',
      paddingLeft: 0,
      textAlign: 'left',
      borderRadius: 0,
      span: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular
      },

      '&.h-default': {
        height: 'auto'
      },

      b: {
        fontWeight: theme.customVariables.fontWeightSemiBold
      },

      hr: {
        opacity: 0.35,
        margin: '24px 0 0 0'
      },

      '&:hover': {
        backgroundColor: 'transparent'
      },

      '&.blank': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },

      '&.toggle': {
        'span, .toggle-btn': {
          float: 'left'
        },
        // span: {
        //   marginRight: 5
        // },
        '.toggle-btn': {
          cursor: 'pointer',
          marginTop: 3
        },
        '.ch-up': {
          webkitTransform: 'scaleY(-1)',
          transform: 'scaleY(-1)'
        }
      }
    },
    '.summary-detail': {
      '.row': {
        '.sub-heading': {
          fontFamily: theme.typography.caption.fontFamily,
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.typography.button.fontWeight,
          color: theme.palette.grey[900]
        },
        '.text': {
          fontFamily: theme.typography.caption.fontFamily,
          fontWeight: theme.typography.fontWeightRegular,
          fontSize: theme.typography.fontSize,
          color: theme.palette.grey[600]
        }
      }
    }
  },
  '.badge': {
    padding: '5px 16px 4px 16px',
    borderRadius: theme.shape.borderRadius,
    '&.success': {
      backgroundColor: '#33CC5933'
    },
    '&.error': {
      backgroundColor: 'rgba(255, 51, 51, 0.2)'
    }
  },
  '.badge-width': {
    width: '40px'
  },
  '.without-accordian': {
    display: 'flex',
    // flexWrap: "wrap",
    flexDirection: 'row',
    width: '100%',
    paddingBottom: 16,
    justifyContent: 'space-between',
    '.left': {
      float: 'left',
      marginRight: '16px'
    },
    '.right': {
      float: 'right'
    }
  },
  '.MuiButtonBase-root': {
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.customVariables.fontWeightSemiBold,
    color: theme.palette.common.black,
    border: '0 solid transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    svg: {
      path: {
        stroke: 'rgb(154, 158, 178)'
      }
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      svg: {
        transform: 'scaleY(-1)'
      }
    },
    '.MuiTouchRipple-root': {
      display: 'none'
    }
  },
  '.btn': {
    padding: '0 !important'
  },
  '.appointment': {
    borderRight: '1px solid' + theme.palette.grey[100],
    '.appointment-icon': {
      // width: 36,
      // height: 36,
      path: {
        stroke: theme.palette.primary.main
        // strokeWidth: 1
      }
    },
    '&:last-child': {
      borderRight: 'none'
    }
  }
}))
