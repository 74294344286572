import { styled } from '@mui/system'
export const Card = styled('div')(({ theme }) => ({
  border: '1px solid' + theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  display: 'inline-block',
  width: '100%',
  padding: 24,
  '.text-600': {
    color: theme.palette.grey[600]
  },
  '.text-900': {
    color: theme.palette.grey[900]
  },
  '.card-header': {
    borderBottom: '1px solid' + theme.palette.divider,
    display: 'inline-block',
    width: '100%',
    paddingBottom: 24,
    marginBottom: 24,
    minHeight: 62,
    '.title': {
      display: 'inline-block',
      float: 'left',
      marginRight: 16,
      marginTop: 10
    },
    '.status': {
      display: 'inline-block',
      float: 'left',
      padding: '10px 16px',
      borderRadius: theme.shape.borderRadius,
      fontSize: 14,
      color: theme.palette.grey[900],

      '&.available': {
        backgroundColor: '#D6F5DE'
      },

      '&.not-available': {
        backgroundColor: '#F5F5F7'
      }
    }
  },
  '.card-body': {
    display: 'inline-block',
    width: '100%',
    fontSize: 14,
    color: theme.palette.grey[600],
    b: {
      color: theme.palette.grey[900]
    }
  },
  '&.ccp-card': {
    h4: {
      color: theme.palette.grey[900]
    },
    '.ccp-card-detail': {
      marginTop: 16,
      '.ccp-card-row': {
        display: 'flex',
        gap: 16,
        '.left': {
          minWidth: 328
        }
      }
    }
  },
  '&.min-h': {
    minHeight: 324
  },
  'ul.list': {
    display: 'inline-block',
    width: '100%',
    padding: 0,
    margin: 0,
    li: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      width: '100%',
      // paddingBottom: 16,
      listStyle: 'none',

      '&:last-child': {
        paddingBottom: 0
      },

      // ".left": {
      //     flexBasis: "81%",
      //     flexGrow: 0,
      //     maxWidth: "81%",
      //     marginRight: "16px"
      // },
      // ".right": {
      //     flexBasis: "16%",
      //     flexGrow: 0,
      //     maxWidth: "16%",
      // },
      '.without-accordian': {
        display: 'flex',
        // flexWrap: "wrap",
        flexDirection: 'row',
        width: '100%',
        paddingTop: 16,
        paddingBottom: 16,
        borderTop: '1px solid' + theme.palette.grey[100],
        justifyContent: 'space-between',
        '.left': {
          marginRight: '16px'
        },
        '.right': {
          marginRight: 40
        }
      },
      '&:first-child': {
        '.without-accordian': {
          borderTop: 'none'
        },
        '.MuiAccordion-root': {
          borderTop: 'none'
        }
      },
      '.MuiAccordion-root': {
        width: '100%',
        marginBottom: 0,
        borderTop: '1px solid' + theme.palette.grey[100],
        // borderBottom: "1px solid" + theme.palette.grey[100],
        '.MuiButtonBase-root': {
          padding: 0,
          '.MuiAccordionSummary-content': {
            width: '100%',
            '.main-title': {
              width: '100%',
              '.ccp-accordian-header': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '.MuiTypography-root': {
                  color: theme.palette.grey[600],
                  flexBasis: '81%',
                  flexGrow: 0,
                  maxWidth: '81%',
                  marginRight: '16px'
                },
                b: {
                  flexBasis: '16%',
                  flexGrow: 0,
                  maxWidth: '16%',
                  textAlign: 'right'
                }
              }
            }
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            marginRight: 8
          }
        },
        '.u-accordian-body': {
          padding: 0,
          border: 'none',
          '.detail': {
            marginBottom: 16,
            '.MuiTypography-root': {
              color: theme.palette.grey[500]
            }
          }
        }
      }
    }
  },
  '.spr': {
    border: '1px solid' + theme.palette.divider,
    display: 'block',
    width: '100%',
    height: 1,
    margin: '24px 0'
  }
}))
