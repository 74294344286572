import { lightTheme } from './theme'
import { styled } from '@mui/system'

export const CustomTheme = styled('div')(({ theme }) => ({
  '&.custom-theme': {
    '.MuiButtonBase-root.MuiButton-root.btn': {
      '&.btn-primary': {
        border: 'none',
        color: theme.palette.common.white,
        '&:hover': {
          background: theme.palette.grey[900] + '!important',
          backgroundColor: theme.palette.grey[900] + '!important',
          borderColor: theme.palette.grey[900] + '!important',
          color: [theme.palette.common.white]
        },
        '&.with-icon:hover': {
          svg: {
            path: {
              stroke: theme.palette.common.white + '!important'
            }
          }
        },
        '&:disabled': {
          opacity: 0.5,
          cursor: 'not-allowed',
          color: theme.palette.common.white + '!important'
        }
      }
    },

    '.u-dialog': {
      '&.confirmation-dialog .u-dialog-action .btn.btn-secondary': {
        marginTop: 0
      }
    },
    '.MuiModal-root-MuiPopover-root-MuiMenu-root, .u-dropdown-menu': {
      '.u-dropdown-item:hover': {
        fontWeight: lightTheme.typography.fontWeightRegular + '!important'
      }
    },

    '.MuiPopper-root .MuiPaper-root .MuiCalendarPicker-root': {
      '.PrivatePickersFadeTransitionGroup-root, .PrivatePickersFadeTransitionGroup-root': {
        color: lightTheme.palette.grey[900]
      }
    },

    '.link': { color: theme.palette.primary.main }
  }
}))
