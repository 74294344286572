import { useEffect, useState } from 'react'
import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Box, Typography, Button, Icon, Checkbox } from '@ntpkunity/controls'
import { useForm } from 'react-hook-form'
import { useUpdateCustomerContact } from '@apis/customer-detail.service'
import { IAddress } from '@models'
import { getGoogleKey } from '@helpers/methods'
import { useStoreContext } from '@store/storeContext'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomerInfoComponent from './customer-info'
import AddressDetailsComponent from './address-detail'
import { AddressType } from '@helpers/enums'
import { useHasFocus } from '@hooks/useHasHook'

interface IFormInputs {
  customer_profile?: {
    prefix?: string | null
    first_name?: string | null
    middle_name?: string | null
    last_name?: string | null
    suffix?: string | null
    date_of_birth: string
    ssn?: string | null
    mobile_number?: string | null
    home_number?: string | null
    email?: string | number
  }
  mail_address: IAddress | null
  garaging_address?: IAddress | null
}

function createValidationSchema(isGaraging: boolean) {
  const schema = yup.object().shape({
    customer_profile: yup.object().shape(
      {
        first_name: yup
          .string()
          .nullable()
          .required('First Name is required')
          .max(100, 'First Name maximum length is up to 100 characters')
          .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
        middle_name: yup
          .string()
          .nullable()
          .max(100, 'Middle Name maximum length is up to 100 characters')
          .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
        last_name: yup
          .string()
          .nullable()
          .required('Last Name is required')
          .max(100, 'Last Name maximum length is up to 100 characters')
          .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
        suffix: yup
          .string()
          .nullable()
          .max(100, 'Customer Suffix maximum length is up to 100 characters')
          .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
        ssn: yup
          .string()
          .nullable()
          .required('Social Security Number is required')
          .when('ssn', {
            is: (value: any) => value,
            then: yup
              .string()
              .nullable()
              .matches(
                /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
                'Valid Social Security Number is required'
              )
          }),
        mobile_number: yup
          .string()
          .nullable()
          .required('Mobile Phone Number is required')
          .matches(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            'Valid Mobile Phone Number is required'
          ),
        home_number: yup
          .string()
          .nullable()
          .test('is-valid-phone', 'Valid Home Phone Number is required', (value) => {
            // Only apply the validation if there's a value
            if (value) {
              return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)
            }
            // If there's no value, validation is not required
            return true
          })
      },
      [['ssn', 'ssn']]
    ),
    mail_address: yup.object().shape({
      address_line_1: yup.string().nullable().required('Address Line 1 is required'),
      city: yup.string().nullable().required('City is required'),
      state_name: yup.string().nullable().required('State Name is required'),
      state_id: yup.string().nullable().required('State Name is required'),
      zip_code: yup.string().nullable().required('Zip Code is required'),
      move_in_duration: yup
        .string()
        .nullable()
        .required('Move-In Date is required')
        .matches(/^(0[1-9]|1[0-2])(19|20)\d{2}$/, 'Move-In Date should be in MM/YYYY format')
    }),
    garaging_address: !isGaraging
      ? yup.object().shape({
          address_line_1: yup.string().nullable().required('Address Line 1 is required'),
          city: yup.string().nullable().required('City is required'),
          state_id: yup.string().nullable().required('State Name is required'),
          state_name: yup.string().nullable().required('State Name is required'),
          zip_code: yup.string().nullable().required('Zip Code is required')
        })
      : yup.object().shape({})
  })
  return schema
}

interface IContactDetailsComponentProps {
  setIsContact?: (value: boolean) => void
  setIsContactErrros?: (value: boolean) => void
  setRoute: (value: number) => void
  contactData?: any
  customerReferenceId?: string
  lenderIntegrationsData: any
  setIsFocus?: (value: boolean) => void
}

const ContactDetailsComponent: FC<IContactDetailsComponentProps> = ({
  setIsContact,
  setIsContactErrros,
  setRoute,
  contactData,
  customerReferenceId,
  lenderIntegrationsData,
  setIsFocus
}) => {
  const theme = useTheme()

  const [getCustomerDataByReferenceId, setCustomerDataByReferenceId] = useState<IFormInputs>()
  const [isGaraging, setIsGaraging] = useState(false)
  const [isSsnMask, setIsSsnMask] = useState(false)
  const validationSchema = createValidationSchema(isGaraging)

  const form = useForm<IFormInputs>({
    defaultValues: getCustomerDataByReferenceId,
    resolver: yupResolver(validationSchema),
    mode: 'onTouched'
  })

  const {
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue
  } = form

  const { actions } = useStoreContext()
  const [isVerified, setIsVerified] = useState(false)
  const [_isAddressDirty, setIsAddressDirty] = useState(false)
  const [googleApiKey, setGoogleApiKey] = useState(null)

  const { mutate: updateCustomerByReferenceId } = useUpdateCustomerContact()
  const [isgaragingVerified, setIsGaragingVerified] = useState(false)
  const focus = useHasFocus()

  useEffect(() => {
    const mailAddress = contactData?.customer_addresses?.find(
      (x: any) => x?.address_type === AddressType.MAILING
    )
    const { ssn, ...restOfContactData } = contactData || {}
    setCustomerDataByReferenceId({
      ...getCustomerDataByReferenceId,
      customer_profile: { ...restOfContactData },
      mail_address: { ...mailAddress }
    })
  }, [contactData])

  useEffect(() => {
    setIsFocus?.(focus)
  }, [focus])

  useEffect(() => {
    return () => {
      if (contactData) {
        setIsContactErrros?.(true)
        setIsContact?.(true)
      }
    }
  }, [contactData])

  useEffect(() => {
    const mailAddress = contactData?.customer_addresses?.find(
      (x: any) => x?.address_type === AddressType.MAILING
    )
    const garagingAddress = contactData?.customer_addresses?.find(
      (x: any) => x?.address_type === AddressType.GARAGING
    )
    reset(getCustomerDataByReferenceId)
    setIsSsnMask(getCustomerDataByReferenceId?.customer_profile?.ssn ? true : false)
    setValue('mail_address', mailAddress)
    setValue('mail_address.state_id', mailAddress?.state_name)
    setValue('mail_address.move_in_duration', setMoveInDate(mailAddress?.move_in_date))
    setIsGaraging(mailAddress?.is_garaging ?? false)
    setValue('garaging_address', mailAddress?.is_garaging ? '' : garagingAddress)
    setValue(
      'garaging_address.state_id',
      mailAddress?.is_garaging ? '' : garagingAddress?.state_name
    )
  }, [getCustomerDataByReferenceId])

  useEffect(() => {
    setGoogleApiKey(getGoogleKey(lenderIntegrationsData))
  }, [lenderIntegrationsData])

  useEffect(() => {
    if (getValues('mail_address')?.verified) {
      setIsVerified(true)
    }
  }, [getValues('mail_address')])

  const fillGargingAddress = (e: any) => {
    if (e.target.checked) {
      setIsGaraging(true)
    } else {
      setIsGaraging(false)
    }
  }

  useEffect(() => {
    if (getValues('garaging_address')?.verified) {
      setIsGaragingVerified(true)
    }
  }, [getValues('garaging_address')])

  const getMoveInDate = (moveInDuration: string) => {
    let date = null
    if (moveInDuration && moveInDuration?.toString()?.length > 3) {
      const month = parseInt(moveInDuration?.toString().slice(0, 2))
      let year = parseInt(moveInDuration?.toString().slice(2, 6))
      date = `${year}-${month}-${1}`
      return date
    }
    return date
  }

  const setMoveInDate = (moveInDate: string) => {
    let monthYear = ''

    if (moveInDate?.length > 0) {
      if (parseInt(moveInDate?.toString()?.slice(0, 4)) > new Date().getFullYear()) {
      }
      monthYear = moveInDate?.toString()?.slice(5, 7) + moveInDate?.toString()?.slice(0, 4)
    }
    return monthYear
  }

  const hasErrors = Object.keys(errors).length > 0
  if (hasErrors) {
    setIsContact?.(false)
    setIsContactErrros?.(hasErrors)
  }
  const onSubmit = (data: IFormInputs) => {
    const addresses = []
    const mailAddress = getValues('mail_address')
    const garagingAddress = getValues('garaging_address')
    if (mailAddress) {
      mailAddress.address_type = AddressType.MAILING
      if (!googleApiKey) {
        mailAddress.verified = false
      }
      addresses.push({
        ...mailAddress,
        move_in_date: mailAddress?.move_in_duration
          ? getMoveInDate(mailAddress?.move_in_duration)
          : null,
        is_garaging: isGaraging
      })
    }

    if (garagingAddress && !isGaraging) {
      garagingAddress.address_type = AddressType.GARAGING
      if (!googleApiKey) {
        garagingAddress.verified = false
      }
      addresses.push({
        ...garagingAddress
      })
    }
    const newData = {
      customer_profile: {
        prefix: data?.customer_profile?.prefix === 'SELECT' ? null : data?.customer_profile?.prefix,
        first_name: data?.customer_profile?.first_name
          ? data?.customer_profile?.first_name
          : undefined,
        middle_name: data?.customer_profile?.middle_name
          ? data?.customer_profile?.middle_name
          : undefined,
        last_name: data?.customer_profile?.last_name
          ? data?.customer_profile?.last_name
          : undefined,
        suffix: data?.customer_profile?.suffix ? data?.customer_profile?.suffix : undefined,
        ssn: data?.customer_profile?.ssn ? data?.customer_profile?.ssn : undefined,
        mobile_number: data?.customer_profile?.mobile_number
          ? data?.customer_profile?.mobile_number
          : undefined,
        home_number: data?.customer_profile?.home_number
          ? data?.customer_profile?.home_number
          : undefined,
        updated_by: 'Netsol'
      },
      customer_addresses: addresses
    }
    updateCustomerByReferenceId(
      {
        ...newData,
        reference_id: customerReferenceId
      },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: 'Record updated successfully',
            toastState: true
          })
          setIsContact?.(true)
          setIsContactErrros?.(true)
          setRoute(1)
          // setAddressDirty(false)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error?.detail?.message?.message?.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }

  return (
    <>
      <Typography theme={theme} component="h3" variant="h3" sx={{ mb: 3 }}>
        Contact Details
      </Typography>
      {/* <form onSubmit={handleSubmit(submit)}> */}
      <Box theme={theme} sx={{ mb: 4 }}>
        <CustomerInfoComponent form={form} isSSN={isSsnMask} setIsSSN={setIsSsnMask} />
      </Box>
      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>
      <Typography theme={theme} component="h4" variant="h4" className="title-icon">
        <span>Mailing Address </span>{' '}
        {isVerified ? (
          <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
            <Icon name="GreenCheckIcon" />
          </Box>
        ) : null}
      </Typography>

      <Box theme={theme} sx={{ mb: 4 }}>
        <AddressDetailsComponent
          form={form}
          googleApiKey={googleApiKey}
          addressValue={'mail_address'}
          setIsAddressDirty={setIsAddressDirty}
          errorProps={errors.mail_address}
        />
      </Box>

      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>
      <Typography theme={theme} component="h4" variant="h4" className="title-icon">
        <span>Garaging Address </span>{' '}
        {!isGaraging && isgaragingVerified ? (
          <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
            <Icon name="GreenCheckIcon" />
          </Box>
        ) : null}
      </Typography>
      <Box theme={theme}>
        <Checkbox
          checkBoxChecked={isGaraging}
          label="Same as mailing address"
          onChange={(e: any) => fillGargingAddress(e)}
          theme={theme}
        />
      </Box>

      <Grid theme={theme} container spacing={3} sx={{ mb: 4 }}>
        <Grid theme={theme} item xs={12}>
          {!isGaraging ? (
            <AddressDetailsComponent
              form={form}
              googleApiKey={googleApiKey}
              addressValue={'garaging_address'}
              setIsAddressDirty={setIsAddressDirty}
              moveIn={false}
              errorProps={errors.garaging_address}
            />
          ) : null}
        </Grid>
      </Grid>

      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>
      <Box theme={theme} textAlign="right" sx={{ mb: 5 }}>
        <Button
          theme={theme}
          primary
          text="Save Contact Details"
          type="submit"
          // disabled={!isDirty || !isAddressDirty}
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
    </>
  )
}
export default ContactDetailsComponent
